<!-- <ngx-ui-loader></ngx-ui-loader> -->
<mat-toolbar [ngStyle]="{'height':'50px'}" class="header-css">
    <div>Leads</div>
</mat-toolbar>
  <div fxLayout="row">
    <mat-card fxFlex>
        <mat-card-content>
    <table fxFlex mat-table [dataSource]="dataSource" matSort matSortActive="lastupdationDate" matSortDirection="desc" class="mat-elevation-z4">

        <!-- Deal # Column -->
        <ng-container matColumnDef="dealNumber">
          <th mat-header-cell *matHeaderCellDef class="th-head-css" style="width: 8%"> Deal #</th>
          <td mat-cell *matCellDef="let element">
               <div style="margin: 15px 0px 5px 0px;">{{element.dealNumber}}</div>
            </td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="th-head-css" style="width: 22%">Lead</th>
          <td mat-cell *matCellDef="let element">
               <div style="margin: 15px 0px 5px 0px;">{{element.fullName}}</div>
               <div  style="margin: 5px 0px;">{{element.email}}</div>
               <div style="margin: 5px 0px 15px 0px;">{{element.phone}}</div>
            </td>
        </ng-container>
         <!-- Vehicle Column -->
         <ng-container matColumnDef="vehicle">
            <th style="width: 14%;" mat-header-cell *matHeaderCellDef class="th-head-css"> Vehicle</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.vehicle">
                    <div>{{element.vehicle.makeName}} &nbsp;{{element.vehicle.modelName}}</div>
                    <div>{{element.vehicle.submodelName}}</div>
                </ng-container>
              </td>
          </ng-container>
           <!-- Variant Column -->
        <ng-container matColumnDef="variant">
            <th mat-header-cell *matHeaderCellDef class="th-head-css" style="width: 29%"> Vehicle Details</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.vehicle">
                    <div>{{element.vehicle.variantName}}</div>
                    <div *ngIf="element.vehicle.vin">VIN:&nbsp;{{element.vehicle.vin}}</div>
                    <div>{{element.vehicle.exteriorColorName}}</div>
                    <div [ngStyle]="{'background-color': element.vehicle.exteriorColorCode}" style="height: 8px; width: 80px;">&nbsp;</div>
                </ng-container>
              </td>
          </ng-container>
           <!-- Color Column -->
        <!-- <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef class="th-head-css"> Color</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.vehicle">
                    <div>{{element.vehicle.exteriorColorName}}</div>
                    <div [ngStyle]="{'background-color': element.vehicle.exteriorColorCode}" style="height: 8px; margin-right: 16px;">&nbsp;</div>
                </ng-container>
              </td>
          </ng-container> -->
           <!-- Requested date Column -->
        <!-- <ng-container matColumnDef="requestedDate">
            <th mat-header-cell *matHeaderCellDef class="th-head-css"> Requested Date</th>
            <td mat-cell *matCellDef="let element">
                 <div>{{element.testDriveDate}}</div>
              </td>
          </ng-container> -->
           <!-- Last updated Column -->
        <ng-container matColumnDef="lastupdatedDate" >
            <th mat-header-cell *matHeaderCellDef class="th-head-css" style="width: 12%"> Last Updated</th>
            <td mat-cell *matCellDef="let element">
                 <div>{{element.lastupdationDate | date : 'MMM dd, yyyy'}}</div>
              </td>
          </ng-container>

           <!-- requestFor Column -->
        <ng-container matColumnDef="status">
            <th style="width: 14%;" mat-header-cell *matHeaderCellDef class="th-head-css"> Status</th>
            <td mat-cell *matCellDef="let element">
                 <div>{{getStatusDisplay(element.status, element)}}</div>
              </td>
          </ng-container>
    
        <!-- Address Column -->
        <!-- <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef class="th-head-css"> Address </th>
            <td mat-cell *matCellDef="let element"> {{element.address}}
                &nbsp;{{element.city}}
                &nbsp;{{element.state}}
                &nbsp;{{element.zip}}
            </td>
          </ng-container> -->

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="th-head-css" style="text-align: center;"> Action </th>
            <td mat-cell *matCellDef="let element" align="center"> 
              <button mat-raised-button style="margin-top: 10px;" class="primary-btn" (click)="selectCustomer(element)">
                SELECT</button>
            </td>
          </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
        </mat-card-content>
    </mat-card>
  </div>