import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../service/common.service';
import { UtilityService } from '../service/utility.service';
import { AngularFireAuthCarDealer } from './angularfirestore-extension.service';

@Injectable({
  providedIn: 'root'
})
export class CardealerAuthService {

  constructor(private fireAuth: AngularFireAuthCarDealer, private router : Router
    , private utilservice : UtilityService, private commonService: CommonService) { }

  login = (email, password) => {
    return this.fireAuth.signInWithEmailAndPassword(email, password);
  }

  logout = () => {
    this.fireAuth.signOut();
    sessionStorage.removeItem('user');
    this.utilservice.clearPurchaseInformation();
    this.commonService.resetCurrentLead();
    this.router.navigate(['login']);
  }
}
