import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { PriceService } from 'src/app/service/price.service';
import { AlertService } from 'ngx-alerts';
import { FirebaseService } from 'src/app/service/firebase.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { timeStamp } from 'console';

@Component({
  selector: 'app-proforma-invoice',
  templateUrl: './proforma-invoice.component.html',
  styleUrls: ['./proforma-invoice.component.css']
})
export class ProformaInvoiceComponent implements OnInit {
  user : any = {};
  currentLead: any = {};
  mainObj : any = {};
  customerVehicle: any = {}
  groupId = '';
  dealerId = '';
  customerId = '';
  evalutionObj = {};
  bookingObj : any = {};
  disableBtn: boolean = false;
  invoiceRecipt = '';
  nodePath = '';
  isBookingSaved : boolean = false;
  constructor(
    private commonService: CommonService, 
    private alertService : AlertService,
    private router: Router,
    private firebaseService : FirebaseService,
    private loaderServie : NgxUiLoaderService,
    ) { }

  ngOnInit(): void {
    this.user = this.commonService.getUserInfo();
    this.currentLead = this.commonService.getCurrentLead();
    if(!this.currentLead) {
      this.currentLead = {};
      this.alertService.danger('Please select customer to continue');
      this.router.navigate(['/home/leads-list']);
      return;
    }
    this.groupId = this.commonService.getGroupUId();
    this.dealerId = this.commonService.getDealerUId();
    this.customerId = this.currentLead ? this.currentLead['pushKey'] : '';
    this.bookingObj = this.currentLead ? this.currentLead['booking'] : {};
    if(this.bookingObj && Object.keys(this.bookingObj).length > 0) {
      this.isBookingSaved = true;
    } else {
      this.isBookingSaved = false;
    }
    this.mainObj.invoiceRecipt = 'IN'+new Date().getTime();
    if(!this.currentLead) {
      this.alertService.danger('Please select customer to continue');
      this.router.navigate(['/home/leads-list']);
      return;
    }
    this.customerVehicle = this.currentLead ? this.currentLead['vehicle'] : {};
    this.nodePath = 'invoice/proforma/'+this.groupId+'/'+this.dealerId+'/'+this.customerId;
    this.getProforma();
  }

  getProforma() {
    this.firebaseService.getFirebaseObjectTypeValues(this.nodePath).subscribe(res=> {
      if(res) {
        this.mainObj = res;
      }
    })
  }

  makeObj() {
    this.mainObj['groupId'] = this.groupId ? this.groupId : '';
    this.mainObj['dealerId'] = this.dealerId ? this.dealerId : '';
    this.mainObj['customerId'] = this.customerId ? this.customerId : '';
    this.mainObj['refurbishTotal'] = this.customerVehicle && (this.customerVehicle['refurbishTotal'] ? this.customerVehicle['refurbishTotal'] : 0);
    this.mainObj['amountReceived'] = this.bookingObj ? this.bookingObj['amountReceived'] : 0;
    this.mainObj['balancePayment'] = this.bookingObj ? this.bookingObj['balancePayment'] : 0;
    this.mainObj['invoiceDateTimeStamp'] = this.mainObj['invoiceDate'] ? new Date(this.mainObj['invoiceDate']).getTime() : '';
    this.mainObj['creationDate'] = new Date().getTime();
  }

  saveProforma() {
    this.disableBtn = true;
    this.loaderServie.startBackground();
    this.makeObj();
    this.firebaseService.firbaseUpdateData(this.nodePath, this.mainObj);
    this.disableBtn = false;
    this.loaderServie.stopBackground();
    this.alertService.success('Record Saved Successfully');
  }

  checkFullPayement = function() {
    if(this.bookingObj && this.bookingObj['balancePayment'] > 0) {
      this.alertService.danger("Unable to check full amount paid", 'Payment Pending');
      this.mainObj.isFullAmountPaid = false;
    } else {
      if(this.mainObj.isFullAmountPaid) {
        this.mainObj.isFullAmountPaid = true;
      } else {
        this.mainObj.isFullAmountPaid = false;
      }
    }
  }
}
