<h2 mat-dialog-title >
    Search Lead
    <span class="material-icons close-btn" mat-dialog-close>
        cancel
        </span>
        <button mat-raised-button style="float: right; margin-right: 20px;" mat-dialog-close (click)="resetCustomer()">Reset</button>
</h2>
<mat-dialog-content style="padding-bottom: 10px;">
    <form >
        <div fxFlex="100">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                <mat-form-field fxFlex appearance="outline">
                    <mat-label>Search by {{customerBy}}</mat-label>
                    <input matInput  matInput [(ngModel)]="customerVal" [ngModelOptions]="{standalone: true}" cdkFocusInitial required style="text-transform: uppercase;" (input)="searchCustomers()">
                  </mat-form-field>
                  <button mat-raised-button disabled class="dis-btn">by</button>
                  <mat-form-field fxFlex="15" appearance="outline">
                    <!-- <mat-label>By</mat-label> -->
                    <mat-select matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="customerBy">
                      <!-- <mat-option *ngFor="let item of customerByList" [value]="item">{{item}}</mat-option> -->
                      <mat-option value="Name">Name</mat-option>
                      <mat-option value="Email">Email</mat-option>
                      <mat-option value="Mobile">Phone</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button mat-raised-button disabled class="dis-btn">&</button>
                  <mat-form-field fxFlex appearance="outline">
                    <mat-label>Search by {{vehicleBy}}</mat-label>
                    <input matInput  matInput [(ngModel)]="vehicle" [ngModelOptions]="{standalone: true}" cdkFocusInitial style="text-transform: uppercase;">
                  </mat-form-field>
                  <button mat-raised-button disabled class="dis-btn">by</button>
                  <mat-form-field fxFlex="15" appearance="outline">
                    <!-- <mat-label>By</mat-label> -->
                    <mat-select matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="vehicleBy">
                      <!-- <mat-option *ngFor="let item of customerByList" [value]="item">{{item}}</mat-option> -->
                      <mat-option value="Make">Make</mat-option>
                      <mat-option value="Model">Model</mat-option>
                      <mat-option value="Sub Model">Sub Model</mat-option>
                      <mat-option value="Engine">Engine</mat-option>
                      <mat-option value="VIN">VIN</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>

            <div  *ngIf="customerList.length > 0" fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top:20px !important;">
                <mat-card [class.mat-elevation-z6]="true"  fxFlex="100" style="padding: 0px;" >
                    <mat-card-content>
                        <div fxLayout="row"  *ngFor="let item of customerList">
                            <div fxFlex="30" style="padding: 16px;">
                                <div  (click)="selectCustomer(item)">
                                    <h3 class="margin-bottom-0"><i class="fa fa-user"></i>&nbsp;{{item.firstName}} {{item.lastName}}</h3>
                                    <h4 class="margin-bottom-0"><i class="fa fa-envelope"></i>&nbsp;{{item.email}}</h4>
                                    <h4 class="margin-bottom-0"><i class="fa fa-phone"></i>&nbsp;{{item.mobile}}</h4>
                                </div>
                            </div>
                            <div fxFlex="70">
                                <div *ngIf="item.vehicle">
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.sm="0px">
                                        <div fxFlex="36">
                                            <img *ngIf="item.vehicle.vehicleImages && item.vehicle.vehicleImages[0] " [src]="item.vehicle.vehicleImages[0].downloadUrl" style="width: 250px;" class="image-style">
                                            <img *ngIf="!item.vehicle.vehicleImages" src="../../../../assets/images/notfound.png" style="width: 250px;" class="image-style">
                                        </div>
                                        <div fxFlex="60" style="padding: 16px;">
                                            <h3 class="margin-bottom-0">
                                                <i class="fa fa-car"></i>&nbsp;{{item.vehicle.year}}&nbsp;{{item.vehicle.makeName}}&nbsp;{{item.vehicle.modelName}}
                                                <button mat-raised-button color="primary" style="float: right;" (click)="selectCustomer(item)">SELECT</button>
                                            </h3>
                                            <h4 class="margin-bottom-0">{{item.vehicle.variantName}}</h4>
                                            <h4 class="margin-bottom-0">VIN: {{item.vehicle.vin}}</h4>
                                            <h4 class="margin-bottom-0">{{item.vehicle.km | number : '1.0-2'}}&nbsp;KM</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="text-center" *ngIf="customerVal && customerVal.length > 3 && customerList.length == 0">
                <b >No record found</b>
            </div>
        </div>

       </form> 
</mat-dialog-content>