import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabaseCarDealer } from './angularfirestore-extension.service';

@Injectable({
  providedIn: 'root'
})
export class CardealerDbService {

  constructor(
    private afdb: AngularFireDatabaseCarDealer,
  ) { }

  get = (nodepath: string) => {
    return this.afdb.database.ref(nodepath).once('value');
  }
  firbaseUpdateData(node: string, value: any) {
    return this.afdb.object(node).update(value);
  }
  firebasePushData(node: string, value: any) {
    return this.afdb.list(node).push(value);
  }

  getFirebaseListTypeValues(node: string) {
    return this.afdb.list(node).valueChanges();
  }

  getFirebaseListLimit(node: string, limit: number) {
    return this.afdb.list(node, ref => ref.limitToFirst(limit)).valueChanges();
  }
  getFirebaseListLimitLast(node: string, limit: number) {
    return this.afdb.list(node, ref => ref.limitToLast(limit)).valueChanges();
  }

  getFirebaseObjectTypeValues(node: string): Observable<any> {
    return this.afdb.object(node).valueChanges();
  }

  firebaseUpdateNodeByPush(rootNode: string, obj: any) {
    this.afdb.list(rootNode).push(obj);
  }
}
