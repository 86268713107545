import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { LeadsService } from '../leads.service';
import { FirebaseService } from 'src/app/service/firebase.service';
import { CommonService } from 'src/app/service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'ngx-alerts';
import { CardealerDbService } from 'src/app/firebase-service/cardealer-db.service';
import { SendSmsComponent } from 'src/app/common-utility/send-sms/send-sms.component';
import { MatDialog } from '@angular/material/dialog';
import { SendEmailComponent } from 'src/app/common-utility/send-email/send-email.component';

@Component({
  selector: 'app-leads-enquiry',
  templateUrl: './leads-enquiry.component.html',
  styleUrls: ['./leads-enquiry.component.css']
})
export class LeadsEnquiryComponent implements OnInit {

  leads: any = { address: {}, currentFinance: {}, currentInsurance: {}, enquiry: {}, existingVehicle: [{}], comments: {}, updatedBy: {}, vehicle: {} };
  uid: string;
  profileForm: FormGroup;
  user: any;
  disableBtn: boolean = false;
  yearArray = [];
  makeArray = [];
  modelArray = [];
  InsuranceCompanyList = [];
  financeBankList = [];
  enquirySourceList = [];
  enquiryAwarenessList = [];
  employeeList = [];
  vehicle: any = {};
  selectedVehicleId: any;
  currentDate = new Date();
  constructor(private leadsService: LeadsService, private fireService: FirebaseService, private commonService: CommonService
    , private router: Router, private ngxService: NgxUiLoaderService, private route: ActivatedRoute
    , private alertService: AlertService, private caredealerFireDB: CardealerDbService, private dialog : MatDialog) {
    this.user = commonService.getUserInfo();
  }

  getEnquirySources = () => {
    let nodepath = 'dealerSetting/enquirySource/' + this.commonService.getGroupUId() + "/" + this.commonService.getDealerUId();
    this.caredealerFireDB.getFirebaseListTypeValues(nodepath).subscribe(res => {
      this.enquirySourceList = res;
    });
  }

  getEnquiryAwareness = () => {
    let nodepath = 'dealerSetting/enquiryAwareness/' + this.commonService.getGroupUId() + "/" + this.commonService.getDealerUId();
    this.caredealerFireDB.getFirebaseListTypeValues(nodepath).subscribe(res => {
      this.enquiryAwarenessList = res;
    });
  }

  getEmployess = () => {
    let nodepath = 'employee' + "/" + this.commonService.getDealerUId();
    this.caredealerFireDB.getFirebaseListTypeValues(nodepath).subscribe(res => {
      this.employeeList = res;
    });
  }

  getInsuranceCompanyList = () => {
    let nodepath = "insurance-company/";
    this.caredealerFireDB.getFirebaseListTypeValues(nodepath).subscribe(res => {
      this.InsuranceCompanyList = res;
    });
  }

  getFinanceBankNames = (groupUId) => {
    this.leadsService.getBankNames(groupUId).subscribe((res: any) => {
      if (res.status == "success" && res.data)
        this.financeBankList = Object.values(res.data);
    });
  }

  getVehicleDetails = () => {
    this.ngxService.startBackground();
    this.fireService.get('purchase/inventory/' + this.commonService.getGroupUId() + '/' + this.commonService.getDealerUId() + "/" + this.selectedVehicleId + "/vehicleInformation").then(res => {
      if (res.val()) {
        this.vehicle = res.val();
        this.vehicle['primaryDescriptionId'] = this.selectedVehicleId;
      }
      this.ngxService.stopBackground();
    })
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.selectedVehicleId = params['params'] && params['params']['selectedVehicleId'] ? params['params']['selectedVehicleId'] : null;
      if (this.selectedVehicleId) {
        this.getVehicleDetails();
        let draft = this.commonService.getLeadsDraft();
        if (draft) {
          this.leads = draft;
        }
      }

    });
    this.leads.honorific = this.leads.honorific ? this.leads.honorific : "Mr";
    this.leads.customerType = this.leads.customerType ? this.leads.customerType : 'individual';
    this.leads.groupUId = this.leads.groupUId ? this.leads.groupUId : this.commonService.getGroupUId();
    this.leads.dealerUId = this.leads.dealerUId ? this.leads.dealerUId : this.commonService.getDealerUId();
    this.leads.leadType = this.leads.leadType ? this.leads.leadType : "cold";
    this.leads.enquiry = this.leads.enquiry ? this.leads.enquiry : {};
    this.leads.enquiry.enquiryDate = this.leads.enquiry.enquiryDate ? this.leads.enquiry.enquiryDate : this.currentDate;
    console.log('leads======', this.leads);
    this.profileForm = new FormGroup({
      firstName: new FormControl(this.leads.firstName, [
        Validators.required,
      ]),
      lastName: new FormControl(this.leads.lastName, [
        Validators.required,
      ]),
      mobile: new FormControl(this.leads.mobile, [
        Validators.required,
      ]),
    });
    this.oldYearArray(1990, new Date().getFullYear());
    this.getOldMakeList(null);
    this.getFinanceBankNames(this.commonService.getGroupUId());
    this.getEnquirySources();
    this.getEnquiryAwareness();
    this.getInsuranceCompanyList();
    this.getEmployess();
    let currentLead = this.commonService.getCurrentLead();
    if (currentLead) {
      this.leadsService.getLeads(currentLead.groupUId, currentLead.dealerUId, currentLead.pushKey).subscribe((res: any) => {
        if (res.status) {
          this.leads = Object.assign(this.leads, res.content);
          this.commonService.setCurrentLead(this.leads);
          this.vehicle = this.leads.vehicle ? this.leads.vehicle : {};
        }
      });
    }

  }
  oldYearArray = (minYear, maxYear) => {
    while (minYear <= maxYear) {
      this.yearArray.push(maxYear--);
    }
  }

  getOldMakeList = (makeName) => {
    this.makeArray = null;
    this.leadsService.getOldMakeList(makeName).subscribe((promise: any) => {
      if (promise.status == "success") {
        this.makeArray = promise.data;
      }
    })
  }

  selectOldMakeList = (item) => {
    this.makeArray.forEach((makeItem) => {
      if (makeItem.make == item.make) {
        item.makeId = makeItem.makeId;
        this.getOldModelList(item);
      }
    })
  }

  getOldModelList = (item) => {
    item.modelArray = null;
    this.leadsService.getOldModelList(item.makeId).subscribe((promise: any) => {
      if (promise.status == "success") {
        item.modelArray = promise.data;
      }
    })
  }

  onSubmit = () => {
    if (!this.profileForm.valid) {
      return;
    } else {
      this.disableBtn = true;
      //is Other State Code
      if (this.leads.address && this.leads.address.state != this.user.state) {
        this.leads.isOtherState = true;
      } else {
        this.leads.isOtherState = false;
      }
      //END
      //For Current Old Vehicle
      try {
        this.leads.existingVehicle = this.leads.existingVehicle.filter((item) => {
          if (item.make) {
            delete item.modelArray;
            return item;
          }
        });
      } catch (error) {
        console.log(error);
        this.leads.existingVehicle = null;
      }
      //END

      let timestamp = new Date().getTime();
      this.leads.createdBy = this.leads.createdBy ? this.leads.createdBy : (this.user.empUId ? this.user.empUId : this.user.uid);
      this.leads.creationDate = this.leads.creationDate ? this.leads.creationDate : timestamp;
      this.leads.lastupdatedBy = this.user.empUId ? this.user.empUId : this.user.uid;
      this.leads.lastupdationDate = timestamp;
      this.leads.firstName = this.leads.firstName ? this.leads.firstName.toUpperCase() : '';
      this.leads.middleName = this.leads.middleName ? this.leads.middleName.toUpperCase() : '';
      this.leads.lastName = this.leads.lastName ? this.leads.lastName.toUpperCase() : '';
      this.leads.customerName = (this.leads.firstName ? this.leads.firstName + ' ' : '') + '' + (this.leads.middleName ? this.leads.middleName + ' ' : '') + "" + (this.leads.lastName ? this.leads.lastName : '');
      this.leads.customerName = this.leads.customerName ? this.leads.customerName.toLowerCase() : '';
      this.leads.fullName = this.leads.customerName.toUpperCase();
      this.leads.email = this.leads.email ? this.leads.email.toLowerCase() : this.leads.email;
      this.leads.status = this.leads.status ? this.leads.status : 'enquiry';
      this.leads.updatedBy = this.leads.updatedBy ? this.leads.updatedBy : {};
      this.leads.updatedBy[this.leads.lastupdatedBy] = { 'name': (this.user.firstName ? this.user.firstName + ' ' : '') + '' + (this.user.middleName ? this.user.middleName + ' ' : '') + "" + (this.user.lastName ? this.user.lastName : ''), 'timestamp': timestamp, 'uid': this.leads.lastupdatedBy };
      this.leads.comments = this.leads.comments ? this.leads.comments : {};
      let commentsId = this.leads.enquiry.commentsId ? this.leads.enquiry.commentsId : timestamp;

      if (this.leads.enquiry.enquiryDate) {
        this.leads.enquiry.enquiry_timestamp = new Date(this.leads.enquiry.enquiryDate).getTime();
      }
      if (this.leads.enquiry.remarks) {
        this.leads.comments[commentsId] = { 'comments': this.leads.enquiry.remarks, 'givenBy': this.leads.lastupdatedBy, 'timestamp': commentsId, 'status': 'enquiry', 'givenByName': (this.user.firstName ? this.user.firstName + ' ' : '') + '' + (this.user.middleName ? this.user.middleName + ' ' : '') + "" + (this.user.lastName ? this.user.lastName : '') };
        this.leads.enquiry.commentsId = commentsId;
      }
      //for recent activity
      this.leads.recentActivity = this.leads.recentActivity ? this.leads.recentActivity : {};
      this.leads.recentActivity[timestamp] = { 'comments': "Enquiry process done", 'givenBy': this.leads.lastupdatedBy, 'timestamp': timestamp, 'status': 'enquiry', 'givenByName': (this.user.firstName ? this.user.firstName + ' ' : '') + '' + (this.user.middleName ? this.user.middleName + ' ' : '') + "" + (this.user.lastName ? this.user.lastName : '') };
      //for vehicle
      this.leads.vehicle = this.vehicle;
      console.log('this.leads===', this.leads);
      console.log('assigned name===', this.leads.assignedName);
      this.ngxService.startBackground();
      this.leadsService.saveEnquiry(this.leads).subscribe((res: any) => {
        console.log(res)
        this.ngxService.stopBackground();
        if (res.status) {
          this.leads = Object.assign(this.leads, res.content);
          this.commonService.setCurrentLead(this.leads);
          this.alertService.success("Record saved successfully");
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate(['/home/leads-summary']));
        } else if (res.message == "DUPLICATE_PHONE") {
          this.alertService.warning("Phone number already exists. Please check and try again.");
        } else {
          this.alertService.warning('Unable to save. Please check and try again.');
        }
      }, err => {
        this.ngxService.stopBackground();
        this.alertService.danger('Network connection not available. Please check and try again.');
      });
    }
  }

  selectVehicle = () => {
    this.commonService.setLeadsDraft(this.leads);
    this.router.navigate(['home/allotment-vehicle-list']);
  }

  changeLeadType = (date) => {
    var enquiryTime =  new Date(date).getTime();
    var currTime = new Date().getTime();
   var days =  Math.round((enquiryTime - currTime)/(1000*60*60*24))
   if(days <= 15) {
        this.leads.leadType = 'hot';
   } else if( days > 15 && days <= 30) {
        this.leads.leadType = 'warm';
   } else {
        this.leads.leadType = 'cold';
   }
    this.leads.status = this.leads.status ? this.leads.status : 'followup';
};


sendMessage() {
  const dialogRef = this.dialog.open(SendSmsComponent,
    { width: '500px', height: "450px", disableClose: true
    , data: {customer: this.leads, phone: this.leads.mobile} });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}

sendMail() {
  const dialogRef = this.dialog.open(SendEmailComponent,
    { width: '500px', height: "450px", disableClose: true
    , data: {customer: this.leads, email: this.leads.email}  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}

}
