import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/service/common.service';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-monthly-sales',
  templateUrl: './monthly-sales.component.html',
  styleUrls: ['./monthly-sales.component.css']
})
export class MonthlySalesComponent implements OnInit {

  tableHeader = [];
  dataList = [];
  toDate: any;
  fromdate: any;
  constructor(private commonService: CommonService, private service: ReportsService, private ngxUIService: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.setHeaderMenu();
    let fromDt = new Date();
    fromDt.setDate(1);
    this.fromdate = fromDt.getTime();
    this.toDate = new Date().getTime();
    this.getMonthlySales();
  }

  getMonthlySales = () => {
    this.dataList = [];
    this.ngxUIService.startBackground();
    this.service.getMonthlySales(this.commonService.getGroupUId(), this.commonService.getDealerUId(), this.fromdate, this.toDate).subscribe((res: any) => {
      if(res.status) {
        this.createDataArr(Object.values(res.content));
      }
      this.ngxUIService.stopBackground();
    }, err => {
      console.log(err);
      this.ngxUIService.stopBackground();
    });
  }

  createDataArr = (list) => {
    this.dataList = [];
    list.forEach(element => {
      let json = element.vehicle ? element.vehicle : {};
      json['buyerName'] = element.fullName;
      json['salesDate'] = element.booking && element.booking.billingDate ? element.booking.billingDate : null;
      this.dataList.push(json);
    });
  }

  setHeaderMenu = () => {
    this.tableHeader = [
      {displayName: "BUYER NAME", keyName: "buyerName", selected: true},
      {displayName: "SELLAR NAME", keyName: "sellerName", selected: true},
      {displayName: "USED CAR EXC.NAME", keyName: "", selected: true},
      {displayName: "BROKER / CUSTOMER", keyName: "", selected: true},
      {displayName: "CONTACT DETAILS OF BROKER/CUSTOMER", keyName: "", selected: true},
      {displayName: "REGN. NO.", keyName: "", selected: true},
      {displayName: "CUBIC CAPACITY", keyName: "", selected: true},
      {displayName: "VIN NO", keyName: "vin", selected: true},
      {displayName: "REGN. Model", keyName: "model", selected: true},
      {displayName: "MFG YEAR", keyName: "year", selected: true},
      {displayName: "NO. OF OWNER	", keyName: "owners", selected: true},
      {displayName: "COLOUR	", keyName: "color", selected: true},
      {displayName: "NEW CAR	", keyName: "", selected: true},
      {displayName: "INSURANCE EXPY. DT	", keyName: "", selected: true},
      {displayName: "INSURANCE COMPANY NAME	", keyName: "", selected: true},
      {displayName: "THIRD PARTY/COMPREHENSIVE	", keyName: "", selected: true},
      {displayName: "KMS	", keyName: "km", selected: true},
      {displayName: "PURCHASE DATE	", keyName: "purchaseDate", selected: true},
      {displayName: "SALE DATE	", keyName: "salesDate", selected: true},
      {displayName: "NO. OF DAYS	", keyName: "", selected: true},
      {displayName: "ACTUAL SALE PRICE	", keyName: "", selected: true},
      {displayName: "EXTENDED WARRANT COLL.	", keyName: "", selected: true},
      {displayName: "RTO MARGIN	", keyName: "", selected: true},
      {displayName: "FINANCE PAYOUT	", keyName: "", selected: true},
      {displayName: "ACCESSORY SALE	", keyName: "", selected: true},
      {displayName: "TOTAL SALE AMOUNT	", keyName: "", selected: true},
      {displayName: "PURCHASE PRICE	", keyName: "", selected: true},
      {displayName: "HANDLING CHARGES	", keyName: "handlingCharges", selected: true},
      {displayName: "REFURBISHMENT EXPENSES	", keyName: "", selected: true},
      {displayName: "FORD EXTENDED WARRENTY	", keyName: "", selected: true},
      {displayName: "Accessory Pur.price	", keyName: "", selected: true},
      {displayName: "INTEREST COST	", keyName: "", selected: true},
      {displayName: "ADMIN COST & SALARY	", keyName: "", selected: true},
      {displayName: "LANDED COST	", keyName: "", selected: true},
      {displayName: "GROSS MARGIN	", keyName: "", selected: true},
      {displayName: "GST 18% OR 12% AS PER CAR SIZE	", keyName: "", selected: true},
      {displayName: "NET PROFIT/LOSS	", keyName: "", selected: true},
      {displayName: "PROFIT & LOSS W/O ADMIN EXP.", keyName: "", selected: true}
    ]
  }

}
