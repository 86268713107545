<ngx-alerts></ngx-alerts>
<div class="container-fluid" style="padding: 0px;">
    <div fxLayout="row wrap" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" style="margin-top: 0px;">
        <div class="col-sm-12 header-css">
            <div fxLayout="row">
                <div fxFlex="50" style="padding-top: 10px;">
                    <h3 style="margin-bottom: 0px;font-size: 18px;">Customer Documents</h3>
                </div>
                <div fxFlex="50" style="text-align: right;">
                    <button style="float: right; margin-top: 6px;" mat-raised-button color="accent"
                        [routerLink]="['/home/dealer-setting']">
                        <mat-icon>arrow_back</mat-icon>&nbsp;Back
                    </button>
                </div>
            </div>
        </div>
        <div fxFlex="63">
            <mat-card>
                <mat-card-title style="font-size: 20px;">Document List</mat-card-title>
                <div style="margin-top: 20px;">
                    <div *ngIf="kycList.length > 0" style="position: relative;">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead class="headerColor">
                                    <tr>
                                        <th class="textCenter" style="width: 5%;">Serial#</th>
                                        <th style="width: 15%;">Document Name</th>
                                        <th style="width: 38%;">Description</th>
                                        <th class="textCenter" style="width: 12%;">
                                            <mat-icon style="vertical-align: bottom;">settings</mat-icon>
                                            &nbsp;&nbsp;Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of kycList; let index = index">
                                        <td class="textCenter" style="width: 5%;">{{index + 1}}</td>
                                        <td style="width: 15%;">{{item.name}}</td>
                                        <td style="width: 38%;">{{item.description}}</td>
                                        <td class="textCenter" style="width: 12%; cursor: pointer;">
                                            <mat-icon (click)="editKyc(item, item.id)" matTooltip="Edit Category"
                                                color="primary" style="margin-right: 15px;">
                                                edit</mat-icon>
                                            <mat-icon (click)="removeItem(item, item.id)"  matTooltip="Delete Document"
                                                class="text-danger">delete</mat-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div *ngIf="kycList.length == 0" class="noRecordFound">
                        <h5>No record found</h5>
                    </div>

                </div>
            </mat-card>
        </div>

        <div fxFlex="35">
            <mat-card>
                <mat-card-content>
                    <mat-card-title style="font-size: 20px;">Create Document</mat-card-title>
                    <form autocomplete="off" #KycForm="ngForm" (ngSubmit)="saveDocument(KycForm)">
                        <div fxLayout="row wrap">
                            <div fxFlex.gt-sm="100" fxFlex="100" style="margin-top: 10px;">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Document Name" [(ngModel)]="kycData.name" name="name"
                                        required>
                                </mat-form-field>
                            </div>

                            <div fxFlex.gt-sm="100" fxFlex="100" style="margin-top: 10px;">
                                <mat-form-field style="display: initial;">
                                    <textarea rows="3" matInput placeholder="Description"
                                        [(ngModel)]="kycData.description" name="description"></textarea>
                                </mat-form-field>
                            </div>

                            <div fxFlex.gt-sm="100" fxFlex="100" style="margin-top: 20px;">
                                <button mat-raised-button color="warn" type="button" (click)="resetForm()"
                                    style="background: linear-gradient(60deg, rgb(253, 156, 30), rgb(251, 192, 27)); border-color:rgb(30,144,255);">
                                    <mat-icon>cached</mat-icon>&nbsp;Reset
                                </button>
                                <button mat-raised-button color="primary" type="submit"
                                    style="float: right; background: linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255)); border-color:rgb(30,144,255)">
                                    <mat-icon>save_alt</mat-icon>&nbsp;Save
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

</div>