<!-- 
<div fxFlex="100">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px" fxLayoutGap.sm="0px">
        <mat-card [class.mat-elevation-z3]="true" fxFlex="49">
            <mat-card-header>
                <mat-card-title>Customer Information</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxFlex="100">
                    <app-leads-info></app-leads-info>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card [class.mat-elevation-z3]="true" fxFlex="50">
            <mat-card-header>
                <div fxFlex="100">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px" fxLayoutGap.sm="0px"
                        style="margin-top: 1px !important;">
                        <div fxFlex style="font-size: 20px;">
                            Vehicle Information
                        </div>
                        <div fxLayoutAlign="end end" fxFlex>
                        </div>
                    </div>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div fxFlex="100">
                    <app-vehicle-info></app-vehicle-info>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px" fxLayoutGap.sm="0px" style="margin-top: 1px !important;">
        <mat-card [class.mat-elevation-z3]="true" fxFlex="49">
            <mat-card-header>
                <div style="width: 100%;">
                    <mat-card-title>
                        <div style=" font-size: 20px; margin-right: 31px;">Deal
                            #{{currentLead.dealNumber}}</div>
                            <small style="font-size: 12px;">{{currentLead.dealJacketStatus ?  (currentLead.dealJacketStatus.substring(0, 1).toUpperCase() +  currentLead.dealJacketStatus.substring(1) ) : ""}}</small>
                            <small style="font-size: 12px;" *ngIf="!currentLead.dealJacketStatus">&nbsp;</small>
                        </mat-card-title>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div fxFlex="100">
                    <app-leads-process></app-leads-process>
                </div>
        </mat-card>
        <mat-card [class.mat-elevation-z3]="true" fxFlex="50" style="padding-left: 0px; padding-right: 0px;">
            <mat-card-header>
                <mat-card-title>
                    <div style=" font-size: 20px; margin-right: 31px;">
                        Deal Progress</div>
                        <small style="font-size: 12px;">&nbsp;</small>
                    </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxFlex="100">
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div> -->

<div fxFlex="100" style="margin-bottom: 20px; margin-left: 15px; margin-right: 15px;">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.sm="0px" style="margin-top: 10px;">
        <div fxFlex="36">
            <div class="row no-margin">
                <mat-accordion fxFlex>
                    <mat-expansion-panel [expanded]="false" class="recentActivityPanel">
                        <mat-expansion-panel-header class="card-header" [collapsedHeight]="'42px'"
                            [expandedHeight]="'42px'">
                            <mat-panel-title style="margin-left: 10px">
                                Recent Activity
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="height: 200px; overflow: auto; padding: 8px;">
                            <div fxFlex="100">
                                <div class="row no-margin" style="margin-top:5px;"
                                    *ngFor="let item of recentActivity | orderBy : 'timestamp'">
                                    <div class="col-sm-5">
                                        <p><strong>{{timeToAgo(item.timestamp)}}</strong></p>
                                    </div>
                                    <div class="col-sm-7 no-padding">
                                        <span>{{item.comments}}</span><br />
                                        <span style="font-size: 13px;"><i>by&nbsp;{{item.givenByName}}</i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <!-- <mat-card [class.mat-elevation-z6]="true" fxFlex class="card" >
                    <div class="row card-header" >
                        <div class="col-sm-6">
                           Recent Activity
                        </div>
                    </div>
                    <mat-card-content style="height: 200px; overflow: auto; padding: 8px;">
                        <div fxFlex="100">
                            <div class="row no-margin" *ngFor="let item of recentActivity | orderBy : 'timestamp'">
                                <div class="col-sm-4">
                                   <p><strong>{{timeToAgo(item.timestamp)}}</strong></p>
                                </div>
                                <div class="col-sm-8 no-padding" >
                                    <p>{{item.comments}}</p>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card> -->
            </div>
            <div class="row no-margin" style="margin-top: 10px;">
                <mat-card [class.mat-elevation-z6]="true" fxFlex class="card">
                    <div class="row card-header">
                        <div class="col-sm-6">
                            Deal#&nbsp;{{currentLead.dealNumber}}
                        </div>
                        <div class="col-sm-6 text-right">
                            <span id="edit-btn" [routerLink]="['/home/leads-enquiry']">Edit</span>
                        </div>
                    </div>
                    <mat-card-content>
                        <div fxFlex="100">
                            <div class="row no-margin">
                                <div class="col-sm-12 text-center no-padding">
                                    <img *ngIf="vehicleInfo.vehicleImages && vehicleInfo.vehicleImages[0]"
                                        [src]="vehicleInfo.vehicleImages[0].downloadUrl" class="image-style">
                                    <img *ngIf="!vehicleInfo.vehicleImages" src="../../../../assets/images/notfound.png"
                                        class="image-style">
                                </div>
                                <div class="col-sm-12 text-center vehicle-info">
                                    <ng-container *ngIf="vehicleInfo">
                                        <div style="font-size: 20px; margin-bottom: 10px;">
                                            <strong>{{vehicleInfo.year}}&nbsp;{{vehicleInfo.makeName}}
                                                &nbsp;{{vehicleInfo.modelName}}</strong>
                                        </div>
                                        <div *ngIf="vehicleInfo.vin"
                                            style="font-size: 16px; border-bottom: 1px solid #3ea2f7;     padding-bottom: 8px;">
                                            VIN:&nbsp;{{vehicleInfo.vin  }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row no-margin" style="margin-bottom: 10px;">
                                <div class="col-sm-4 text-center no-padding">
                                    <i class="fa fa-user-circle-o" style="font-size: 70px;"></i>
                                </div>
                                <div class="col-sm-8 no-padding">
                                    <ng-container>
                                        <div style="font-size: 20px; margin-bottom: 10px;">
                                            <strong>{{currentLead.fullName}}</strong>
                                        </div>
                                        <div style="font-size: 16px;">
                                            <i class="fa fa-phone"></i>&nbsp;{{currentLead.mobile  }}
                                        </div>
                                        <div style="font-size: 16px; ">
                                            <i class="fa fa-envelope"></i>&nbsp;{{currentLead.email  }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div fxFlex>
            <mat-card [class.mat-elevation-z6]="true" fxFlex class="card">
                <div class="row card-header">
                    <div class="col-sm-6">
                        Workflow
                    </div>
                    <div class="col-sm-6 text-right">
                        {{stagesCompleted}}/6 Steps Completed
                    </div>
                </div>
                <mat-card-content style="padding: 8px;">
                    <div fxFlex="100">
                        <div class="row no-margin">
                            <div class="col-sm-12">
                                <!-- <mat-card [class.mat-elevation-z3]="true" fxFlex > -->
                                <div class="row no-margin">
                                    <div class="col-sm-4 menu-font"><b>Enquiry</b></div>
                                    <div class="col-sm-4 menu-font">
                                        <div *ngIf="leadProcess.enquiryDone == 'Y'">
                                            {{leadProcess.enquiryDoneDate | date : "medium"}}</div>
                                    </div>
                                    <div class="col-sm-4 text-right no-padding">
                                        <ng-container
                                            *ngIf="leadProcess.enquiryDone == 'Y'; then trueCondition else elseTemplate">
                                        </ng-container>
                                        <ng-template #trueCondition>
                                            <div class="menu-font">
                                                <span><b>{{datToTimesAgo(leadProcess.enquiryDoneDate)}}</b></span>
                                                <br />
                                                <span
                                                    style="font-size: 13px;"><i>by&nbsp;{{workFlowGivenByName}}</i></span>

                                            </div>
                                        </ng-template>
                                        <ng-template #elseTemplate>
                                            <button mat-raised-button [routerLink]="['/home/leads-enquiry']"
                                                class="start-btn">Start</button>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row no-margin">
                                    <div class="col-sm-4 menu-font"><b>Follow-up</b></div>
                                    <div class="col-sm-4 menu-font">
                                        <div *ngIf="leadProcess.followupDone == 'Y'">
                                            {{leadProcess.followupDoneDate | date : "medium"}}</div>
                                    </div>
                                    <div class="col-sm-4 text-right no-padding">
                                        <ng-container
                                            *ngIf="leadProcess.followupDone == 'Y'; then trueConditionF else elseTemplateF">
                                        </ng-container>
                                        <ng-template #trueConditionF>
                                            <div class="menu-font">
                                                <span><b>{{datToTimesAgo(leadProcess.followupDoneDate)}}</b></span><br />
                                                <span
                                                    style="font-size: 13px;"><i>by&nbsp;{{workFlowGivenByName}}</i></span>
                                            </div>
                                        </ng-template>
                                        <ng-template #elseTemplateF>
                                            <button mat-raised-button type="button"
                                                [routerLink]="['/home/leads-followup']" class="start-btn">Start</button>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row no-margin">
                                    <div class="col-sm-4 menu-font"><b>Test Drive</b></div>
                                    <div class="col-sm-4 menu-font">
                                        <div *ngIf="leadProcess.testdriveDone == 'Y'">
                                            {{leadProcess.testdriveDoneDate | date : "medium"}}</div>
                                    </div>
                                    <div class="col-sm-4 text-right no-padding">
                                        <ng-container
                                            *ngIf="leadProcess.testdriveDone == 'Y'; then trueConditionT else elseTemplateT">
                                        </ng-container>
                                        <ng-template #trueConditionT>
                                            <div class="menu-font">
                                                <span><b>{{datToTimesAgo(leadProcess.testdriveDoneDate)}}</b></span><br />
                                                <span
                                                    style="font-size: 13px;"><i>by&nbsp;{{workFlowGivenByName}}</i></span>
                                            </div>
                                        </ng-template>
                                        <ng-template #elseTemplateT>
                                            <button mat-raised-button [routerLink]="['/home/leads-testdrive']"
                                                class="start-btn">Start</button>
                                        </ng-template>
                                    </div>
                                </div>
                                <!-- </mat-card> -->
                            </div>
                            <div class="col-sm-12">
                                <!-- <mat-card [class.mat-elevation-z3]="true" fxFlex > -->
                                <div class="row no-margin">
                                    <div class="col-sm-4 menu-font"><b>Booking</b></div>
                                    <div class="col-sm-4 menu-font">
                                        <div *ngIf="leadProcess.bookingDone == 'Y'">
                                            {{leadProcess.bookingDoneDate | date : "medium"}}</div>
                                    </div>
                                    <div class="col-sm-4 text-right no-padding">
                                        <ng-container
                                            *ngIf="leadProcess.bookingDone == 'Y'; then trueConditionB else elseTemplateB">
                                        </ng-container>
                                        <ng-template #trueConditionB>
                                            <div class="menu-font">
                                                <span><b>{{datToTimesAgo(leadProcess.bookingDoneDate)}}</b></span><br />
                                                <span
                                                    style="font-size: 13px;"><i>by&nbsp;{{workFlowGivenByName}}</i></span>
                                            </div>
                                        </ng-template>
                                        <ng-template #elseTemplateB>
                                            <button mat-raised-button [routerLink]="['/home/leads-booking']"
                                                class="start-btn">Start</button>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row no-margin">
                                    <div class="col-sm-4 menu-font"><b>Billing</b></div>
                                    <div class="col-sm-4 menu-font">
                                        <div *ngIf="leadProcess.billingDone == 'Y'">
                                            {{leadProcess.billingDoneDate | date : "medium"}}</div>
                                    </div>
                                    <div class="col-sm-4 text-right no-padding">
                                        <ng-container
                                            *ngIf="leadProcess.billingDone == 'Y'; then trueConditionBi else elseTemplateBi">
                                        </ng-container>
                                        <ng-template #trueConditionBi>
                                            <div class="menu-font ">
                                                <span><b>{{datToTimesAgo(leadProcess.billingDoneDate)}}</b></span><br />
                                                <span
                                                    style="font-size: 13px;"><i>by&nbsp;{{workFlowGivenByName}}</i></span>

                                            </div>
                                        </ng-template>
                                        <ng-template #elseTemplateBi>
                                            <button mat-raised-button [routerLink]="['/home/leads-booking']"
                                                class="start-btn">Start</button>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row no-margin">
                                    <div class="col-sm-4 menu-font"><b>Delivered</b></div>
                                    <div class="col-sm-4 menu-font">
                                        <div *ngIf="leadProcess.deliveredDone == 'Y'">
                                            {{leadProcess.deliveredDoneDate | date : "medium"}}</div>
                                    </div>
                                    <div class="col-sm-4 text-right no-padding">
                                        <ng-container
                                            *ngIf="leadProcess.deliveredDone == 'Y'; then trueConditionD else elseTemplateD">
                                        </ng-container>
                                        <ng-template #trueConditionD>
                                            <div class="menu-font">
                                                <span><b>{{datToTimesAgo(leadProcess.deliveredDoneDate )}}</b></span><br />
                                                <span
                                                    style="font-size: 13px;"><i>by&nbsp;{{workFlowGivenByName}}</i></span>

                                            </div>
                                        </ng-template>
                                        <ng-template #elseTemplateD>
                                            <button mat-raised-button [routerLink]="['/home/leads-booking']"
                                                class="start-btn">Start</button>
                                        </ng-template>
                                    </div>
                                </div>
                                <!-- </mat-card> -->
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>