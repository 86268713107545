import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AlertService } from 'ngx-alerts';
import { environment } from 'src/environments/environment';
import { FirebaseService } from 'src/app/service/firebase.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/service/common.service';
import { ESignatureComponent } from 'src/app/purchase/e-signature/e-signature.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css']
})
export class CreateInvoiceComponent implements OnInit {


  a: any = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  b: any = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  n: any;

  totalPrice: number = 0;
  amountInWords: any;
  signatureImage;

  invoiceModel: any = {
    invoiceNo: '',
    date: '',
    deliveryNote: '',
    modeOfPayment: '',
    supplierRef: '',
    buyerOrderNo: '',
    dated: '',
    despatchDocNo: '',
    deliveryNoteDate: '',
    despatchedThrough: '',
    destination: '',
    termsOfDelivery: '',
    signatureSign: '',
    signatureSigndate: '',

    isEdit: false,
  };

  customerInfo: any;
  vehicleInfo: any;
  user: any = {};
  groupUID: any;

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    private firebaseService: FirebaseService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private router: Router
  ) {
    let currentLead = this.commonService.getCurrentLead();
    if (!currentLead) {
      this.alertService.danger("Please select lead to continue");
      this.router.navigate(['home/leads-list']);
    }
  }

  ngOnInit(): void {
    this.getTimeAndDate();
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.groupUID = this.user.group ? this.user.group.uid : '';
    console.log("--------userInfo------------");
    console.log(this.user);
    this.getCustomerInfo();
  }

  setAmountInWords(amount) {
    debugger;
    this.amountInWords = this.inWords(Number(amount));
  }


  getSavedInvoiceDATA() {
    this.ngxService.startBackground();
    const nodePathUrl = "invoice/" + this.groupUID + "/" + this.user.uid + "/" + this.customerInfo.pushKey;
    this.firebaseService.getFirebaseObjectTypeValues(nodePathUrl).subscribe(res => {
      if (res) {
        this.resetForm();
        this.invoiceModel = res;
        this.invoiceModel.isEdit = true;
        this.signatureImage = res.signatureSign ? res.signatureSign : '';

        this.setAmountInWords(this.customerInfo.vehicle.salePrice);
      } else {
        this.setAmountInWords(this.customerInfo.vehicle.salePrice);
      }

      this.ngxService.stopBackground();
    })
  }

  getTimeAndDate() {
    const moment = require('moment');
    this.invoiceModel.date = moment().format('DD-MMM-YYYY');
    // this.invoiceModel.time = moment().format("hh:mm");
  }

  getCustomerInfo() {
    var defaultVehicle = sessionStorage.getItem(environment.DEFAULT_VEHICLE) != null ? JSON.parse(sessionStorage.getItem(environment.DEFAULT_VEHICLE)) : {};
    //var customerInformation = sessionStorage.getItem(environment.CUSTOMER_INFO) != null ? JSON.parse(sessionStorage.getItem(environment.CUSTOMER_INFO)) : "";
    var customerInformation = this.commonService.getCurrentLead();

    console.log("--------CUrrent lead-----------------");
    console.log(customerInformation);

    if (customerInformation) {
      this.customerInfo = customerInformation;
      this.getSavedInvoiceDATA();
    } else {
      this.customerInfo = {};
      // this.alertService.info('Please select customer to continue');
      // this.router.navigate(['home/leads-list']);
      return;
    }

    if (defaultVehicle) {
      this.vehicleInfo = defaultVehicle;
    } else {
      this.vehicleInfo = null;
    }
  }

  resetForm() {
    this.invoiceModel = {
      invoiceNo: '',
      date: '',
      deliveryNote: '',
      modeOfPayment: '',
      supplierRef: '',
      buyerOrderNo: '',
      dated: '',
      despatchDocNo: '',
      deliveryNoteDate: '',
      despatchedThrough: '',
      destination: '',
      termsOfDelivery: '',
      signatureSign: '',
      signatureSigndate: '',

      isEdit: false,
    };

    this.totalPrice = 0;
    this.amountInWords = '';
    this.signatureImage = '';

    // this.getTimeAndDate();
  }

  openDialog(params: string) {
    const dialogConfig = new MatDialogConfig();
    var vehicle = {};

    dialogConfig.data = vehicle;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.panelClass = 'eSinature-dialog';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ESignatureComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('result====', result);
      if (result) {
        this.signatureImage = result;
        this.invoiceModel.signatureSign = result;
        this.invoiceModel.signatureSigndate = "" + new Date();
      }
    });
  }

  sendFormData() {
    this.ngxService.startBackground();
    console.log(this.invoiceModel);
    if (this.validateForm()) {

      debugger;
      var timestamp = new Date().getTime();
      this.invoiceModel.invalid_flag = 'N';
      this.invoiceModel.delete_flag = 'N';
      this.invoiceModel.created_by = this.invoiceModel.isEdit ? this.invoiceModel.created_by : this.user.uid;
      this.invoiceModel.lastUpdated_by = this.user.uid;
      this.invoiceModel.creation_date = this.invoiceModel.isEdit ? this.invoiceModel.creation_date : timestamp;
      this.invoiceModel.lastUpdated_date = timestamp;


      const nodePathUrl = "invoice/" + this.groupUID + "/" + this.user.uid + "/" + this.customerInfo.pushKey;

      debugger;
      if (this.invoiceModel.isEdit) { // in edit case
        // const finalNodePath = nodePathUrl + "/" + this.invoiceModel.id;
        this.firebaseService.firbaseUpdateData(nodePathUrl, this.invoiceModel);

        this.invoiceModel.isEdit = false;

        this.ngxService.stopBackground();
        this.alertService.success('Invoice updated successfully');

        //route refresh same page.
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        //   this.router.navigate(['/purchaseM/create-delivery-note/']));

        // this.resetForm();

      } else { // in case of new creation
        const finalNodePath = nodePathUrl;
        this.firebaseService.firbaseSetData(finalNodePath, this.invoiceModel);

        this.ngxService.stopBackground();
        this.alertService.success('Invoice created successfully');

      }

    } else {
      this.ngxService.stopBackground();
    }
  }

  validateForm() {
    debugger;
    if (this.invoiceModel.invoiceNo == undefined || this.invoiceModel.invoiceNo == null || this.invoiceModel.invoiceNo == '') {
      this.alertService.danger('Please enter Invoice No.');
      return false;
    } else if (this.customerInfo == undefined || this.customerInfo == null || this.customerInfo == '') {
      this.alertService.danger('Please select customer');
      return false;
    } else if (this.customerInfo != undefined && (this.customerInfo.pushKey == undefined || this.customerInfo.pushKey == null || this.customerInfo.pushKey == '')) {
      this.alertService.danger('Customer uid is missing.');
      return false;
    }
    else {
      return true;
    }
  }


  inWords(num) {

    if ((num = num.toString()).length > 9) return 'overflow';
    this.n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!this.n) return; var str = '';
    str += (this.n[1] != 0) ? (this.a[Number(this.n[1])] || this.b[this.n[1][0]] + ' ' + this.a[this.n[1][1]]) + 'Crore ' : '';
    str += (this.n[2] != 0) ? (this.a[Number(this.n[2])] || this.b[this.n[2][0]] + ' ' + this.a[this.n[2][1]]) + 'Lakh ' : '';
    str += (this.n[3] != 0) ? (this.a[Number(this.n[3])] || this.b[this.n[3][0]] + ' ' + this.a[this.n[3][1]]) + 'Thousand ' : '';
    str += (this.n[4] != 0) ? (this.a[Number(this.n[4])] || this.b[this.n[4][0]] + ' ' + this.a[this.n[4][1]]) + 'Hundred ' : '';
    str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '') + (this.a[Number(this.n[5])] || this.b[this.n[5][0]] + ' ' + this.a[this.n[5][1]]) + '' : '';
    return str;
  }

  public print(): void {
    let printContents, popupWin;
    var doc = document.getElementById('print-section');
    printContents = doc.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
          <style>
          #printContent {
            display: block;
            width: auto;
            height: auto;
            overflow: visible;
            }
            @media print { 
              @page { size: A4 landscape; } 
              .hidden-print {
                display: none !important;
              }
              
              .details-card {
                border: 1px solid #ddd !important;
                min-height: 100px !important;
            }
          }
             table > tbody > tr > td, table > tbody > tr > th,  table > thead > tr > th {
              border: 1px solid #ddd !important;
          }
         table > tbody > tr > td, table > tbody > tr > th, table > thead > tr > th{
              padding: 5px !important;
          }
          table {
            width: 100% !important;
          }
          </style>
        </head>
        <body onload="window.print();window.close()">
        <h4 style='text-align: center;'>Invoice</h4>
          <div id="printContent">${printContents}</div>
        </body>
      </html>`
    );
    popupWin.document.close();
  }

}


