import { Component, OnInit } from '@angular/core';
import { FireAuthService } from 'src/app/service/fire-auth.service';
import { FirebaseService } from 'src/app/service/firebase.service';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CardealerAuthService } from 'src/app/firebase-service/cardealer-auth.service';
import { CardealerDbService } from 'src/app/firebase-service/cardealer-db.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  hide: boolean = false;
  loading: boolean = false;
  constructor(
    private fireAuth : CardealerAuthService, 
    private firebaseService : CardealerDbService,
    // private fireAuth : FireAuthService, 
    // private firebaseService : FirebaseService,
    private router : Router,
    private alertService: AlertService,
    private loaderService: NgxUiLoaderService,
    private commonService : CommonService,
    private authService : FireAuthService
    ) { }

  ngOnInit(): void {
    var user = this.commonService.getUserInfo();
    debugger;
    if (user && Object.keys(user).length > 0) {
      this.authService.resolveMenuOnRefresh();
    }
  }

  submitByEnter = (e) => {
    if ( e.detail == 0) {
     //  this.onSubmit(e);
   } else {
     this.hide = !this.hide;
   }
 }

  onSubmit = () => {
    this.loaderService.startBackground();
    this.fireAuth.login(this.email, this.password).then(res => {
      if (res && res['user']) {
        let nodepath = "users/" + res['user']['uid'];
        this.firebaseService.get(nodepath).then(res => {
          let user = res.val();
          if(user['employeeAdmin'] || user['dealerAdmin'] ) {
            user['firebaseUId'] = user['uid'];
            sessionStorage.setItem('user', JSON.stringify(user));
            this.router.navigate(['home/leads-list']);
            this.loaderService.stopBackground();
          } else {
            this.loaderService.stopBackground();
            this.alertService.danger("Access Denied");
            this.fireAuth.logout();
          }
        });
      } else {
        this.loaderService.stopBackground();
      }
    }).catch((err) => {
      console.log(err);
      this.loaderService.stopBackground();
        this.alertService.danger(err['message']);
    })
  }
}
