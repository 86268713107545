import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LeadsService } from 'src/app/dashboard/leads/leads.service';
import { DialogData } from 'src/app/purchase/search-dialog/search-dialog.component';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-search-leads-dialog',
  templateUrl: './search-leads-dialog.component.html',
  styleUrls: ['./search-leads-dialog.component.css']
})
export class SearchLeadsDialogComponent implements OnInit {

  customerBy: string = "Name";
  vehicleBy: string ="Model";
  user: any;
  customerVal: any;
  customerList : any = [];
  vehicleList: any = [];
  selectedCustomer : any;
  vehicle: any;
  requestedApplication: string = null;
  
  constructor(private service: LeadsService, 
    private router: Router,
    private commonService : CommonService,
    public dialogRef: MatDialogRef<SearchLeadsDialogComponent>,
    private  loaderService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
  }

  ngOnInit() {
    this.user = this.commonService.getUserInfo();
    if(this.data.searchValue) {
      this.customerVal = this.data.searchValue;
      this.requestedApplication = this.data.requestedApplication;
      this.searchCustomers();
    }
  }

  searchCustomers = () => {
    let flag = true;
    if(this.customerVal.length < 3) {
      return;
    }
    this.loaderService.startBackground()
    if("Mobile" == this.customerBy) {
      if(this.customerVal.length != 10) {
        flag = false;
      }
    }
    if(flag) {
      let body = {};
      this.customerList = [];
      this.vehicleList = [];
      body['groupId'] = this.commonService.getGroupUId();
      body['dealerId'] = this.commonService.getDealerUId();
      body['customerVal'] = this.customerVal;
      body['customerBy'] = this.customerBy;
      this.service.searchLeads(body).subscribe(res => {
        console.log(res);
        if(res['status']) {
          this.customerList = res['content'];
        }
        this.loaderService.stopBackground();
      }, err => {
        this.loaderService.stopBackground();
        console.log(err);
      });
    }
    
  }

  selectCustomer = (customer) => {
    this.dialogRef.close();
    this.commonService.setCurrentLead(customer);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
       this.router.navigate(['/home/leads-summary']));
  }

  resetCustomer = () => {
    this.commonService.resetCurrentLead();
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
       this.router.navigate(['/home/leads-list']));
  }
}
