import { Component, OnInit, Input } from '@angular/core';
import { FireAuthService } from 'src/app/service/fire-auth.service';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SearchLeadsDialogComponent } from './search-leads-dialog/search-leads-dialog.component';
import { SmsDialogComponent } from './sms-dialog/sms-dialog.component';
import { CardealerAuthService } from 'src/app/firebase-service/cardealer-auth.service';
import { MenuDealer } from 'src/app/dashboard/menu-dealer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input("rightDrawer")
  public rightDrawer: any;
  currentLead: any = null;
  vehicleInfo: any = {}
  searchValue: string = null;
  constructor(private authService: CardealerAuthService
    , private commonService: CommonService, private router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.currentLead = this.commonService.getCurrentLead();
    if (this.currentLead) {
      this.vehicleInfo = this.currentLead.vehicle ? this.currentLead.vehicle : {};
    }
  }
  resetLead = () => {
    this.commonService.resetCurrentLead();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/home/leads-list']));
  }

  logout() {
    this.commonService.resetLeadDraft();
    this.commonService.resetCurrentLead();
    this.authService.logout();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SearchLeadsDialogComponent, {
      width: '95vw',
      data: {
        searchValue: this.searchValue,
      },
      disableClose: true,
      position: {
        top: '70px',
        left: '215px'
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.searchValue = null;
    });
  }

  sendSMS(customer) {
    const dialogRef = this.dialog.open(SmsDialogComponent, {
      width: '50vw',
      data: {
        customer: customer,
      },
      disableClose: true,
      position: {
        top: '70px',
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.searchValue = null;
    });
  }

}
