import { Component, OnInit } from '@angular/core';
import { MenuDealer } from '../menu-dealer';
import { Router, ActivatedRoute } from '@angular/router';
import { FireAuthService } from 'src/app/service/fire-auth.service';
import { CommonService } from 'src/app/service/common.service';
import { CardealerAuthService } from 'src/app/firebase-service/cardealer-auth.service';
import { FireEventService } from 'src/app/service/fire-event.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  sideMenu: any = [];
  selectIndex = 0;
  hide: boolean = false;
  currentRoute = "";
  user: any = {};
  dealerMenu : any = {};
  currentLead: any = {};
  constructor(private router: Router, private route: ActivatedRoute
    , private fireAuthSer: CardealerAuthService, private commonService: CommonService,
    private fireEvent: FireEventService
    ) {
      this.fireEvent.getOptionLink().subscribe((res: any) => {
        console.log(res);
        if(res.link)
          this.changeOption(res.link);
      }) }


  ngOnInit(): void {
    this.createSideMenu();
    this.user = JSON.parse(sessionStorage.getItem('user'));
  }

  returnZero() {
    return 0
  }

  logout = () => {
    this.fireAuthSer.logout();
  }

  public createSideMenu() {

    //this.sideMenu = [
    //{ 'label': 'Menu 1', 'selected': true, 'icon': 'person_pin', 'route': 'customer-home-personal' },
    // { 'label': 'Delivery Note', 'selected': false, 'icon': 'person_pin', 'route': 'create-delivery-note' },
    // { 'label': 'Sales Contact', 'selected': false, 'icon': 'person_pin', 'route': 'create-sales-contract' },
    // { 'label': 'Purchase Agreement', 'selected': false, 'icon': 'person_pin', 'route': 'create-purchase-agreement' },
    // { 'label': 'Form 28', 'selected': false, 'icon': 'person_pin', 'route': 'create-form-twentyEight' },
    // { 'label': 'Form 29', 'selected': false, 'icon': 'person_pin', 'route': 'create-form-twentyNine' },
    // { 'label': 'Form 30', 'selected': false, 'icon': 'person_pin', 'route': 'create-form-thirty' },
    // { 'label': 'Delivery Challan', 'selected': false, 'icon': 'person_pin', 'route': 'create-delivery-challan' },
    // { 'label': 'Invoice', 'selected': false, 'icon': 'person_pin', 'route': 'create-invoice' },

    //{ "label": "Purchase", 'selected': false, 'icon': 'shopping_cart', "route": "purchase" },

    //]
    //}


    this.sideMenu = new MenuDealer();
    this.updateDealerMenu();
  }

  updateDealerMenu() {
    this.currentLead = this.commonService.getCurrentLead();
    for(var key  in this.sideMenu){
        if(key == 'SALE') {
          if(this.currentLead && Object.keys(this.currentLead).length > 0) {
            var obj =  { 'label': 'Summary', 'title': 'Summary', 'selected': false, 'icon': 'person_pin', 'route': 'leads-summary' };
            //this.sideMenu[key].push(obj);
            this.sideMenu[key].splice(0, 0, obj);
            this.changeOption(obj);
          } else {
            this.sideMenu[key].forEach(function(item, index, object) {
              if (item['route'] == 'leads-summary') {
                object.splice(index, 1);
              }
            });
          }
        }
    };
  }

  routeTo = (option) => {
    this.router.navigateByUrl(option.route);
  }

  changeOption(option) {
    for (var i in this.sideMenu) {
      for (var j in this.sideMenu[i]) {
        if (option.label == this.sideMenu[i][j].label) {
          this.sideMenu[i][j].selected = true;
        } else {
          this.sideMenu[i][j].selected = false;
        }
      }
    }

    if (option.route == 'purchaseM') {
      this.router.navigate(['/' + option.route], { relativeTo: this.route });
    } else if (option.route == 'inventory-m') {
      this.router.navigate(['/' + option.route], { relativeTo: this.route });
    } else if (option.route == 'report') {
      this.router.navigate(['/' + option.route], { relativeTo: this.route });
    } else if (option.route == 'leads-list') {
      this.commonService.resetCurrentLead();
      this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>
      this.router.navigate(['/home/leads-list']));
    } else {
      this.router.navigate([option.route], { relativeTo: this.route });
    }

  }
}
