import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { PurchaseService } from '../service/purchase.service';
import { CommonService } from 'src/app/service/common.service';

export class EMAIL {
  public toEmail: string;
  public emailContent: string;
  public dealerName : string;
}

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.css']
})
export class EmailDialogComponent implements OnInit {

  customer: any;
  emailObject: EMAIL;
  user : any = {};
  constructor(public dialogRef: MatDialogRef<EmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ngxService: NgxUiLoaderService,
    private alertService: AlertService,
    private apiService: PurchaseService,
    private commonService : CommonService
    ) {
    this.emailObject = new EMAIL();
    this.user = this.commonService.getUserInfo();
    var customerInformation = this.commonService.getSelectedCustomer();
    if (customerInformation) {
      this.customer = customerInformation;
      this.emailObject.toEmail = this.customer.email;
    }
    this.emailObject.dealerName = this.user ? this.user['businessName'] : '';
  }

  ngOnInit() {

  }

  sendMail() {
    debugger;
    if (this.emailObject.toEmail && this.emailObject.emailContent) {
      this.ngxService.startBackground();
      this.emailObject.emailContent = this.emailObject.emailContent + " ";
      this.apiService.sendMail(this.emailObject).subscribe(res => {
       if(res && res.statusCode == 202) {
        this.alertService.success('Email send successfully');
       }
       this.close();
      });
    } else {
      this.alertService.danger('Please enter required fields');
    }
  }

  close() {
    this.ngxService.stopBackground();
    this.dialogRef.close();
  }

}
