import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class AngularFireDatabaseCarDealer extends AngularFireDatabase { }

@Injectable()
export class AngularFireAuthCarDealer extends AngularFireAuth { }

export function AngularFireDatabaseCarDealerFactory(platformId: Object, zone: NgZone) {
  return new AngularFireDatabaseCarDealer(environment.cardealer_db, 'CARDEALER', undefined, platformId, zone)
}
export function AngularFireAuthCarDealerFactory(platformId: Object, zone: NgZone) {
  return new AngularFireAuthCarDealer(environment.cardealer_db, 'CARDEALER', platformId, zone)
}

