import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMAIL } from 'src/app/purchase/email-dialog/email-dialog.component';
import { PurchaseService } from 'src/app/purchase/service/purchase.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  
  customer: any;
  emailObject: EMAIL;
  user : any = {};
  constructor(public dialogRef: MatDialogRef<SendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ngxService: NgxUiLoaderService,
    private alertService: AlertService,
    private apiService: PurchaseService,
    private commonService : CommonService
    ) {
    this.emailObject = new EMAIL();
    this.user = this.commonService.getUserInfo();
    this.customer = data.customer;
    this.emailObject.toEmail = data.email;
    this.emailObject.dealerName = this.user ? this.user['businessName'] : '';
  }

  ngOnInit() {

  }

  sendMail() {
    if (this.emailObject.toEmail && this.emailObject.emailContent) {
      this.ngxService.startBackground();
      this.emailObject.emailContent = this.emailObject.emailContent + " ";
      this.apiService.sendMail(this.emailObject).subscribe(res => {
       if(res && res.statusCode == 202) {
        this.alertService.success('Email send successfully');
       }
       this.close();
      });
    } else {
      this.alertService.danger('Please enter required fields');
    }
  }

  close() {
    this.ngxService.stopBackground();
    this.dialogRef.close();
  }

}
