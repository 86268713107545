import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/service/utility.service';
import { FirebaseService } from 'src/app/service/firebase.service';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-kyc-setup',
  templateUrl: './kyc-setup.component.html',
  styleUrls: ['./kyc-setup.component.css']
})
export class KycSetupComponent implements OnInit {

  user: any;
  groupId = "";
  dealerId = "";
  nodePath = "";
  kycList: any = [];
  kycData: any = {
    name: '',
    description: '',
    isEdit: false,
  };

  constructor(
    private utilServ: UtilityService,
    private firebaseService: FirebaseService,
    private alertService: AlertService,
    private ngxService: NgxUiLoaderService,
    private dialog: MatDialog,
  ) {
    this.user = this.utilServ.getLoginUser();
    this.groupId = this.utilServ.getGroupId();
    this.dealerId = this.utilServ.getDealerId();
    this.nodePath = "dealerSetting/kyc/" + this.groupId + "/" + this.dealerId;
  }

  ngOnInit() {
    this.resetForm();
    this.getKycList();
  }

  resetForm() {
    this.kycData = {
      name: '',
      description: '',
      isEdit: false,
    };
  }

  getKycList() {
    this.ngxService.startBackground();
    if (this.dealerId) {
      this.firebaseService.getFirebaseListTypeValues(this.nodePath).subscribe((res: []) => {
        this.kycList = res;
        console.log(this.kycList);
        this.ngxService.stopBackground();
      });
    }  else {
      if (!this.dealerId) { // if logged-In user (dealer) not exist
        this.alertService.danger('Network connection not available. Please check and try again.');
      }
      this.ngxService.stopBackground();
    }
  }

  removeItem(item, id) {
    Swal.fire({
      title: 'Do you want to delete this document?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteRec(item, id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
    
      }
    })
  }

  deleteRec(item, id) {
    if (id) {
      const finalNodePath = this.nodePath + "/" + id;
      this.firebaseService.firebaseDeleteNode(finalNodePath);
      this.alertService.success('Record deleted successfully');
      this.ngxService.stopBackground();
    } else {
      this.ngxService.stopBackground();
      this.alertService.danger('Something went wrong.');
    }
  }

  editKyc(item: any, pushKeyVal: number) {
    this.kycData = item;
    this.kycData.isEdit = true;
    console.log(item);
  }

  saveDocument(KycForm: NgForm) {
    this.ngxService.startBackground();
    if (!KycForm.invalid && this.dealerId) {
      var timestamp = new Date().getTime();
      var finalObj = {
        name: KycForm.value.name,
        description: KycForm.value.description ? KycForm.value.description : '',
        invalid_flag: 'N',
        delete_flag: 'N',
        created_by: this.kycData.isEdit ? this.kycData.created_by : this.dealerId,
        lastUpdated_by: this.dealerId,
        creation_date: this.kycData.isEdit ? this.kycData.creation_date : timestamp,
        lastUpdated_date: timestamp,
      }
      if (this.kycData.isEdit && this.kycData.id) { // in edit case
        const finalNodePath = this.nodePath + "/" + this.kycData.id;
        this.firebaseService.firbaseUpdateData(finalNodePath, finalObj);
        this.kycData.isEdit = false;
        KycForm.resetForm();
        this.ngxService.stopBackground();
        this.alertService.success('Record update successfully');
      } else { // in case of new creation
        const finalNodePath = this.nodePath;
        this.firebaseService.firebaseUpdateNodeByPushAndUpdatePushKey(finalNodePath, finalObj, this, function (callbackRes) {
          var response = callbackRes.response;
          var pushKey = response.key;
          console.log(pushKey);
          callbackRes.objRef.ngxService.stopBackground();
          callbackRes.objRef.alertService.success('Record saved successfully');
          KycForm.resetForm();
        });
      }
    } else {
      if (KycForm.invalid) {
        this.alertService.danger('Please enter doc name');
      }
      else if (!this.dealerId) { // if dealer not exist
        this.alertService.danger('Network connection not available. Please check and try again.');
      }
      this.ngxService.stopBackground();
    }
  }
}
