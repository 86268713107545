import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  usedCarUrl  = environment.USEDCAR_API_URL;
  cardealerUrl = environment.CARDEALER_API_URL;
  constructor(private httpClient: HttpClient) { }

  getMonthlySales = (groupUId, dealerUId, fromDate, toDate) => {
    return this.httpClient.get(this.usedCarUrl + '/api/sales/getMonthlySales?groupUId='+groupUId+'&dealerUId='+dealerUId+'&fromDate='+fromDate+'&toDate='+toDate);
  }
}
