<div class="container-fluid" style="height: 65vh; overflow: auto; background-color: white; width: 45vw;">
    <h2 mat-dialog-title>
        <span>Send SMS</span>
        <span class="fx-spacer"></span>
        <button mat-icon-button style="float: right;" (click)="close()">
            <mat-icon mat-list-icon>close</mat-icon>
        </button>
    </h2>
    <div class="row">
        <div class="col-sm-12">
            <h2 style="display: inline-block;">{{customer.fullName}}</h2>
            <h3 style="display: inline-block; float: right; margin-right: 20px;">{{date | date}}</h3>
        </div>
    </div>
    <form (submit)="f.form.valid ? saveCustomer(): ''" #f="ngForm" novalidate appFocus>
        <div class="row">
            <div class="col-sm-12" style="margin-top: 10px; font-size: 14px; ">
                <div class="row" style="font-size: 14px; padding-bottom: 25px;">
                    <div class="col-sm-12" style="margin-top: 15px;">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Mobile</mat-label>
                            <input matInput [(ngModel)]="formAttr.mobile"
                                oninput="this.value = this.value.toUpperCase()" name="lastName">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12" style="margin-top: 20px; margin-top: 15px;">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Message</mat-label>
                            <textarea style="resize: none; width: 100%;" oninput="this.value = this.value.toUpperCase()"
                                rows="3" cols="25" matInput [(ngModel)]="formAttr.message" name="message"
                                #message="ngModel" [ngClass]="{'is-invalid': f.submitted && message.invalid }"
                                required></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-sm-12" align="center" style="margin-top: 20px;">
                <button type="submit" mat-raised-button color="primary"
                    style="margin: 10px; background: linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255)); border-color:rgb(30,144,255)"><i
                        class="" aria-hidden="true"></i>&nbsp;Save</button>
            </div>
        </div>
    </form>
</div>