<h2 style="margin-bottom: 10px;" mat-dialog-title>Send Mail</h2>
<header>
    <div class="row">
        <div class="col-sm-12" style="margin-bottom: 10px;">
            <span>
                <mat-icon style="vertical-align: middle;margin-right: 6px;font-size: 23px;">person</mat-icon>
                {{customer.firstName}} {{customer.lastName}}
            </span>
        </div>
    </div>
</header>
<div style="margin-top: 20px;">
    <form (submit)="f.form.valid && sendMail()" #f="ngForm" novalidate>
        <mat-dialog-content>
            <div style="height: 260px;">
                <div class="row">
                    <div class="col-sm-12">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Email</mat-label>
                            <input matInput [(ngModel)]="emailObject.toEmail" name="toEmail" required>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12" style="margin-top: 10px;">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Email Content</mat-label>
                            <textarea style="resize: none; width: 100%;" maxlength="115" rows="5" cols="25" matInput
                                [(ngModel)]="emailObject.emailContent" name="emailContent" required></textarea>
                        </mat-form-field>
                        <!-- <mat-hint style="float: right;">Maximum emailContent: {{mesagge.value? mesagge.value.length : 0}} /
                            115</mat-hint> -->
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div style="width: 100%;" align="right">
                <button mat-raised-button (click)="close()" color="warn" type="button">Cancel</button>
                <button type="submit" mat-raised-button
                    style="margin-left: 40px;padding-left: 25px;padding-right: 25px;background-color: green;color: white;">Send</button>
                <!-- <button type="submit" mat-button>Send</button>
                <button mat-button (click)="close()">Cancel</button> -->
            </div>
        </mat-dialog-actions>
    </form>
</div>