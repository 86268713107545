import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',

  })
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiServer = environment.USEDCAR_API_URL;
  constructor(private httpClient: HttpClient) { }

  updateMedicalDetails(path: string, customerObject: any) {
    let headers = new HttpHeaders();
    let formData = new FormData();
    for (let key in customerObject) {
      formData.append(key, customerObject[key]);
    }
    return this.httpClient.post(`${this.apiServer}/api/usedcar/` + path, formData);
  }
  updateFollowupDetails(path: string, customerObject: any) {
    let headers = new HttpHeaders();
    let formData = new FormData();
    for (let key in customerObject) {
      formData.append(key, customerObject[key]);
    }
    return this.httpClient.post(`${this.apiServer}/api/leads/` + path, formData);
  }

  getPlannerData(groupId: string, dealerId: string, type: string) {
    return this.httpClient.get(`${this.apiServer}api/leads/leads_planner?groupUId=` + groupId + "&dealerUId=" + dealerId + "&reqType=" + type);
  }

  sendSMS(number: string, message: string, code: string) {
    return this.httpClient.post(`${this.apiServer}/api/purchase/sendsms?userPhone=` + number + "&message=" + message + "&countryCode=" + code, {});
  }

}
