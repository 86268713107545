<ngx-alerts></ngx-alerts>
<!-- <app-header></app-header> -->
<div class="container-fluid" style="height: 100vh;padding: 0px">
    <mat-drawer-container class="example-container">
        <mat-drawer #drawer opened="true" class="example-sidenav left-menu-drawer" mode="side"
            [ngClass]="{'opened': !hide, 'closed':hide}">
            <div class="col-sm-12" style="color: #fff;height: 40px;padding-left: 0px;text-align: center">
                <div [ngClass]="{'moveTextOpen': !hide, 'moveTextCloseHeader':hide}"
                    style="line-height: 1;cursor: pointer; text-decoration: none;text-align: center;">
                    <div style="font-size: 25px; ">
                        <span (click)="hide = !hide;" style="vertical-align: middle;" matTooltip="Close Menu"
                            matTooltipClass="custom-tooltip">
                            <mat-icon>menu_open</mat-icon>
                        </span>
                        <span>Used Car</span>
                        <!-- <img src="../../../assets/images/bestCarsbestPriceLogo.png" style="height: 38px;width: 150px;"> -->
                    </div>
                    <div style="margin: 0px 2px 0px 13px;" *ngIf="!hide">
                        <small style="font-size: 11px;color: #fff;">Powered by SHIFTMOBILITY
                        </small>
                    </div>
                </div>
                <div [ngClass]="{'moveTextOpen': hide, 'moveTextCloseHeader':!hide}" (click)="hide = !hide;"
                    style="padding-left: 0px; padding-right: 0px;cursor: pointer;text-align: center"
                    matTooltip="Open Menu" matTooltipClass="custom-tooltip">
                    <img src="../../../assets/images/open_white.png" style="width: 33px; margin-left: -15px;">
                </div>
            </div>
            <div class="col-sm-12 div-contanier" style="padding-right: 10px;padding-left: 12px;">
                <!-- <div *ngFor="let option of sideMenu">
                    <div class="vertical-menu" [ngClass]="{'router-link-active-dashboard': option.selected}" (click)="routeTo(option)">
                        <a href="javascript:void(0);" (click)="routeTo(option);"
                            style="text-decoration: none; color: black">
                            <div class="row" style="font-size: 14px;"
                                [ngClass]="{'colorClass': option.selected, 'colorClassDefault': !option.selected}">
                                <span class="col-sm-4" [ngClass]="{'moveIconOpen': !hide, 'moveIconClose':hide}"
                                    style="padding-right: 2px;text-align: center">
                                    <mat-icon style="margin-left: 5px;" [matTooltip]="option.label"
                                        matTooltipClass="custom-tooltip">{{option.icon}}</mat-icon>
                                </span>
                                <span class="col-sm-8" [ngClass]="{'moveTextOpen': !hide, 'moveTextClose':hide}"
                                    style="padding-left: 2px; padding-right: 2px; white-space: nowrap; padding-top: 3px;font-size: 16px;padding-top: 0px;" (click)="changeOption(option)">{{option.label}}</span>
                            </div>
                        </a>
                    </div>
                </div> -->
                <div *ngFor="let option of sideMenu | keyvalue: returnZero">
                    <div class="separator">
                      <span [ngClass]="{'moveTextOpen': !hide, 'moveTextClose':hide}" style="color: #fff;">{{option.key}}</span>
                    </div>
                    <div class="vertical-menu" *ngFor="let subOption of option.value"
                      [ngClass]="{'router-link-active-dashboard': subOption.selected}">
                      <a href="javascript:void(0);" style="text-decoration: none; color: black"
                        (click)="changeOption(subOption);">
                        <div class="row" style="font-size: 14px;"
                          [ngClass]="{'colorClass': subOption.selected, 'colorClassDefault': !subOption.selected}">
                          <span class="col-sm-3" [ngClass]="{'moveIconOpen': !hide, 'moveIconClose':hide}"
                            style="padding-right: 2px">
                            <mat-icon style="margin-left: 5px;"
                              [matTooltip]="subOption.title"
                              matTooltipClass="custom-tooltip">{{subOption.icon}}</mat-icon>
                          </span>
                          <span class="col-sm-9" [ngClass]="{'moveTextOpen': !hide, 'moveTextClose':hide}"
                            style="padding-left: 2px; padding-right: 2px; white-space: nowrap; padding-top: 3px;font-size: 16px;padding-top: 0px;">{{subOption.label}}</span>
                        </div>
                      </a>
                    </div>
                  </div>
            </div>
        </mat-drawer>
        <mat-drawer #rightDrawer position="end" mode="over">
            <div style="width:auto;height:100vh;">
                <div style="margin-bottom:60px;">
                    <div style="height:80px;background-color: #F7F3F7; margin: 0px;border-bottom: 1px solid #ddd"
                        class="row">
                        <div class="col-sm-2" style="margin-top:10px;" align="right">
                            <button mat-fab style="font-size: 18px;box-shadow:none;background-color:coral;color:white">
                                {{user.firstName.charAt(0).toUpperCase()}}{{user.lastName.charAt(0).toUpperCase()}}
                            </button>
                        </div>
                        <div class="col-sm-6" style="margin-top:15px;text-align:left;width:245px;padding:1px">
                            <div style="font-size:16px;font-weight:bold; text-transform: uppercase;">{{user.firstName}}
                                {{user.lastName}}</div>
                            <div style="font-size:13px;font-weight:600">{{user.email}}</div>
                        </div>
                        <div class="col-sm-4" style="margin-top:17px;text-align:center;">

                            <button mat-flat-button style="background-color:#313c4a;color:white" (click)="logout()">LOGOUT</button>

                        </div>
                    </div>
                    <div class="row" style="margin:0px;margin-top:15px; margin-left: 15px">
                        <span style="font-size:14px;font-weight:bold">Basic Information</span>
                    </div>
                    <div class="container" style="margin-top:10px">
                        <form>
                            <div class="form-group">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-form-field fxFlex appearance="outline">
                                        <mat-label>First Name</mat-label>
                                        <input matInput matInput [(ngModel)]="user.firstName"
                                            [ngModelOptions]="{standalone: true}" style="text-transform: uppercase;"
                                            required>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-form-field fxFlex appearance="outline">
                                        <mat-label>Last Name</mat-label>
                                        <input matInput matInput [(ngModel)]="user.lastName"
                                            [ngModelOptions]="{standalone: true}" style="text-transform: uppercase;"
                                            required>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-form-field fxFlex appearance="outline">
                                        <mat-label>Email</mat-label>
                                        <input matInput disabled matInput [(ngModel)]="user.email"
                                            [ngModelOptions]="{standalone: true}" required>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content class="example-drawer-content"
            [ngClass]="{'opened-content-custom': !hide, 'closed-content-custom':hide}">
            <div class="example-sidenav-content">
                <app-header [rightDrawer]="rightDrawer"></app-header>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px"
                    style="max-height: 90vh; height: 100vh; overflow: auto;">
                    <div fxFlex="100">
                    <router-outlet></router-outlet>
                    </div>
                </div>
                <!-- <app-footer [rightDrawer]="rightDrawer"></app-footer> -->
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>