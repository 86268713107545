import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { UtilityService } from 'src/app/service/utility.service';

@Component({
  selector: 'app-dealer-setting',
  templateUrl: './dealer-setting.component.html',
  styleUrls: ['./dealer-setting.component.css']
})
export class DealerSettingComponent implements OnInit {
  user : any = {};
  isDealer: boolean = false;
  constructor(private commonService: CommonService, private utilService : UtilityService) { }

  ngOnInit(): void {
    this.user = this.commonService.getUserInfo();
    this.isDealer = this.utilService.isDealerLogin();
  }

}
