<ngx-alerts></ngx-alerts>
<div class="container-fluid " style="height: 80vh; overflow: auto;">
    <div class="row mb-10">
        <div class="col-sm-12 col-md-12" style="padding: 0px 10px 0px 10px;">
            <div class="card example-full-width ">
                <div class="card-header custheader">
                    <div class="row">
                        <div class="col-sm-7 text-right">
                            <h4 class="card-title" style="font-size: 22px;margin-right:30px;">
                                Invoice</h4>
                        </div>
                        <div class="col-sm-5 text-right" printSectionId="print-section" ngxPrint>
                            <mat-icon class="hidden-print"
                                style="vertical-align: middle;font-size: 25px;cursor: pointer;" (click)="print()">
                                local_printshop</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="margin: 10px">
                    <form>

                        <!-- Table 1 (Input) -->
                        <table style="margin-top:10px;">

                            <tr>
                                <td rowspan="3" class="customTD">
                                    <span style="font-size: 16px;"><b>{{user.businessName}}</b></span><br />
                                    <span style="font-size: 14px;"><b>{{user.address}}</b></span><br />
                                    <!-- <span style="font-size: 14px;"><b>ANJUR ROAD, NEAR VIJAY SHOW MILL,</b></span><br /> -->
                                    <!-- <span style="font-size: 14px;"><b>MUMBAI NASIK HIGHWAY</b></span><br /> -->
                                    <!-- <span style="font-size: 14px;"><b>TAL-BHIWANDI,</b></span><br /> -->
                                    <!-- <span style="font-size: 14px;">DIS-THANE 421302</span><br /> -->
                                    <span style="font-size: 14px;">GSTIN/UIN: {{user.gstin}}</span><br />
                                    <span style="font-size: 14px;">State Name: {{user.state}}, Zip : {{user.zip}}</span>

                                </td>
                                <td>
                                    <span style="font-size: 14px;">Invoice No.</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.invoiceNo" name="invoiceNo" />
                                </td>
                                <td>
                                    <span style="font-size: 14px;">Dated</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.date" name="date" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span style="font-size: 14px;">Delivery Note</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.deliveryNote"
                                        name="deliveryNote" />
                                </td>
                                <td>
                                    <span style="font-size: 14px;">Mode/Terms of Payment</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.modeOfPayment"
                                        name="modeOfPayment" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span style="font-size: 14px;">Supplier's Ref.</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.supplierRef"
                                        name="supplierRef" />
                                </td>
                                <td>
                                    <span style="font-size: 14px;">Other Reference(s)</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.otherRef" name="otherRef" />
                                </td>
                            </tr>

                            <tr>
                                <td rowspan="4" class="customTD">
                                    <span style="font-size: 14px;">Buyer</span><br />
                                    <span
                                        style="font-size: 16px;"><b>{{customerInfo.firstName + " " + customerInfo.lastName}}</b></span><br /><br />
                                    <!-- <span style="font-size: 14px;">State Name :
                                        {{customerInfo.state}}, &nbsp; Zip :
                                        {{customerInfo.pincode}}</span><br /> -->
                                </td>
                                <td>
                                    <span style="font-size: 14px;">Buyer's Order No.</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.buyerOrderNo"
                                        name="buyerOrderNo" />
                                </td>
                                <td>
                                    <span style="font-size: 14px;">Dated </span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.dated" name="dated" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span style="font-size: 14px;">Despatch Document No.</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.despatchDocNo"
                                        name="despatchDocNo" />
                                </td>
                                <td>
                                    <span style="font-size: 14px;">Delivery Note Date </span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.deliveryNoteDate"
                                        name="deliveryNoteDate" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <span style="font-size: 14px;">Despatched through</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.despatchedThrough"
                                        name="despatchedThrough" />
                                </td>
                                <td>
                                    <span style="font-size: 14px;">Destination</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.destination"
                                        name="destination" />
                                </td>
                            </tr>


                            <tr>
                                <td colspan="2">
                                    <span style="font-size: 14px;">Terms of Delivery</span>
                                    <input matInput type="text" [(ngModel)]="invoiceModel.termsOfDelivery"
                                        name="termsOfDelivery" />
                                </td>
                            </tr>
                        </table>


                        <!-- Table 2 (Items Display) -->
                        <table style="margin-top:10px;">
                            <tr>
                                <th>SI No.</th>
                                <th>Description of Goods</th>
                                <th>HSN/SAC</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Per</th>
                                <th>Disc. %</th>
                                <th>Amount</th>
                            </tr>

                            <!-- Make dynamic row of table -->
                            <!-- <tr *ngFor="let good of goodsList; let index = index"> -->
                            <tr>
                                <td>1</td>
                                <td>
                                    <span>{{customerInfo.vehicle.regNo}}</span><br />
                                    <span style="margin-left:0px;">ENG NO -
                                        {{customerInfo.vehicle.engineNumber}}</span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;">{{customerInfo.vehicle.salePrice | number}}</td>
                            </tr>

                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td style="text-align:right;">Total</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;">&#x20a8; {{customerInfo.vehicle.salePrice | number}}
                                    </td>
                                </tr>
                            </tfoot>

                        </table>

                        <div class="row" style="margin-top:10px;">
                            <div class="col-sm-6">

                                <div class="row">
                                    <div class="col-sm-12">
                                        <span style="font-size: 13px;margin-left:10px;">Amount Chargeable (in
                                            words)</span><br />
                                        <!-- <span style="font-size: 15px;margin-left:10px;">Indian Rupees
                                        </span> -->
                                        <span style="font-size: 15px;margin-left:10px;">Indian Rupees
                                            &nbsp;{{amountInWords}} &nbsp;Only</span>
                                    </div>
                                </div>

                                <div class="row" style="margin-top:40px;">
                                    <div class="col-sm-12">
                                        <span style="font-size: 15px;margin-left:10px;">Company's VAT Tin.</span><span
                                            style="font-size: 15px;margin-left:20px;">:</span><br />
                                        <span style="font-size: 15px;margin-left:10px;">Company's PAN</span><span
                                            style="font-size: 15px;margin-left:44px;">:{{user.pan}}</span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-1"></div>
                            <div class="col-sm-5">

                                <div class="row">
                                    <div class="col-sm-12 text-right">
                                        <span style="font-size: 13px;">E. & O.E</span>
                                    </div>
                                </div>

                                <div class="row"
                                    style="margin:0px;padding:0px;margin-top: -15px;margin-left: 15px;margin-right: 15px;height: 100px;">
                                    <div (click)="openDialog('dealer')" class="col-sm-10"
                                        style="padding: 0px;padding-left: 5px;border-bottom: 1px solid black;text-align: center;">
                                        <img [src]="signatureImage" *ngIf="signatureImage" style="max-height: 100px;" />
                                    </div>
                                </div>

                                <div class="row"
                                    style="margin:0px;padding:0px;margin-top: 5px;margin-left: 15px;margin-right: 15px;">
                                    <div class="col-sm-12" style="text-align: left;margin-left:-15px">
                                        for {{user.businessName}} (Please Sign above)
                                    </div>
                                </div>

                                <div class="row"
                                    style="margin:0px;padding:0px;margin-top: 30px;margin-left: 15px;margin-right: 15px;">
                                    <div class="col-sm-7" style="display: flex;padding: 5px;">
                                        <span>Date: </span>
                                        <span style="width: 100%;border-bottom: 1px solid #000000;margin-left: 10px;"
                                            disabled="true">{{invoiceModel.signatureSigndate | date : 'MMM-dd-yyyy'}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- <div class="row" style="margin-top:30px;">
                            <div class="col-sm-12">
                                <span style="font-size: 15px;"><b>SHOWROOM:</b> Modi House, Opp. L.I.C. Building,
                                    Naupada, Off Eastern Express Highway, Thane - 400 602. Tel.: 6761 6161 / 2530 5757
                                    Fax : 6761</span><br />
                                <span style="font-size: 15px;margin-top:5px;"><b>WORKSHOP :</b> Navjeevan Comp., Opp.
                                    Oswal Park,
                                    Pokhran Road No. 2, Off Eastern Express Highway, Thane - 400 602. Ph. : 6761 1111 /
                                    2533
                                </span>
                            </div>
                        </div> -->


                        <!-- SAVE BUTTON -->
                        <div class="row" style="margin:0px;text-align: center;margin-top: 30px;">
                            <div class="col-sm-12">
                                <button (click)="sendFormData()" class="submit" type="submit" mat-raised-button
                                    color="primary"
                                    style="padding-left: 20px;padding-right: 20px; background: linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255)); border-color:#c20518;font-size: 17px;">Save
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Print Div -->
<div class="container-fluid" id="print-section" [hidden]=true>
    <div class="card-body" style="margin: 10px">
        <form>

            <!-- Table 1 (Input) -->
            <table style="margin-top:10px;">

                <tr>
                    <td rowspan="3" class="customTD">
                        <span style="font-size: 16px;"><b>{{user.businessName}}</b></span><br />
                        <span style="font-size: 14px;"><b>{{user.address}}</b></span><br />
                        <!-- <span style="font-size: 14px;"><b>ANJUR ROAD, NEAR VIJAY SHOW MILL,</b></span><br /> -->
                        <!-- <span style="font-size: 14px;"><b>MUMBAI NASIK HIGHWAY</b></span><br /> -->
                        <!-- <span style="font-size: 14px;"><b>TAL-BHIWANDI,</b></span><br /> -->
                        <!-- <span style="font-size: 14px;">DIS-THANE 421302</span><br /> -->
                        <span style="font-size: 14px;">GSTIN/UIN: {{user.gstin}}</span><br />
                        <span style="font-size: 14px;">State Name: {{user.state}}, Zip : {{user.zip}}</span>

                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Invoice No.</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.invoiceNo}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Dated</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.date}}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Delivery Note</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.deliveryNote}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Mode/Terms of Payment</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.modeOfPayment}}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Supplier's Ref.</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.supplierRef}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Other Reference(s)</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.otherRef}}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td rowspan="4" class="customTD">
                        <span style="font-size: 14px;">Buyer</span><br />
                        <span
                            style="font-size: 16px;"><b>{{customerInfo.firstName + " " + customerInfo.lastName}}</b></span><br /><br />
                        <!-- <span style="font-size: 14px;">State Name :
                            {{customerInfo.state}}, &nbsp; Zip :
                            {{customerInfo.pincode}}</span><br /> -->
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Buyer's Order No.</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.buyerOrderNo}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Dated</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.dated}}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Despatch Document No.</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.despatchDocNo}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Delivery Note Date </div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.deliveryNoteDate}}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Despatched through</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.despatchedThrough}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="row">
                            <div class="col-sm-4 no-padding">Destination</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.destination}}
                            </div>
                        </div>
                    </td>
                </tr>


                <tr>
                    <td colspan="2">
                        <div class="row">
                            <div class="col-sm-4 no-padding">Terms of Delivery</div>
                            <div class="col-sm-8 no-padding" style="min-width: 50px;">
                                {{invoiceModel.termsOfDelivery}}
                            </div>
                        </div>
                    </td>
                </tr>
            </table>


            <!-- Table 2 (Items Display) -->
            <table style="margin-top:10px;">
                <tr>
                    <th>SI No.</th>
                    <th>Description of Goods</th>
                    <th>HSN/SAC</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Per</th>
                    <th>Disc. %</th>
                    <th>Amount</th>
                </tr>

                <!-- Make dynamic row of table -->
                <!-- <tr *ngFor="let good of goodsList; let index = index"> -->
                <tr>
                    <td>1</td>
                    <td>
                        <span>{{customerInfo.vehicle.regNo}}</span><br />
                        <span style="margin-left:10px;">ENG NO -
                            {{customerInfo.vehicle.engineNumber}}</span>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{customerInfo.vehicle.salePrice | number}}</td>
                </tr>

                <tfoot>
                    <tr>
                        <td></td>
                        <td style="text-align:right;">Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>&#x20a8; {{customerInfo.vehicle.salePrice | number}}</td>
                    </tr>
                </tfoot>

            </table>

            <div class="row" style="margin-top:10px;">
                <div class="col-sm-6">

                    <div class="row">
                        <div class="col-sm-12">
                            <span style="font-size: 13px;margin-left:10px;">Amount Chargeable (in
                                words)</span><br />
                            <!-- <span style="font-size: 15px;margin-left:10px;">Indian Rupees
                            </span> -->
                            <span style="font-size: 15px;margin-left:10px;">Indian Rupees
                                &nbsp;{{amountInWords}} &nbsp;Only</span>
                        </div>
                    </div>

                    <div class="row" style="margin-top:40px;">
                        <div class="col-sm-12">
                            <span style="font-size: 15px;margin-left:10px;">Company's VAT Tin.</span><span
                                style="font-size: 15px;margin-left:20px;">:</span><br />
                            <span style="font-size: 15px;margin-left:10px;">Company's PAN</span><span
                                style="font-size: 15px;margin-left:44px;">:{{user.pan}}</span>
                        </div>
                    </div>

                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-5">

                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <span style="font-size: 13px;">E. & O.E</span>
                        </div>
                    </div>

                    <div class="row" style="margin-top:100px;">
                        <div (click)="openDialog('dealer')" class="col-sm-10"
                            style="padding: 0px;padding-left: 5px;border-bottom: 1px solid black;text-align: center;">
                            <img [src]="signatureImage" *ngIf="signatureImage" style="max-height: 100px;" />
                        </div>
                    </div>

                    <div class="row"
                        style="margin:0px;padding:0px;margin-top: 5px;margin-left: 15px;margin-right: 15px;">
                        <div class="col-sm-12" style="text-align: left;margin-left:-15px">
                            for {{user.businessName}}
                        </div>
                    </div>

                    <div class="row"
                        style="margin:0px;padding:0px;margin-top: 30px;margin-left: 15px;margin-right: 15px;">
                        <div class="col-sm-7" style="display: flex;padding: 5px;">
                            <span>Date: </span>
                            <span style="width: 100%;border-bottom: 1px solid #000000;margin-left: 10px;"
                                disabled="true">{{invoiceModel.signatureSigndate | date : 'MMM-dd-yyyy'}}</span>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>
