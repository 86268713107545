<!-- <div id="main">
    <div class="row">
        <div class="col-sm-12 custom-container">
            <mat-card style="padding: 0px 0px 0px 0px;">
                <mat-card-header class="bg-primary-bg" style="color: #fff;text-align: center;">
                    <mat-card-title style="margin-bottom: 0px;">
                        <h2 style="font-family: Verdana, Geneva, sans-serif; margin: 0px;padding: 10px;">
                        <strong>Used Car</strong></h2>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form class="example-form" style="margin-top: 10px;" (submit)="onSubmit()">
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field class="full-width">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Email" name="email" [(ngModel)]="email" style="margin: 0px;padding-bottom: 0px;color: #000;">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field class="full-width">
                                    <mat-label>Password</mat-label>
                                    <input type="password" matInput placeholder="Password" name="password" [(ngModel)]="password" style="margin: 0px;padding-bottom: 0px;color: #000;">
                                </mat-form-field>
                            </div>
                            <div class="col-sm-12" style="text-align: center;margin-top: 28px;">
                                <button mat-button color="primary" (click)="onSubmit()" style="background-color: rgb(33, 152, 243);color: #fff;border-radius: 0px;width: 135px;"><i class='fa fa-sign-in-alt' style='font-size:24px'></i>&nbsp;&nbsp;SIGN IN</button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
                <mat-card-actions style="text-align: center;">
                    
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    
</div>
   -->

   <div id="main">
    <div style="height: 20vh;">&nbsp;</div>
    <section fxLayoutAlign="center center">
      <mat-card [class.mat-elevation-z8]="true" style="padding: 0px; z-index: 2; width: 300px ;">
        <div style="padding: 10px; background: linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255)) ;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(244,67,54,.4) ; color: #fff;">
          <h4 style="text-align: center; font-size: 24px; margin-top: 10px;    font-weight: 500;">Used Car Platform</h4>
        </div>
      </mat-card>
    </section>
    <section fxLayoutAlign="center center" style="margin-top: -40px;">
      <mat-card style="padding-top: 70px; width: 350px; height: 40%; overflow: auto; border-radius: 10px;">
        <mat-card-content>
            <form (submit)="onSubmit()" >
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email" [(ngModel)]="email" name="email">
                      <mat-icon matSuffix>email</mat-icon>
                    </mat-form-field>
                  </div>
                  <div >
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>Password</mat-label>
                      <input matInput [type]="!hide ? 'password' : 'text'" [(ngModel)]="password" name="password">
                      <button type="submit" [hidden]="true" mat-icon-button matSuffix  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      </button>
                       <button type="button" mat-icon-button matSuffix (click)="submitByEnter($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{!hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <div style="position: absolute; top: 45%; left: 45%;">
                    <mat-spinner [diameter]="30" [hidden]="!loading"></mat-spinner>
                  </div>
                  <button style="width: 100%;" mat-raised-button color="primary" type="button" [disabled]="!email || !password" (click)="onSubmit()">
                        Login
                  </button>
            </form>
            <div style="height: 20px;"></div>
        </mat-card-content>
      </mat-card>
      
    </section>
  </div>