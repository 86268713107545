export class PurchaseBean {
    public customerInformation : any = {};
    public vehicleInformation : any = {};
    public documentInformation : any = {};
    
    setcustomerInformation(val: any) {
        this.customerInformation = val;
    }

    getcustomerInformation() {
        return this.customerInformation;
    }

    setvehicleInformation(val: any) {
        this.vehicleInformation = val;
    }

    getvehicleInformation() {
        return this.vehicleInformation;
    }

    setdocumentInformation(val: any){
        this.documentInformation = val;
    }

    getdocumentInformation(){
        return this.documentInformation;
    }
}