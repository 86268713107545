import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { LeadsService } from '../leads.service';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/service/utility.service';
import { FirebaseService } from 'src/app/service/firebase.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-leads-booking',
  templateUrl: './leads-booking.component.html',
  styleUrls: ['./leads-booking.component.css']
})
export class LeadsBookingComponent implements OnInit {


  currentLead: any = {};
  vehicleInfo: any = {};
  leads: any = {booking : {}}
  booking : any = {};
  profileForm: FormGroup;
  currentUser: any = {};
  disableBtn : boolean = false;
  cardType: any = ['Credit', 'Debit'];
  cardName: any = ['VISA', 'Master', 'Diners', 'Amex'];
  cuurentDate = new Date();

  purchaseVehicleNodePath : string;
  purchaseVehicle : any = {};
  assignToAnotherFlag : boolean = false;

  proformaInvoiceData : any;

  constructor(private commonService: CommonService, private leadsService: LeadsService,
    private utilService: UtilityService, private fbService : FirebaseService,
     private alertService: AlertService
    , private ngxService: NgxUiLoaderService, private fireService: FirebaseService, private router: Router) { }

  ngOnInit(): void {
    this.currentUser = this.commonService.getUserInfo();
    let currentLead = this.commonService.getCurrentLead();
    this.booking.bookingDate = this.cuurentDate;
    this.booking.purchaseMode = "Cash";
    this.booking.financeMode = "Self";
    this.purchaseVehicle = {};
    this.purchaseVehicleNodePath = 'purchase' + '/' + 'inventory' + '/' + this.utilService.getGroupId() + '/' + this.utilService.getDealerId();
    if(currentLead) {
      this.leadsService.getLeads(currentLead.groupUId, currentLead.dealerUId, currentLead.pushKey).subscribe((res: any) =>{
        if(res.status) {
          this.leads = Object.assign(this.leads, res.content);
          this.commonService.setCurrentLead(this.leads);
          this.vehicleInfo = this.leads.vehicle ? this.leads.vehicle : {};
          if(this.vehicleInfo && this.vehicleInfo.primaryDescriptionId) {
            this.purchaseVehicleNodePath  = this.purchaseVehicleNodePath + "/" +  this.vehicleInfo.primaryDescriptionId;
          }
          this.booking = this.leads.booking ? this.leads.booking : {};
          this.booking.bookingDate = this.booking.bookingDate ? this.booking.bookingDate : this.cuurentDate;
          this.booking.purchaseMode = this.booking.purchaseMode ? this.booking.purchaseMode : "Cash";
          this.booking.financeMode = this.booking.financeMode ? this.booking.financeMode : "Self";
          this.profileForm.get('pushKey').setValue(this.leads.pushKey);
          this.getPurchaseVehicle();
          this.getInvoiceData();
        }
      });
    } else {
      this.alertService.danger("Please select lead to continue");
    }
    this.profileForm = new FormGroup({
      amountReceived : new FormControl(this.booking.amountReceived, [
        Validators.required,
      ]),
      bookingDate : new FormControl(this.booking.bookingDate, [
        Validators.required,
      ]),
      pushKey: new FormControl(this.leads.pushKey, [
        Validators.required,
      ]),
    });

  }

  getPurchaseVehicle() {
    if(this.vehicleInfo && this.vehicleInfo.primaryDescriptionId) {
      this.fbService.get(this.purchaseVehicleNodePath).then(promise => {
        this.purchaseVehicle = promise.val() || {};
        console.log(promise.val(), "abcd")
        if(this.purchaseVehicle && this.purchaseVehicle.sales && this.purchaseVehicle.sales.customerId
          && this.leads.pushKey != this.purchaseVehicle.sales.customerId) {
              this.assignToAnotherFlag = true;
        }
      })
    }
  }

  getInvoiceData = () => {
    let nodePath = 'invoice/proforma/'+this.commonService.getGroupUId()+'/'+this.commonService.getDealerUId()+'/'+this.leads.pushKey;
    this.fireService.getFirebaseObjectTypeValues(nodePath).subscribe(res => {
      if(res) {
        this.proformaInvoiceData = res;
      }
    });
  }

  validateBooking = () => {
    let flag = true;
    if(this.leads.booking.billed || this.leads.booking.delivered) {
      if(!this.proformaInvoiceData || !this.proformaInvoiceData.isFullAmountPaid) {
        flag = false;
        this.alertService.warning('Please check full amount paid in performa invoice and save to continue.');
      }
    }
    return flag;
  }
  validateBookingMode =() =>{
      if(this.leads.booking.purchaseMode && this.leads.booking.purchaseMode == 'Cheque'){
          if(!this.leads.booking.chequeBankName || this.leads.booking.chequeBankName == '' || this.leads.booking.chequeBankName == null){
            this.alertService.warning('Please fill cheque bank name and save to continue.');
            return false;
          }else if(!this.leads.booking.chequeNumber || this.leads.booking.chequeNumber == '' || this.leads.booking.chequeNumber == null){
            this.alertService.warning('Please fill cheque# and save to continue.');
            return false;
          }else if(!this.leads.booking.chequeDate || this.leads.booking.chequeDate == '' || this.leads.booking.chequeDate == null){
            this.alertService.warning('Please fill cheque date and save to continue.');
            return false;
          }
      }else if(this.leads.booking.purchaseMode && this.leads.booking.purchaseMode == 'Online Transfer'){
        if(!this.leads.booking.onlineBankName || this.leads.booking.onlineBankName == '' || this.leads.booking.onlineBankName == null){
          this.alertService.warning('Please fill bank name and save to continue.');
          return false;
        }else if(!this.leads.booking.transactionNumber || this.leads.booking.transactionNumber == '' || this.leads.booking.transactionNumber == null){
          this.alertService.warning('Please fill transaction# and save to continue.');
          return false;
        }
      }else if(this.leads.booking.purchaseMode && this.leads.booking.purchaseMode == 'Other'){
        if(!this.leads.booking.other || this.leads.booking.other == '' || this.leads.booking.other == null){
          this.alertService.warning('Please fill other mode and save to continue.');
          return false;
        }
      }else if(this.leads.booking.purchaseMode && this.leads.booking.purchaseMode == 'Card'){
        if(!this.leads.booking.cardType || this.leads.booking.cardType == '' || this.leads.booking.cardType == null){
          this.alertService.warning('Please select card type and save to continue.');
          return false;
        }else if(!this.leads.booking.cardName || this.leads.booking.cardName == '' || this.leads.booking.cardName == null){
          this.alertService.warning('Please select card name and save to continue.');
          return false;
        }else if(!this.leads.booking.cardTransactionNumber || this.leads.booking.cardTransactionNumber == '' || this.leads.booking.cardTransactionNumber == null){
          this.alertService.warning('Please fill card transaction#  and save to continue.');
          return false;
        }
      }
      return true;
  }

  onSubmit = () => {
    this.leads.booking = this.booking;
    if(this.validateBookingMode()){
    if(this.validateBooking()) {
      let timestamp = new Date().getTime();
      this.leads.lastupdatedBy = this.currentUser.uid;
      this.leads.lastupdationDate = timestamp;
      this.leads.booking.lastupdationDate = timestamp;
      this.leads.subStatus = this.leads.booking.subStatus;
      this.leads.status = 'booking';
      if(this.vehicleInfo && this.vehicleInfo.primaryDescriptionId) {
        this.leads.vehicleRefKey = this.vehicleInfo.primaryDescriptionId;
      }
      if (this.leads.booking.billed || this.leads.booking.delivered) {
          this.leads.status = 'billing';
          this.leads.billingStatus =  this.leads.booking.delivered ? "Delivered" : "Not Delivered";
          this.leads.subStatus = null;
      }  else {
          this.leads.billingStatus = null;
      }
      //for Cancel booking
      if(this.leads.booking.subStatus && this.leads.booking.subStatus == "Cancelled") {
          this.leads.status = 'cancelled';
      }
      //END
      this.leads.updatedBy[this.leads.lastupdatedBy] = {
          'name': (this.currentUser.firstName ? this.currentUser.firstName + ' ' : '') + '' + (this.currentUser.middleName ? this.currentUser.middleName + ' ' : '') + "" + (this.currentUser.lastName ? this.currentUser.lastName : ''),
          'timestamp': timestamp,
          'uid': this.leads.lastupdatedBy
      };
      this.leads.comments = this.leads.comments ? this.leads.comments : {};
      let commentsId = this.leads.booking.commentsId ? this.leads.booking.commentsId : timestamp;
      if (this.leads.booking.remark) {
          this.leads.comments[commentsId] = {
              'comments': this.leads.booking.remark,
              'givenBy': this.leads.lastupdatedBy,
              'timestamp': commentsId,
              'status': 'booking',
              'givenByName': (this.currentUser.firstName ? this.currentUser.firstName + ' ' : '') + '' + (this.currentUser.middleName ? this.currentUser.middleName + ' ' : '') + "" + (this.currentUser.lastName ? this.currentUser.lastName : '')
          };
          this.leads.booking.commentsId = commentsId;
      }
        //for recent activity
        this.leads.recentActivity = this.leads.recentActivity ? this.leads.recentActivity : {};
        this.leads.recentActivity[timestamp] = {'comments': "Booking process done", 'givenBy': this.leads.lastupdatedBy, 'timestamp': timestamp, 'status':'enquiry', 'givenByName': (this.currentUser.firstName ? this.currentUser.firstName+' ' : '')+''+(this.currentUser.middleName? this.currentUser.middleName+' ': '')+""+(this.currentUser.lastName ? this.currentUser.lastName : '')};


      if (this.leads.booking.bookingDate && !this.leads.booking.booking_timestamp) {
          this.leads.booking.booking_timestamp = new Date(this.leads.booking.bookingDate).getTime();
      }
      //For Billing Date
      if (this.leads.booking.billed) {
          this.leads.booking.billingDate = this.leads.booking.billingDate ? this.leads.booking.billingDate : timestamp;
      } else {
        this.leads.booking.billingDate = null;
      }
      //END
      //For Delivered Date
      if (this.leads.booking.delivered) {
        this.leads.booking.deliveredDate = this.leads.booking.deliveredDate ? this.leads.booking.deliveredDate : timestamp;
      } else {
        this.leads.booking.deliveredDate = null;
      }
      //END
      if(this.booking.committedDeliveryDate) {
        this.leads.vehicleDeliveryTimestamp = new Date(this.booking.committedDeliveryDate).getTime();
      }
      if(!this.leads.booking.amountReceived) {
          // balancePaymentCount();
      }
     this.disableBtn = true;
        this.ngxService.startBackground();
        this.leadsService.saveBooking(this.leads).subscribe((response: any) => {
            this.disableBtn = false;
             this.ngxService.stopBackground();
            if (response && response.status && response.content) {
                this.alertService.success('Record saved successfully');
                this.leads = response.content;
               this.commonService.setCurrentLead(this.leads);
               this.updateVehicleStatus();
               this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
               this.router.navigate(['/home/leads-summary']));
            } else {
                this.alertService.warning('Unable to save. Please check and try again.');
            }
        }, (error) => {
            console.log(error);
            this.alertService.danger('Network connection not available. Please check and try again.');
            this.disableBtn = false;
             this.ngxService.stopBackground();
        });
    }
   }
  }

  updateVehicleStatus = () => {
    if(this.vehicleInfo && this.vehicleInfo.primaryDescriptionId) {
        if(this.leads.subStatus == "Not Assigned" || this.leads.subStatus == "Cancelled") {
          let json = {'customerId' : null, 'vehicleStatus' : null}
          this.fbService.firbaseUpdateData(this.purchaseVehicleNodePath + "/sales", json);
        } else {
          let json = {'customerId' : this.leads.pushKey, 'vehicleStatus' : this.leads.billingStatus == "Delivered" ? this.leads.billingStatus : this.leads.status}
          this.fbService.firbaseUpdateData(this.purchaseVehicleNodePath + "/sales", json);
          if(this.leads.billingStatus == "Delivered") {
            this.fbService.firbaseSetData(this.purchaseVehicleNodePath + "/vehicleInformation/status", "Delivered");
          }
        }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.alertService.danger('Only Digits');
      return false;
    }
    return true;
  }

  calculateBalance = () => {
    this.booking.balancePayment = this.objToNumber(this.vehicleInfo.salePrice) - this.booking.amountReceived;
  }

  objToNumber = (val) => {
    if(val) {
      return Number(val);
    }
    return 0;
  }
}
