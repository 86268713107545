import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AlertModule } from 'ngx-alerts';
import { NgxUiLoaderModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';
import { CoreModule } from './core/core.module';

import { ESignatureComponent } from './purchase/e-signature/e-signature.component';
import { SignaturePadModule } from '@ng-plus/signature-pad';

import { DashboardModule } from './dashboard/dashboard.module';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ConfirmationDialogComponent } from './inventory-m/post-to-marketplace/confirmation-dialog/confirmation-dialog.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthCarDealer, AngularFireAuthCarDealerFactory, AngularFireDatabaseCarDealer, AngularFireDatabaseCarDealerFactory } from './firebase-service/angularfirestore-extension.service';
import { PSmsComponent } from './purchase/p-sms/p-sms.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DatePipe } from '@angular/common';
import { SendSmsComponent } from './common-utility/send-sms/send-sms.component';
import { SendEmailComponent } from './common-utility/send-email/send-email.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';

const ngxconfiguration: NgxUiLoaderConfig = {
  "bgsColor": "linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255))",
  "bgsOpacity": 0.5,
  "bgsPosition": "center-center",
  "bgsSize": 60,
  "bgsType": "rectangle-bounce",
  "blur": 5,
  "delay": 0,
  "fgsColor": "red",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "rectangle-bounce",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 500
};


@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent,
    SendSmsComponent,
    SendEmailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    DashboardModule,
    AngularFireStorageModule, // firestore
    AngularFireAuthModule, // auth

    AngularFireModule.initializeApp(environment.usedcar_dev),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireDatabaseModule,
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, position: 'right' }),
    NgxUiLoaderModule.forRoot(ngxconfiguration),
    SignaturePadModule,
    AngularMaterialModule,
    GooglePlaceModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXTfoyb18l2xDia9_YB6FRAxFx8kdKq-U',
      libraries: ["places", "geometry", "weather"]
    }),
    AgmDirectionModule

  ],
  providers: [
    { provide: AngularFireDatabaseCarDealer, deps: [PLATFORM_ID, NgZone], useFactory: AngularFireDatabaseCarDealerFactory },
    { provide: AngularFireAuthCarDealer, deps: [PLATFORM_ID, NgZone], useFactory: AngularFireAuthCarDealerFactory },
    DatePipe
  ],
  entryComponents: [ESignatureComponent, ConfirmationDialogComponent, PSmsComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
