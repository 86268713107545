import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-test-drive-directions',
  templateUrl: './test-drive-directions.component.html',
  styleUrls: ['./test-drive-directions.component.css']
})
export class TestDriveDirectionsComponent implements OnInit {

  public lat = 19.7515;
  public lng = 75.7139;

  public origin: any;
  public destination: any;
  zoom: number = 6;
  public sourceDestinationObject: any = {};
  public fromToObject: any = {};
  public directions: any = {};
  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('searchTo') searchToElementRef: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone,
    private ngxService: NgxUiLoaderService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        console.log(params)
        if (params) {
          let from = params.from ? JSON.parse(params.from) : null;
          let to = params.to ? JSON.parse(params.to) : null;
          if (from && to) {
            this.directions.origin = from.name;
            this.directions.destination = to.name;
            this.ngxService.startBackground();
            this.getDirection({ source: { lat: from.lat, lng: from.lng }, destination: { lat: to.lat, lng: to.lng } });
            this.getDistanceAndTime(from.name, to.name);
          }
        }
      });
  }

  getDirection(sourceDestinationObject) {
    this.origin = sourceDestinationObject.source;
    this.destination = sourceDestinationObject.destination;
  }


  ngAfterViewInit() {
    this.findAdress();
  }

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.fromToObject.from = place.formatted_address;
          this.sourceDestinationObject.source = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
        });
      });
      let autocompletes = new google.maps.places.Autocomplete(this.searchToElementRef.nativeElement);
      autocompletes.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.ngxService.startBackground();
          let place: google.maps.places.PlaceResult = autocompletes.getPlace();
          this.fromToObject.to = place.formatted_address;
          this.sourceDestinationObject.destination = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
          this.getDistanceAndTime(this.fromToObject.from, this.fromToObject.to);
          this.getDirection(this.sourceDestinationObject);
          this.zoom = 12;
        });
      });
    });
  }

  getDistanceAndTime(from, to): void {
    if (from && to) {
      let directionsService = new google.maps.DirectionsService();
      var directionsDisplay = new google.maps.DirectionsRenderer();
      let directionObservable = Observable.create((observer) => {
        let request: any = {
          origin: from,
          destination: to,
          travelMode: 'DRIVING',
          drivingOptions: {
            departureTime: new Date(),
            trafficModel: "bestguess"
          }
        }
        directionsService.route(request, function (response, status) {
          if (status === google.maps.DirectionsStatus.OK) {
            let kms = response.routes[0].legs[0].distance.text;
            if (kms) {
              let kmsArr = kms.split(' ');
              if (kmsArr[0]) {
                kms = (Number(kmsArr[0].replace(/,/g, ''))) * 2 + ' ' + kmsArr[1];
              }
            }
            let time = response.routes[0].legs[0].duration.text;
            if (time) {
              var timeArr = time.split(' ');
              if (timeArr[0]) {
                time = (Number(timeArr[0])) * 2 + ' ' + timeArr[1];
              }
            }
            observer.next({ 'kms': kms, 'time': time });

            directionsDisplay.setDirections(response);
            directionsDisplay.setPanel(document.getElementById('directionsList'));
            //$scope.directions.showList = true;

          } else {
            alert('Google route unsuccesfull!');
          }
        });
      });
      directionObservable.subscribe((text: any) => {
        //this.leads.testdrive.kms = text.kms;
        //this.leads.testdrive.time = text.time;
        this.ngxService.stopBackground();
      });
    }
  }

  reset = () => {
    this.directions = { origin: "", destination: "" };
  }

}
