<div class="container-fluid" style=" margin-bottom: 20px;">
    <div class="row" style="margin-top: 20px;">
        <div class="col-md-4">
            <div class="btn-group">
                <div style="font-size: 16px;" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    <i style="margin-top: 6px;" class="fa fa-angle-double-left"></i>
                </div>
                &nbsp;
                <div mwlCalendarToday [(viewDate)]="viewDate" style="text-align: center;">
                    <h3 style="font-size: 17px;     white-space: nowrap;">
                        {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                </div>
                &nbsp;
                <div style="font-size: 16px;" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    <i style="margin-top: 6px;" class="fa fa-angle-double-right"></i>
                </div>
            </div>
            <button class="btn btn-warn" mwlCalendarToday [(viewDate)]="viewDate"
                style="background-color: #007bff;font-size: 13px; margin-left: 30px; color: white;">
                <span class="material-icons" style="vertical-align: middle;"> refresh </span>&nbsp;Today</button>
        </div>
        <div class="col-md-3">
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline" style="margin-top: -8px; width: 12vw; float: right;">
                <mat-select [(ngModel)]="statusType">
                    <mat-option *ngFor="let item of statusTypeArr" [value]="item.type"
                        (click)="getLeadsByStatus(item.type, item.date)">
                        {{item.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-2">
            <mat-form-field appearance="outline" style="margin-top: -8px; width: 10vw;">
                <mat-select [(ngModel)]="dataType">
                    <mat-option *ngFor="let item of dataTypeArr" [value]="item.type" (click)="setView(item.type)">
                        {{item.label}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <br />

</div>
<div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)">
    </mwl-calendar-day-view>
</div>