import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FireEventService } from './fire-event.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private eventFire: FireEventService
  ) { }

  getGroupUId = () => {
    if (sessionStorage && sessionStorage.user) {
      var user = sessionStorage.user ? JSON.parse(sessionStorage.user) : null;
      return user.groupAdmin ? user.uid : (user.group ? user.group.uid : user.uid);
    }
    return null;
  }
  getDealerUId = () => {
    if (sessionStorage && sessionStorage.user) {
      var user = sessionStorage.user ? JSON.parse(sessionStorage.user) : null;
      if (user.dealerAdmin) {
        return user.uid;
      } else {
        return user.dealerUID ? user.dealerUID : null;
      }
    }
    return null;
  }
  getCurrentLead = () => {
    if (sessionStorage && sessionStorage.currentLead) {
      return sessionStorage.currentLead ? JSON.parse(sessionStorage.currentLead) : null;
    }
  }

  setCurrentLead = (leads: any) => {
    sessionStorage.removeItem("currentLead");
    if (leads) {
      sessionStorage.currentLead = JSON.stringify(leads);
    }
  }

  getUserInfo = () => {
    if (sessionStorage && sessionStorage.user) {
      return sessionStorage.user ? JSON.parse(sessionStorage.user) : null;
    }
  }

  setSelectedCustomer = (customer: any, selectedVehicle: any) => {
    sessionStorage.setItem(environment.CUSTOMER_INFO, JSON.stringify(customer));
    if (selectedVehicle) {
      sessionStorage.setItem(environment.DEFAULT_VEHICLE, JSON.stringify(selectedVehicle));
    }
    this.eventFire.setCustomer(customer);
  }

  getSelectedCustomer = () => {
    if (sessionStorage) {
      return sessionStorage[environment.CUSTOMER_INFO] ? JSON.parse(sessionStorage[environment.CUSTOMER_INFO]) : null;
    }
  }

  getSelectedVehicle = ()=> {
    if (sessionStorage) {
      return sessionStorage[environment.DEFAULT_VEHICLE] ? JSON.parse(sessionStorage[environment.DEFAULT_VEHICLE]) : null;
    }
  }

  setLeadsDraft = (lead) => {
    sessionStorage.removeItem("currentLead");
    if (lead) {
      sessionStorage.leadDraft = JSON.stringify(lead);
    }
  }

  getLeadsDraft = () => {
    if (sessionStorage && sessionStorage.leadDraft) {
      return sessionStorage.leadDraft ? JSON.parse(sessionStorage.leadDraft) : null;
    }
  }


  resetLeadDraft = () => {
    sessionStorage.removeItem('leadDraft');
  }

  resetCurrentLead = () => {
    sessionStorage.removeItem('currentLead');
  }
}
