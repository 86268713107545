import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { HomeComponent } from './home/home.component';
import { AlertModule } from 'ngx-alerts';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { CoreModule } from '../core/core.module';
import { LeadsFollowupComponent } from './leads/leads-followup/leads-followup.component';
import { LeadsEnquiryComponent } from './leads/leads-enquiry/leads-enquiry.component';
import { LeadsTestdriveComponent } from './leads/leads-testdrive/leads-testdrive.component';
import { VehicleListComponent } from './vehicle/vehicle-list/vehicle-list.component';
import { VehicleDetailsComponent } from './vehicle/vehicle-details/vehicle-details.component';
import { LeadsListComponent } from './leads/leads-list/leads-list.component';
import { DealerSettingComponent } from './dealer-setting/dealer-setting.component';
import { KycSetupComponent } from './dealer-setting/kyc-setup/kyc-setup.component';
import { VehicleDocSetupComponent } from './dealer-setting/vehicle-doc-setup/vehicle-doc-setup.component';
import { LeadsSummaryComponent } from './leads/leads-summary/leads-summary.component';
import { LeadsInfoComponent } from './leads/leads-summary/leads-info/leads-info.component';
import { VehicleInfoComponent } from './leads/leads-summary/vehicle-info/vehicle-info.component';
import { LeadsProcessComponent } from './leads/leads-summary/leads-process/leads-process.component';

import { OrderByPipe } from '../pipes/order-by.pipe';
import { LeadsBookingComponent } from './leads/leads-booking/leads-booking.component';
import { PaymentRecipetComponent } from './invoice/payment-recipet/payment-recipet.component';
import { CreateInvoiceComponent } from './invoice/create-invoice/create-invoice.component';
import { ProformaInvoiceComponent } from './invoice/proforma-invoice/proforma-invoice.component';
import { MonthlySalesComponent } from './reports/monthly-sales/monthly-sales.component';
import { LeadsAppointmentComponent } from './leads-appointment/leads-appointment.component';
import { CalendarModule } from 'angular-calendar';
import { BookAppointmentComponent } from './leads-appointment/book-appointment/book-appointment.component';
import { CreateSalesContractComponent } from '../purchase/form/create-sales-contract/create-sales-contract.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { TestDriveDirectionsComponent } from './leads/leads-testdrive/test-drive-directions/test-drive-directions.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';


const ngxconfiguration: NgxUiLoaderConfig = {
  "bgsColor": "linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255))",
  "bgsOpacity": 0.5,
  "bgsPosition": "center-center",
  "bgsSize": 60,
  "bgsType": "rectangle-bounce",
  "blur": 5,
  "delay": 0,
  "fgsColor": "red",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "rectangle-bounce",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 500
};

@NgModule({
  declarations: [HomeComponent,
    LeadsEnquiryComponent,
    LeadsFollowupComponent,
    LeadsTestdriveComponent,
    VehicleListComponent,
    VehicleDetailsComponent,
    LeadsListComponent,
    CreateInvoiceComponent,
    LeadsSummaryComponent,
    CreateSalesContractComponent,
    LeadsInfoComponent,
    VehicleInfoComponent,
    LeadsProcessComponent,
    DealerSettingComponent,
    KycSetupComponent,
    VehicleDocSetupComponent,
    LeadsBookingComponent,
    ProformaInvoiceComponent,
    OrderByPipe,
    PaymentRecipetComponent,
    MonthlySalesComponent,
    PaymentRecipetComponent,
    LeadsAppointmentComponent,
    BookAppointmentComponent,
    TestDriveDirectionsComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    DashboardRoutingModule,
    CoreModule,
    AlertModule.forRoot({ maxMessages: 5, timeout: 5000, position: 'right' }),
    NgxUiLoaderModule.forRoot(ngxconfiguration),
    CalendarModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXTfoyb18l2xDia9_YB6FRAxFx8kdKq-U',
      libraries: ["places", "geometry", "weather"]
    }),
    AgmDirectionModule
  ],
  entryComponents: [BookAppointmentComponent]
})
export class DashboardModule { }
