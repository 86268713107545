import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LeadsEnquiryComponent } from './leads/leads-enquiry/leads-enquiry.component';
import { LeadsFollowupComponent } from './leads/leads-followup/leads-followup.component';
import { LeadsTestdriveComponent } from './leads/leads-testdrive/leads-testdrive.component';
import { AuthGuard } from '../auth.guard';
import { VehicleListComponent } from './vehicle/vehicle-list/vehicle-list.component';
import { VehicleDetailsComponent } from './vehicle/vehicle-details/vehicle-details.component';
import { LeadsListComponent } from './leads/leads-list/leads-list.component';
import { DealerSettingComponent } from './dealer-setting/dealer-setting.component';
import { KycSetupComponent } from './dealer-setting/kyc-setup/kyc-setup.component';
import { VehicleDocSetupComponent } from './dealer-setting/vehicle-doc-setup/vehicle-doc-setup.component';
import { LeadsSummaryComponent } from './leads/leads-summary/leads-summary.component';

import { CreateInvoiceComponent } from './invoice/create-invoice/create-invoice.component';
import { ProformaInvoiceComponent } from './invoice/proforma-invoice/proforma-invoice.component';
import { PaymentRecipetComponent } from './invoice/payment-recipet/payment-recipet.component';
import { MonthlySalesComponent } from './reports/monthly-sales/monthly-sales.component';



import { LeadsBookingComponent } from './leads/leads-booking/leads-booking.component';
import { LeadsAppointmentComponent } from './leads-appointment/leads-appointment.component';
import { CreateSalesContractComponent } from '../purchase/form/create-sales-contract/create-sales-contract.component';
import { TestDriveDirectionsComponent } from './leads/leads-testdrive/test-drive-directions/test-drive-directions.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: 'leads-list', component: LeadsListComponent, canActivate: [AuthGuard] },
      { path: 'leads-enquiry', component: LeadsEnquiryComponent, canActivate: [AuthGuard] },
      { path: 'leads-followup', component: LeadsFollowupComponent, canActivate: [AuthGuard] },
      { path: 'leads-testdrive', component: LeadsTestdriveComponent, canActivate: [AuthGuard] },
      { path: 'allotment-vehicle-list', component: VehicleListComponent, canActivate: [AuthGuard] },
      { path: 'allotment-vehicle-details', component: VehicleDetailsComponent, canActivate: [AuthGuard] },
      { path: 'create-invoice', component: CreateInvoiceComponent, canActivate: [AuthGuard] },
      { path: 'create-sales-contract', component: CreateSalesContractComponent, canActivate: [AuthGuard] },
      { path: 'dealer-setting', component: DealerSettingComponent, canActivate: [AuthGuard] },
      { path: 'kyc-setup', component: KycSetupComponent, canActivate: [AuthGuard] },
      { path: 'vehicle-doc-setup', component: VehicleDocSetupComponent, canActivate: [AuthGuard] },
      { path: 'leads-summary', component: LeadsSummaryComponent, canActivate: [AuthGuard] },

      { path: 'leads-booking', component: LeadsBookingComponent, canActivate: [AuthGuard] },
      { path: 'proforma-invoice', component: ProformaInvoiceComponent, canActivate: [AuthGuard] },
      { path: 'payment-receipt', component: PaymentRecipetComponent, canActivate: [AuthGuard] },
      { path: 'monthly-sales', component: MonthlySalesComponent, canActivate: [AuthGuard] },

      { path: 'payment-receipt', component: PaymentRecipetComponent, canActivate: [AuthGuard] },
      { path: 'leads-appointment', component: LeadsAppointmentComponent, canActivate: [AuthGuard] },
      { path: 'test-drive-directions', component: TestDriveDirectionsComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
