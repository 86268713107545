import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FireEventService {
  protected _eventSubject = new Subject();
  public events = this._eventSubject.asObservable();

  changeRouterLink(linkName: string) {
    this._eventSubject.next(linkName);
  }

  setCustomer(customer: any) {
    this._eventSubject.next({ customer: customer });
  }

  setOptionLink(link: any) {
    this._eventSubject.next({ link: link });
  }

  getOptionLink(): Observable<any> {
    return this._eventSubject.asObservable();
  }
}
