import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SMS } from 'src/app/purchase/p-sms/p-sms.component';
import { PurchaseService } from 'src/app/purchase/service/purchase.service';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.css']
})
export class SendSmsComponent implements OnInit {

  customer: any;
  smsObject: SMS;
  constructor(public dialogRef: MatDialogRef<SendSmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ngxService: NgxUiLoaderService,
    private alertService: AlertService,
    private apiService: PurchaseService,) {
    this.smsObject = new SMS();
    this.customer = data.customer;
    this.smsObject.phone = data.phone;
  }

  ngOnInit() {

  }

  sendSms() {
    if (this.smsObject.message) {
      this.ngxService.startBackground();
      this.smsObject.message = this.smsObject.message + " ";
      this.apiService.sendSMS(this.smsObject).subscribe(res => {
        let flag = res.success;
        if (flag) {
          this.alertService.success('Message sent');
          this.ngxService.stopBackground();
          this.close();
        } else {
          this.alertService.danger('Message not sent');
        }
      });
    } else {
      this.alertService.danger('Message not sent');
    }
  }

  close() {
    this.ngxService.stopBackground();
    this.dialogRef.close();
  }
}
