<mat-toolbar [ngStyle]="{'height':'50px'}" class="header-css">
    <div fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
            <div fxFlex="50">Enquiry</div>
            <div fxFlex="50" fxLayoutAlign="end start">
                <!-- <button mat-raised-button color="primary" (click) ="changeTo()">View</button> -->
            </div>
        </div>
    </div>
</mat-toolbar>

<div style="padding-left: 16px;">
        <div fxFlex="100" style="padding-right: 18px;">
            <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px !important;">
             <div fxFlex="100">
              <mat-card [class.mat-elevation-z6]="true"  fxFlex>
                    <mat-card-header>
                        <mat-card-subtitle style="font-size: 18px;">Personal Information</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <div fxFlex="100">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-form-field fxFlex="15" appearance="outline">
                                        <mat-select [(ngModel)]="leads.honorific" [ngModelOptions]="{standalone: true}">
                                          <mat-option value="Mr">Mr.</mat-option>
                                          <mat-option value="Mrs" >Mrs.</mat-option>
                                          <mat-option value="Dr" >Dr.</mat-option>
                                          <mat-option value="M/s" >M/s.</mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    <mat-form-field fxFlex appearance="outline">
                                        <mat-label>First Name</mat-label>
                                        <input matInput formControlName="firstName" matInput [(ngModel)]="leads.firstName" cdkFocusInitial required style="text-transform: uppercase;">
                                      </mat-form-field>
                                      <mat-form-field fxFlex appearance="outline">
                                        <mat-label>Last Name</mat-label>
                                        <input matInput [(ngModel)]="leads.lastName" formControlName="lastName" style="text-transform: uppercase;"  required>
                                      </mat-form-field>
                                      <mat-form-field fxFlex appearance="outline">
                                        <mat-label>Middle Name</mat-label>
                                        <input matInput matInput [(ngModel)]="leads.middleName" [ngModelOptions]="{standalone: true}" style="text-transform: uppercase;">
                                      </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-form-field fxFlex="32" appearance="outline">
                                        <mat-label>Mobile</mat-label>
                                        <input maxlength="10" matInput formControlName="mobile" matInput [(ngModel)]="leads.mobile" [disabled]="leads.pushKey" required>
                                      </mat-form-field>
                                      <div fxFlex="5">
                                        <h4 class="enquiry-icons"><i class="fa fa-comments" aria-hidden="true" title="Send SMS" (click)="sendMessage()"></i></h4>
                                      </div>
                                      <!-- <div fxFlex="5">
                                        <h4 class="enquiry-icons"><i class="fa fa-whatsapp" style="color: green;" aria-hidden="true"></i></h4>
                                      </div> -->
                                      <mat-form-field fxFlex appearance="outline">
                                        <mat-label>Email</mat-label>
                                        <input matInput  matInput [(ngModel)]="leads.email" [ngModelOptions]="{standalone: true}">
                                      </mat-form-field>
                                      <div fxFlex="5">
                                        <h4 class="enquiry-icons"><i class="fa fa-envelope" aria-hidden="true" title="Send Email" (click)="sendMail()"></i></h4>
                                      </div>
                                </div>
                        </div>
                    </mat-card-content>
                </mat-card>
              </div>
            </div>
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel class="parametersPanel"  style="margin-top: 10px;">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        Current Address and Vehicle Info
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px !important;">
                    <div fxFlex="50">
                        <mat-card [class.mat-elevation-z6]="true" >
                            <mat-card-header>
                                <mat-card-subtitle style="font-size: 18px;">Address Information</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                    <div fxFlex="100">
                                            <div>
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Address</mat-label>
                                                    <input matInput [(ngModel)]="leads.address.address" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>
                                            </div>
                                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Landmark</mat-label>
                                                    <input matInput [(ngModel)]="leads.address.landmark" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>City</mat-label>
                                                    <input matInput [(ngModel)]="leads.address.city" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>
                                            </div>
                                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>State</mat-label>
                                                    <input matInput [(ngModel)]="leads.address.state" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>

                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Zip</mat-label>
                                                    <input matInput [(ngModel)]="leads.address.zip" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>
                                            </div>
                                    </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div fxFlex="50">
                        <mat-card [class.mat-elevation-z6]="true">
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                <div  fxFlex="100">
                                    Current Vehicle
                                </div>
                                <div FxFlex="60">
                                    <button type="button" class="pull-right" mat-raised-button color="primary" (click)="leads.existingVehicle.push({})">+&nbsp; Add More</button>
                                </div>
                            </div>
                            <mat-card-content style="max-height: 240px; overflow: auto;">
                                <div *ngFor="let item of leads.existingVehicle; let i = index "  style="border-bottom: 1px solid #ddd;">
                                    <div fxFlex="100">
                                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" *ngIf="i > 0">
                                            <div fxFlex="100">
                                                <button mat-icon-button color="warn" class="pull-right" (click)="leads.existingVehicle.splice(i, 1);">
                                                    <mat-icon>delete</mat-icon>
                                                    </button>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" >
                                            <mat-form-field fxFlex appearance="outline">
                                                <mat-label>Year</mat-label>
                                                <mat-select [(ngModel)]="item.year" [ngModelOptions]="{standalone: true}">
                                                  <mat-option *ngFor="let year of yearArray" [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                              <mat-form-field fxFlex appearance="outline">
                                                <mat-label>Make</mat-label>
                                                <mat-select [(ngModel)]="item.makeId" [ngModelOptions]="{standalone: true}" (selectionChange)="getOldModelList(item)">
                                                  <mat-option *ngFor="let make of makeArray" [value]="make.makeId" (onSelectionChange)="item.make = make.make">{{make.make}}</mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                        </div>
                                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                            <mat-form-field fxFlex appearance="outline">
                                                <mat-label>Model</mat-label>
                                                <mat-select [(ngModel)]="item.model" [ngModelOptions]="{standalone: true}" [disabled]="!item.makeId">
                                                    <mat-option *ngFor="let model of item.modelArray" (onSelectionChange)="item.modelId = model.pushKey" [value]="model.model" >{{model.model}}</mat-option>
                                                </mat-select>
                                              </mat-form-field>

                                            <mat-form-field fxFlex appearance="outline" >
                                                <mat-label>Varient</mat-label>
                                                <input matInput [(ngModel)]="item.varient" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                        </div>
                                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                            <mat-form-field fxFlex appearance="outline">
                                                <mat-label>Color</mat-label>
                                                <input matInput [(ngModel)]="item.color" [ngModelOptions]="{standalone: true}">
                                            </mat-form-field>
                                        </div>
                                </div>

                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                </mat-expansion-panel>
                <mat-expansion-panel class="parametersPanel">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Current Loan and Insurance Info
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px !important;">
                      <div fxFlex="50">
                          <mat-card [class.mat-elevation-z6]="true" >
                              <mat-card-header>
                                  <mat-card-subtitle style="font-size: 18px;">Current Loan</mat-card-subtitle>
                              </mat-card-header>
                              <mat-card-content>
                                      <div fxFlex="100">
                                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Bank Name</mat-label>
                                                    <mat-select [(ngModel)]="leads.currentFinance.bankName" [ngModelOptions]="{standalone: true}">
                                                      <mat-option *ngFor="let finance of financeBankList" (onSelectionChange)="leads.currentFinance.bankId = finance.pushKey" [value]="finance.bankName">{{finance.bankName}}</mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Loan Amount</mat-label>
                                                    <input matInput [(ngModel)]="leads.currentFinance.finnacedAmount" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>
                                            </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                  <mat-form-field fxFlex="30" appearance="outline">
                                                      <mat-label>Rate of Interest</mat-label>
                                                      <input matInput [(ngModel)]="leads.currentFinance.interestRate" [ngModelOptions]="{standalone: true}">
                                                      </mat-form-field>
                                                      <mat-form-field fxFlex="40" appearance="outline">
                                                        <mat-label>EMI</mat-label>
                                                        <input matInput [(ngModel)]="leads.currentFinance.EMI" [ngModelOptions]="{standalone: true}">
                                                        </mat-form-field>
                                                  <mat-form-field fxFlex="28" appearance="outline">
                                                      <mat-label>Tenure Year</mat-label>
                                                      <input matInput [(ngModel)]="leads.currentFinance.tenureYear" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>
                                              </div>
                                      </div>
                              </mat-card-content>
                          </mat-card>
                      </div>
                      <div fxFlex="50">
                          <mat-card [class.mat-elevation-z6]="true">
                              <mat-card-header>
                                  <mat-card-subtitle style="font-size: 18px;">Current Insurance</mat-card-subtitle>
                              </mat-card-header>
                              <mat-card-content>
                                      <div fxFlex="100">
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Company Name</mat-label>
                                                    <mat-select [(ngModel)]="leads.currentInsurance.companyName" [ngModelOptions]="{standalone: true}">
                                                      <mat-option *ngFor="let insurance of InsuranceCompanyList" (onSelectionChange)="leads.currentInsurance.companyPushKey = insurance.pushKey" [value]="insurance.name">{{insurance.name}}</mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                                  <mat-form-field fxFlex appearance="outline">
                                                      <mat-label>Insured Year</mat-label>
                                                      <input matInput [(ngModel)]="leads.currentInsurance.insuredYear" [ngModelOptions]="{standalone: true}">
                                                      </mat-form-field>
                                              </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                  <mat-form-field fxFlex appearance="outline">
                                                      <mat-label>Premium Amount</mat-label>
                                                      <input matInput [(ngModel)]="leads.currentInsurance.premiumAmount" [ngModelOptions]="{standalone: true}">
                                                      </mat-form-field>
                                              </div>
                                      </div>
                              </mat-card-content>
                          </mat-card>
                      </div>
                  </div>
                  </mat-expansion-panel>
              </mat-accordion>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px !important;">
                      <div fxFlex="50">
                          <mat-card [class.mat-elevation-z6]="true" >
                              <mat-card-header>
                                  <mat-card-subtitle style="font-size: 18px;">Enquiry</mat-card-subtitle>
                              </mat-card-header>
                              <mat-card-content>
                                      <div fxFlex="100">
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field  fxFlex appearance="outline">
                                                    <mat-label>Enquiry Date</mat-label>
                                                    <input matInput disabled [(ngModel)]="leads.enquiry.enquiryDate" [ngModelOptions]="{standalone: true}" [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                                                  </mat-form-field>
                                                  <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Enquiry Source</mat-label>
                                                    <mat-select [(ngModel)]="leads.enquiry.enquirySource" [ngModelOptions]="{standalone: true}">
                                                      <mat-option *ngFor="let source of enquirySourceList" (onSelectionChange)="leads.enquiry.enquirySourceId = source.pushKey" [value]="source.description">{{source.description}}</mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                              </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                  <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Enquiry Awareness</mat-label>
                                                    <mat-select [(ngModel)]="leads.enquiry.enquiryAwareness" [ngModelOptions]="{standalone: true}">
                                                        <mat-option *ngFor="let awareness of enquiryAwarenessList" (onSelectionChange)="leads.enquiry.enquiryAwarenessId = awareness.pushKey" [value]="awareness.description">{{awareness.description}}</mat-option>
                                                    </mat-select>
                                                  </mat-form-field>
                                                <mat-form-field fxFlex appearance="outline">
                                                  <mat-label>Assign</mat-label>
                                                  <mat-select [(ngModel)]="leads.assignedName" [ngModelOptions]="{standalone: true}">
                                                    <!-- <mat-option *ngFor="let employee of employeeList" (onSelectionChange)="leads.assignedName = (employee.firstName+' '+employee.lastName).toUpperCase()" [value]="employee.uid">{{employee.firstName}} {{employee.lastName}}</mat-option> -->
                                                    <mat-option *ngFor="let employee of employeeList" (onSelectionChange)="leads.assignedUId = employee.uid" [value]="employee.firstName + ' ' + employee.lastName">{{employee.firstName}} {{employee.lastName}}</mat-option>
                                                  </mat-select>
                                                </mat-form-field>

                                              </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <div fxFlex="30">Customer Type</div>
                                                <mat-radio-group fxFlex="60" [(ngModel)]="leads.customerType" [ngModelOptions]="{standalone: true}" color="primary">
                                                    <mat-radio-button fxFlex  name="customerType" value="individual">Individual</mat-radio-button>
                                                    <mat-radio-button fxFlex name="customerType" value="company">Company</mat-radio-button>
                                                </mat-radio-group>
                                              </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px;">
                                                <div fxFlex="30">Lead Type</div>
                                                <mat-radio-group fxFlex="60"  [(ngModel)]="leads.leadType" disabled="true"  [ngModelOptions]="{standalone: true}" color="primary">
                                                    <mat-radio-button fxFlex  name="leadType"  value="hot">Hot</mat-radio-button>
                                                    <mat-radio-button fxFlex name="leadType" value="warm">Warm</mat-radio-button>
                                                    <mat-radio-button fxFlex name="leadType" value="cold">Cold</mat-radio-button>
                                                </mat-radio-group>
                                                <div fxFlex="10">
                                                    <span class="material-icons" matTooltip="
                                                    If Next Follow-up Date between&#13;
                                                     1 - 15 days  Hot&#13;
                                                     16 days - 30 days  Warm&#13;
                                                     30 days  Cold"
                                                    [matTooltipClass]="'my-tooltip'">
                                                        info
                                                        </span>
                                                </div>
                                              </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field  fxFlex="40" appearance="outline" >
                                                    <mat-label>Next Follow-up Date</mat-label>
                                                    <input matInput disabled [(ngModel)]="leads.enquiry.nextFollowupDate" [ngModelOptions]="{standalone: true}" [matDatepicker]="nextfollowup" (dateChange)="changeLeadType(leads.enquiry.nextFollowupDate)">
                                                    <mat-datepicker-toggle matSuffix [for]="nextfollowup"></mat-datepicker-toggle>
                                                    <mat-datepicker #nextfollowup disabled="false"></mat-datepicker>
                                                  </mat-form-field>
                                                  <mat-form-field fxFlex="60" appearance="outline">
                                                    <mat-label>Consultant Remark</mat-label>
                                                    <textarea matInput placeholder="Consultant Remark" [(ngModel)]="leads.enquiry.remarks" [ngModelOptions]="{standalone: true}"></textarea>
                                                  </mat-form-field>
                                              </div>
                                      </div>
                              </mat-card-content>
                          </mat-card>
                      </div>
                      <div fxFlex="50">
                          <mat-card [class.mat-elevation-z6]="true">
                                  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                                    <div fxFlex="49" style="font-size: 18px;">Vehicle</div>
                                    <div fxFlex="49">
                                      <button class="pull-right" mat-raised-button color="primary" (click)="selectVehicle()">{{vehicle.makeName ? "Change": "Select"}} Vehicle</button>
                                    </div>
                                  </div>
                              <mat-card-content style="height: 305px;">
                                  <div fxFlex="100" >
                                    <ng-container *ngIf="vehicle && vehicle.vehicleImages && vehicle.vehicleImages[0];else other_content">
                                      <img id="card-img" [src]="vehicle.vehicleImages[0].downloadUrl"
                                        class="img-fluid" alt="images">
                                    </ng-container>
                                    <ng-template #other_content>
                                        <img id="card-img" src="../../../../assets/images/no-image1.png"
                                        class="img-fluid" alt="images">
                                    </ng-template>
                                    <ng-container *ngIf="vehicle">
                                      <div class="md-title title-font-size" style="margin-bottom: 10px; margin-top: 10px; font-size: 22px;">
                                          <strong>{{vehicle.makeName}} &nbsp;{{vehicle.modelName}}&nbsp;{{vehicle.submodelName}}</strong>
                                      </div>
                                      <div  *ngIf="vehicle.year">
                                           <div>{{vehicle.year}}&nbsp;|&nbsp;{{vehicle.fuelType}}&nbsp;|&nbsp;
                                            <span *ngIf="vehicle.km">
                                               {{vehicle.km | number : '1.0-2'}}&nbsp;KM
                                           </span>

                                           </div>
                                      </div>
                                      <div>
                                          <h4 *ngIf="vehicle.salePrice" style="font-size: 20px; margin-top: 10px;">
                                              <i class="fa fa-inr"></i>&nbsp;{{vehicle.salePrice  | number : '1.0-2' }}
                                          </h4>
                                      </div>
                                  </ng-container>
                                  </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div style="height: 30px;"></div>
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                        <button fxFlex="10" mat-raised-button color="warn" type="reset">Clear</button>
                        <button fxFlex="10" mat-raised-button color="primary" type="submit" [disabled]="!profileForm.valid">{{leads.pushKey ? 'Update' : 'Save'}}</button>
                    </div>
                    <div style="height: 30px;"></div>
            </form>
        </div>
</div>
