<ngx-alerts></ngx-alerts>
<div class="container-fluid" style="height: 80vh; overflow: auto;">
    <div class="row mb-10">
        <div class="col-sm-12 col-md-12" style="padding: 0px;">
            <div class="card example-full-width ">
                <div class="card-header  custheader">
                    <div class="row">
                        <div class="col-sm-7 text-right">
                            <h4 class="card-title" style="font-size: 22px">Sales Contract Form</h4>
                        </div>
                        <div class="col-sm-5 text-right" printSectionId="print-section" ngxPrint>
                            <mat-icon class="hidden-print"
                                style="vertical-align: middle;font-size: 25px;cursor: pointer;" (click)="print()">
                                local_printshop</mat-icon>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="margin: 10px">
                    <form>
                        <div class="row">
                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Date" [(ngModel)]="contractModel.date" name="date"
                                        readonly>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Time" [(ngModel)]="contractModel.time" name="time"
                                        readonly>
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Location" [(ngModel)]="contractModel.location"
                                        name="location">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-top:15px;">
                            <div class="col-sm-12">
                                <h3>Customer Details</h3>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Name" [(ngModel)]="contractModel.name" name="name">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="S/O" [(ngModel)]="contractModel.so" name="so">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Address" [(ngModel)]="contractModel.address"
                                        name="address">
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Phone No(R)" [(ngModel)]="contractModel.phoneNoR"
                                        name="phoneNoR">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Phone No(O)" [(ngModel)]="contractModel.phoneNoO"
                                        name="phoneNoO">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Phone No(MOB)" [(ngModel)]="contractModel.phoneNoMOB"
                                        name="phoneNoMOB">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" style="margin-top:15px;">
                            <div class="col-sm-12">
                                <h3>Booked Vehicle Details</h3>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-3">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Make" [(ngModel)]="contractModel.make" name="make">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-3">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Model" [(ngModel)]="contractModel.model" name="model">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-3">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Variant" [(ngModel)]="contractModel.variant"
                                        name="variant">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-3">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Colour" [(ngModel)]="contractModel.colour"
                                        name="colour">
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-6">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Registration Number"
                                        [(ngModel)]="contractModel.registrationNumber" name="registrationNumber">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Owner Serial" [(ngModel)]="contractModel.ownerSerial"
                                        name="ownerSerial">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Fuel Type" [(ngModel)]="contractModel.fuelType"
                                        name="fuelType">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-6">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Chassis No" [(ngModel)]="contractModel.chassisNo"
                                        name="chassisNo">
                                </mat-form-field>
                            </div>
                        </div>


                        <table style="margin-top:10px;">
                            <caption>Payment Master</caption>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Particulars</th>
                                <th>Remarks</th>
                                <th>Amount</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>Vehicle Price</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.vehiclePriceRemarks"
                                        name="vehiclePriceRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.vehiclePriceAmount"
                                        name="vehiclePriceAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>2</td>
                                <td>Warranty Cost</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.warrantyCostRemarks"
                                        name="warrantyCostRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.warrantyCostAmount"
                                        name="warrantyCostAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>3</td>
                                <td>1 Year Service</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.oneYearServiceRemarks"
                                        name="oneYearServiceRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.oneYearServiceAmount"
                                        name="oneYearServiceAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>4</td>
                                <td>Insurance</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.insuranceRemarks"
                                        name="insuranceRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.insuranceAmount"
                                        name="insuranceAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>5</td>
                                <td>Zero Dep Cover</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.zeroDepCoverRemarks"
                                        name="zeroDepCoverRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.zeroDepCoverAmount"
                                        name="zeroDepCoverAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>6</td>
                                <td>RSA</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.rsaRemarks"
                                        name="rsaRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.rsaAmount"
                                        name="rsaAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>7</td>
                                <td>Accessories</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.accessoriesRemarks"
                                        name="accessoriesRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.accessoriesAmount"
                                        name="accessoriesAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>8</td>
                                <td>RTO & Transfer Charges</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.rtoTransRemarks"
                                        name="rtoTransRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.rtoTransAmount"
                                        name="rtoTransAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>9</td>
                                <td>Others</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.othersRemarks"
                                        name="othersRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.othersAmount"
                                        name="othersAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>10</td>
                                <td>GST</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.gstRemarks"
                                        name="gstRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.gstAmount"
                                        name="gstAmount" />
                                </td>
                            </tr>

                            <tr>
                                <td>11</td>
                                <td>Additional RF as per Customer Request</td>
                                <td>
                                    <input matInput type="text" [(ngModel)]="contractModel.additionaRFRemarks"
                                        name="additionaRFRemarks" />
                                </td>
                                <td><input matInput type="text" [(ngModel)]="contractModel.additionaRFAmount"
                                        name="additionaRFAmount" />
                                </td>
                            </tr>

                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align: right;">&#x20a8; {{totalAmount | number}}</td>
                                </tr>
                            </tfoot>

                        </table>

                        <div class="row" style="margin-top:20px;">
                            <div class="col-sm-12">
                                <h3>Payment Mode</h3>


                                <div class="row">
                                    <div class="col-sm-4" *ngFor="let mode of paymentModes">
                                        {{mode.name}}
                                        <mat-checkbox style="vertical-align: text-top;" [(ngModel)]="mode.selected"
                                            [ngModelOptions]="{standalone: true}" (change)="isChange(mode, $event)">
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row" style="margin-top:15px;">
                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Finance" [(ngModel)]="contractModel.finance"
                                        name="finance">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Loan" [(ngModel)]="contractModel.loan" name="loan">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Tenure" [(ngModel)]="contractModel.tenure"
                                        name="tenure">
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Exchange" [(ngModel)]="contractModel.exchange"
                                        name="exchange">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Registration No."
                                        [(ngModel)]="contractModel.registrationNO" name="registrationNO">
                                </mat-form-field>
                            </div>

                            <div class="col-sm-4">
                                <mat-form-field style="display: initial;">
                                    <input matInput placeholder="Amount" [(ngModel)]="contractModel.amount"
                                        name="amount">
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-12">
                                <mat-form-field style="display: initial;">
                                    <textarea matInput style="resize: none; width: 100%;" maxlength="1000" rows="5"
                                        cols="25" placeholder="Job Agreed Upon:"
                                        [(ngModel)]="contractModel.jobAgreedUpon" name="jobAgreedUpon">   </textarea>
                                </mat-form-field>
                            </div>
                        </div>





                        <div class="row" style="margin:0px;text-align: center;margin-top: 20px;margin-bottom: 30px;">
                            <div class="col-sm-12">
                                <button (click)="sendFormData()" class="submit" type="submit" mat-raised-button
                                    color="primary"
                                    style="padding-left: 20px;padding-right: 20px; background: linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255)); border-color:#c20518;font-size: 17px;">Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Print Div -->
<div class="container-fluid" id="print-section" [hidden]=true>
    <div class="card-body" style="margin: 10px">
        <form>
            <div class="row">
                <div class="col-sm-4">
                    <span style="font-size: 15px;">Date:&nbsp;{{contractModel.date}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Time:&nbsp;{{contractModel.time}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Location:&nbsp;{{contractModel.location}}</span>
                </div>
            </div>

            <div class="row" style="margin-top:15px;">
                <div class="col-sm-12">
                    <h3>Customer Details</h3>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <span style="font-size: 15px;">Name:&nbsp;{{contractModel.name}}</span>
                </div>

                <div class="col-sm-6">
                    <span style="font-size: 15px;">S/O:&nbsp;{{contractModel.so}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <span style="font-size: 15px;">Address:&nbsp;{{contractModel.address}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                    <span style="font-size: 15px;">Phone No(R):&nbsp;{{contractModel.phoneNoR}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Phone No(O):&nbsp;{{contractModel.phoneNoO}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Phone No(MOB):&nbsp;{{contractModel.phoneNoMOB}}</span>
                </div>
            </div>

            <div class="row" style="margin-top:15px;">
                <div class="col-sm-12">
                    <h3>Booked Vehicle Details</h3>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3">
                    <span style="font-size: 15px;">Make:&nbsp;{{contractModel.make}}</span>
                </div>

                <div class="col-sm-3">
                    <span style="font-size: 15px;">Model:&nbsp;{{contractModel.model}}</span>
                </div>

                <div class="col-sm-3">
                    <span style="font-size: 15px;">Variant:&nbsp;{{contractModel.variant}}</span>
                </div>

                <div class="col-sm-3">
                    <span style="font-size: 15px;">Colour:&nbsp;{{contractModel.colour}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <span style="font-size: 15px;">Registration Number:&nbsp;{{contractModel.registrationNumber}}</span>
                </div>

                <div class="col-sm-6">
                    <span style="font-size: 15px;">Owner Serial:&nbsp;{{contractModel.ownerSerial}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <span style="font-size: 15px;">Fuel Type:&nbsp;{{contractModel.fuelType}}</span>
                </div>

                <div class="col-sm-6">
                    <span style="font-size: 15px;">Chassis No:&nbsp;{{contractModel.chassisNo}}</span>
                </div>
            </div>


            <table style="margin-top:10px;">
                <caption>Payment Master</caption>
                <tr>
                    <th>Sr. No.</th>
                    <th>Particulars</th>
                    <th>Remarks</th>
                    <th>Amount</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Vehicle Price</td>
                    <td>
                        <span>{{contractModel.vehiclePriceRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.vehiclePriceAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>2</td>
                    <td>Warranty Cost</td>
                    <td>
                        <span>{{contractModel.warrantyCostRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.warrantyCostAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>3</td>
                    <td>1 Year Service</td>
                    <td>
                        <span>{{contractModel.oneYearServiceRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.oneYearServiceAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>4</td>
                    <td>Insurance</td>
                    <td>
                        <span>{{contractModel.insuranceRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.insuranceAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>5</td>
                    <td>Zero Dep Cover</td>
                    <td>
                        <span>{{contractModel.zeroDepCoverRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.zeroDepCoverAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>6</td>
                    <td>RSA</td>
                    <td>
                        <span>{{contractModel.rsaRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.rsaAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>7</td>
                    <td>Accessories</td>
                    <td>
                        <span>{{contractModel.accessoriesRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.accessoriesAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>8</td>
                    <td>RTO & Transfer Charges</td>
                    <td>
                        <span>{{contractModel.rtoTransRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.rtoTransAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>9</td>
                    <td>Others</td>
                    <td>
                        <span>{{contractModel.othersRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.othersAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>10</td>
                    <td>GST</td>
                    <td>
                        <span>{{contractModel.gstRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.gstAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>11</td>
                    <td>Additional RF as per Customer Request</td>
                    <td>
                        <span>{{contractModel.additionaRFRemarks}}</span>
                    </td>
                    <td>
                        <span>{{contractModel.additionaRFAmount}}</span>
                    </td>
                </tr>

                <tr>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td>{{totalAmount}}</td>
                </tr>

            </table>

            <div class="row" style="margin-top:20px;">
                <div class="col-sm-12">
                    <h3>Payment Mode</h3>

                    <div class="row">
                        <div class="col-sm-4 form-check" *ngFor="let mode of paymentModes">
                            <label class="form-check-label" for="exampleCheck1"> {{mode.name}}</label>&nbsp;
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top:15px;">
                <div class="col-sm-4">
                    <span style="font-size: 15px;">Finance:&nbsp;{{contractModel.finance}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Loan:&nbsp;{{contractModel.loan}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Tenure:&nbsp;{{contractModel.tenure}}</span>
                </div>
            </div>


            <div class="row">
                <div class="col-sm-4">
                    <span style="font-size: 15px;">Exchange:&nbsp;{{contractModel.exchange}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Registration No.:&nbsp;{{contractModel.registrationNO}}</span>
                </div>

                <div class="col-sm-4">
                    <span style="font-size: 15px;">Amount:&nbsp;{{contractModel.amount}}</span>
                </div>
            </div>


            <div class="row" style="margin-top:20px;">
                <div class="col-sm-12">
                    <span style="font-size: 15px;">Job Agreed Upon:&nbsp;{{contractModel.jobAgreedUpon}}</span>
                </div>
            </div>
        </form>
    </div>
</div>