<mat-toolbar [ngStyle]="{'height':'50px'}" class="header-css">
    <div fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
            <div fxFlex="50">Sales</div>
            <div fxFlex="50" fxLayoutAlign="end start">
                <!-- <button mat-raised-button color="primary" (click) ="changeTo()">View</button> -->
            </div>
        </div>
    </div>
</mat-toolbar>
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <button mat-icon-button [matMenuTriggerFor]="menu" class="pull-right no-border">
                <mat-icon>settings</mat-icon>
              </button>
              <mat-menu #menu="matMenu" role="menuitemcheckbox">
                  <div>
                    <span class="material-icons pull-right" style="font-size: 25px; color: red; cursor: pointer; padding: 5px;">
                        highlight_off
                        </span>
                  </div>
                <button mat-menu-item *ngFor="let item of tableHeader" (click)="$event.stopPropagation();" class="no-border">
                    <mat-checkbox color="warn" [checked]="item.selected" color="primary" [(ngModel)]="item.selected">
                        {{item.displayName}}
                    </mat-checkbox>&nbsp;
                  </button>
              </mat-menu>                                                                
        </div>
    </div>
    <div class="row no-margin">
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>SR. NO.</th>
                        <th *ngFor="let item of tableHeader" class="no-wrap" [hidden]="!item.selected">{{item.displayName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of dataList; let i = index">
                        <td>{{i+1}}</td>
                        <td *ngFor="let item of tableHeader" class="no-wrap" [hidden]="!item.selected">{{data[item.keyName]}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>