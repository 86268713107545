<div class="contanier-fluid" style="margin-bottom: 100px;background-color: rgb(250,250,250)">
    <div class="row" style="margin: 0px;">
        <div class="col-sm-12 custom-header">
            PAYMENT RECEIPT
        </div>
    </div>
    <div class="row margin-lr-none" style="margin-top: 10px;">
        <div class="col-sm-4">
            <div>
                <label>{{user.businessName}}</label>
            </div>
            <div>
                <label>Showroom</label>
            </div>
            <div>
                <label>{{user.address}}</label>
            </div>
            <div>
                <label>{{user.state}} - {{user.zip}}</label>
            </div>
            <div>
                <label *ngIf="user.phone">Tel: {{user.phone}}</label>
            </div>
            <div>
                <label *ngIf="user.email">Email: {{user.email}}</label>
            </div>
            <div>
                <label *ngIf="user.pan">PAN#: {{user.pan}}</label>
            </div>
            <div>
                <label *ngIf="user.tan">TAN#: {{user.tan}}</label>
            </div>
            <div>
                <label *ngIf="user.gstin">GSTIN#: {{user.gstin}}</label>
            </div>
        </div>
        <div class="col-sm-5 text-center">
            <div>
                <label>{{currentLead.fullName}}</label>
            </div>
            <div *ngIf="currentLead.address">
                <label>{{currentLead.address.address}}</label>
            </div>
            <div *ngIf="currentLead.address">
                <label>{{currentLead.address.state}} - {{currentLead.address.zip}}</label>
            </div>
        </div>
        <div class="col-sm-3 text-right">
            <div>
                <label>Deal #{{currentLead.dealNumber}}</label>
            </div>
            <div>
                <label *ngIf="currentLead.mobile">Mobile#:&nbsp;</label><span>{{currentLead.mobile}}</span>
            </div>
            <div>
                <label *ngIf="currentLead.email">Email:&nbsp;</label><span>{{currentLead.email}}</span>
            </div>
        </div>
    </div>
    <div class="row margin-lr-none" style="height: 48px;">
        <div class="col-sm-12" style="font-size: 18px;background-color: grey;">
            <div class="row" style="margin-top: 10px;">
                <div class="col-sm-6" style="color: #fff;">Balance</div>
                <div class="col-sm-6 text-right" style="color: #fff;"><span class="fa fa-inr"></span>&nbsp;{{this.balancePayment | number}}</div>
            </div>
        </div>
    </div>

    <div class="row margin-lr-none">
        <div class="col-sm-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th style="width: 12%;">Receipt#</th>
                        <th>Date</th>
                        <th>Mode of Payment</th>
                        <th style="text-align: right;"><span class="fa fa-inr"></span>&nbsp;Amount</th>
                        <th>Invalidate</th>
                        <!-- <th>Print</th> -->
                    </tr>
                </thead>
                <thead>
                    <tr *ngFor="let item of mainList;let index=index">
                        <td>
                            <mat-icon (click)="pushObj()" *ngIf="index==0" class="add-item">add_circle</mat-icon>
                            <mat-icon (click)="removeItem(index)" class="remove-item" *ngIf="index>0 && !item.pushKey">remove_circle</mat-icon>
                        </td>
                        <td>
                            <!-- <mat-form-field class="example-full-width">
                                <input matInput [(ngModel)]="item.receiptNumber">
                            </mat-form-field> -->
                            {{item.receiptId}}
                        </td>
                        <td>
                            <mat-form-field>
                                <mat-label>Date</mat-label>
                                <input matInput [disabled]="item.pushKey" [(ngModel)]="item.invoiceDate" name="invoiceDate" [matDatepicker]="invoiceDate">
                                <mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
                                <mat-datepicker #invoiceDate></mat-datepicker>
                            </mat-form-field>
                        </td>
                        <td>
                            <div class="row">
                                <div class="col-sm-6" *ngFor="let mode of modeOfPaymentArr" >
                                    <mat-radio-group [(ngModel)]="item.modeOfPayment" color="primary">
                                        <mat-radio-button fxFlex [disabled]="item.pushKey"  name="{{mode}}" value="{{mode}}">{{mode}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="row" *ngIf="item.modeOfPayment == 'Cheque / DD'" style="margin: 0px;">
                                <div class="col-sm-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Bank:</mat-label>
                                        <input matInput [(ngModel)]="item.chequeBankName">
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Cheque#:</mat-label>
                                        <input matInput [(ngModel)]="item.chequeNumber">
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Date</mat-label>
                                        <input matInput [(ngModel)]="item.chequeDate" name="chequeDate" [matDatepicker]="chequeDate">
                                        <mat-datepicker-toggle matSuffix [for]="chequeDate"></mat-datepicker-toggle>
                                        <mat-datepicker #chequeDate></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" *ngIf="item.modeOfPayment == 'Online Transfer'" style="margin: 0px;">
                                <div class="col-sm-6">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Bank:</mat-label>
                                        <input matInput [(ngModel)]="item.onlineBankName">
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-6">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Transaction#:</mat-label>
                                        <input matInput [(ngModel)]="item.transactionNumber">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" *ngIf="item.modeOfPayment == 'Other'" style="margin: 0px;">
                                <div class="col-sm-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Other:</mat-label>
                                        <input matInput [(ngModel)]="item.other">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" *ngIf="item.modeOfPayment == 'Card'" style="margin: 0px;padding-right: 5px;">
                                <div class="col-sm-6 padding-lr-none">
                                    <mat-form-field appearance="outline" style="width: 100%; ">
                                        <mat-label>Type</mat-label>
                                        <mat-select [(value)]="item.cardType" name="cardType">
                                            <mat-option value="{{item}}" *ngFor="let item of cardType">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-6 padding-lr-none">
                                    <mat-form-field appearance="outline" style="width: 100%; ">
                                        <mat-label>Name</mat-label>
                                        <mat-select [(value)]="item.cardName" name="cardName">
                                            <mat-option value="{{item}}" *ngFor="let item of cardName">{{item}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-6 padding-lr-none">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Transaction#:</mat-label>
                                        <input matInput [(ngModel)]="item.cardTransactionNumber">
                                    </mat-form-field>
                                </div>
                            </div>
                        </td>
                        <td>
                            <mat-form-field  style="text-align: right;" class="example-full-width">
                                <input matInput [disabled]="item.pushKey || item.fromBooking" [(ngModel)]="item.amount">
                            </mat-form-field>
                        </td>
                        <td style="text-align: center;">
                            <mat-checkbox [disabled]="item.pushKey && item.invalidate" [(ngModel)]="item.invalidate" [ngModelOptions]="{standalone: true}" color="primary"></mat-checkbox>
                        </td>
                        <!-- <td>
                            <mat-icon> local_printshop</mat-icon>
                        </td> -->
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <div class="row margin-lr-none" style="margin-top: 10px;">
        <div class="col-sm-6 text-right">
            <button mat-raised-button [disabled]="disableBtn" class="primary-btn" (click)="saveInfo()" color="primary" type="submit">Save</button>
        </div>
    </div>
    <div class="row" style="margin: 0px;">
        <div class="col-sm-12" style="float: right;">
            Authorised Signatory
        </div>
    </div>
    <div class="row" style="margin: 0px;">
        <div class="col-sm-12">
            <label>Showroom: {{user.address}} {{user.state}} - {{user.zip}}</label>
        </div>
    </div>
</div>
