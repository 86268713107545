import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CalendarView, CalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/service/utility.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FirebaseService } from 'src/app/service/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CommonService } from 'src/app/service/common.service';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { AlertService } from 'ngx-alerts';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-leads-appointment',
  templateUrl: './leads-appointment.component.html',
  styleUrls: ['./leads-appointment.component.css']
})
export class LeadsAppointmentComponent implements OnInit {

  user: any = {};
  selectedCustomer: any = {};
  testDriveFinalData: any = [];
  dataSource = new MatTableDataSource();

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  activeStatus: string;
  dataTypeArr: any = [{ type: CalendarView.Day, label: "Day" }, { type: CalendarView.Week, label: "Week" }, { type: CalendarView.Month, label: "Month" }];
  statusTypeArr: any = [
    { type: 'enquiry', label: 'Enquiry', 'date': 'creditApplicationDate' }, { type: 'followup', label: 'Follow up', 'date': 'vehicleTrading' }, { type: 'testdrive', label: 'Test Drive', 'date': 'testDriveDate' },
    { type: 'booking', label: 'Booking', 'date': 'purchaseDoneDate' }, { type: 'billing', label: 'Billed', 'date': 'rcbDoneDate' }];
  dataType: any = CalendarView.Month;
  statusType: any = "followup";
  constructor(private utilSer: UtilityService,
    private firebaseService: FirebaseService,
    private router: Router,
    private loaderService: NgxUiLoaderService,
    private apiService: ApiService, private uilServ: UtilityService,
    private dialog: MatDialog, public common: CommonService, private alertService: AlertService
  ) {
    this.activeStatus = "followup";
    this.selectedCustomer = sessionStorage.getItem("currentLead") ? JSON.parse(sessionStorage.getItem("currentLead")) : null;
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      if (this.selectedCustomer) {
        this.openDialog(this.selectedCustomer, date.getTime());
      } else {
        if (!this.activeDayIsOpen) {
          if (!this.selectedCustomer) {
            this.alertService.danger("Please select customer to continue");
            this.router.navigate(["home/leads-list"]);
            return;
          }
        }
      }
      this.viewDate = date;
    }
  }

  handleEvent(action: string, event: any): void {
    console.log(event)
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
    // let vehicleArr = Object.values(event.custObject['vehicleList']);
    // if (vehicleArr.length > 0) {
    //   event.custObject['selectedVehicle'] = vehicleArr[0];
    // }
    // this.uilServ.setSelectedCustomer(event.custObject, event.custObject.selectedVehicle);
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //   this.router.navigate(['home/leads-summary']));
    this.openDialog(event.custObject, event.start.getTime());
  }

  openDialog(object: any, date: any) {
    const dialogRef = this.dialog.open(BookAppointmentComponent, {
      panelClass: 'my-component-bottom-sheet',
      disableClose: true,
      data: {
        customer: object,
        date: date,
      }
    });

    // dialogRef.afterDismissed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnInit() {
    //this.user = this.utilSer.getUser();
    this.getLeadsByStatus("followup", "lastupdationDate");
  }

  public filterVehicle = () => {
    if (this.testDriveFinalData.length > 0) {
      this.testDriveFinalData.forEach((element: any) => {
        if (element['vehicleList']) {
          let vehicleArr = Object.values(element['vehicleList']);
          if (vehicleArr.length > 0) {
            element['selectedVehicle'] = vehicleArr[0];
          }
        }
      });
    }
  }

  myStyle(val): object {
    return { "background-color": val };
  }


  getLeadsByStatus(status: string, dateKey): void {
    this.events = [];
    this.loaderService.startBackground();
    let path = "leads_info/" + this.common.getGroupUId() + "/" + this.common.getDealerUId();
    this.activeStatus = status;
    this.apiService.getPlannerData(this.common.getGroupUId(), this.common.getDealerUId(), status).subscribe((res: any) => {
      let plannerArray = [];
      if (res != null) {
        if (res.status == "success") {
          if (res.content != "no_record_found") {
            let content = res.content;
            let grades = {};
            let list = [];
            content.forEach(function (item) {
              if (!grades[item.pushKey + "~" + item.timestamp]) {
                list.push(item);
                grades[item.pushKey + "~" + item.timestamp] = true;
              }
            });
            content = list;
            for (let i in content) {
              let eventObject: any = {
                start: startOfDay(new Date(content[i].timestamp)),
                title: content[i].name.toUpperCase(),
                color: colors.yellow,
                draggable: false,
                custObject: content[i],
                resizable: {
                  beforeStart: false,
                  afterEnd: false,
                },
              }
              this.events.push(eventObject);
            }
          }
        }
      }
      this.refresh.next();
      this.loaderService.stopBackground();
      console.log(this.events);
    })
  }

}
