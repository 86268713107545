import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'ngx-alerts';
export class FormAttributes {
  message: string;
  firebaseUId: string;
  dealerId: string;
  timestamp: string;
  mobile: string;
  fullName: string;
  dealerName: string;
}
@Component({
  selector: 'app-sms-dialog',
  templateUrl: './sms-dialog.component.html',
  styleUrls: ['./sms-dialog.component.css']
})
export class SmsDialogComponent implements OnInit {

  public formAttr: FormAttributes;
  public customer: any;
  public date: any;
  public category: any = [{ 'title': 'Follow up', 'value': 'followup' }];
  public reason: any = [{ 'title': 'Video Conference' }];
  public loginDealer: any;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private bottomSheetRef: MatDialogRef<SmsDialogComponent>, private apiService: ApiService,
    private ngxService: NgxUiLoaderService, private alertService: AlertService) {
    this.formAttr = new FormAttributes();
    if (data) {
      this.customer = data.customer;
      this.formAttr.mobile = this.customer.mobile;
    }
    this.loginDealer = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {};
  }

  ngOnInit() {
  }

  saveCustomer() {
    var validateUser = true;
    this.ngxService.startBackground();
    this.formAttr.firebaseUId = this.customer.pushKey;
    this.formAttr.dealerId = this.loginDealer.firebaseUId;
    this.formAttr.timestamp = this.date;
    this.formAttr.fullName = this.customer.fullName;
    this.formAttr.dealerName = this.loginDealer.businessName;
    console.log(this.formAttr);
    this.apiService.sendSMS(this.formAttr.mobile, this.formAttr.message, "91")
      .subscribe((resMap: any) => {
        this.ngxService.stopBackground();
        if (resMap.message) {
          this.alertService.success("Message Sent");
          this.bottomSheetRef.close();
        } else {
          this.alertService.danger("Message not Sent");
        }
      }, error => {
        console.log(error);
        this.alertService.danger("Record not saved");
        this.ngxService.stopBackground();
      });
  }

  close() {
    this.bottomSheetRef.close();
  }

}
