import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { FirebaseService } from 'src/app/service/firebase.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.css']
})
export class VehicleDetailsComponent implements OnInit {

  vehicleId: any;
  vehicleDetails: any = {};
  constructor(private route: ActivatedRoute, private commonService: CommonService
    , private fireDB: FirebaseService,  public ngxService: NgxUiLoaderService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.vehicleId = params['params'] && params['params']['vehicleId'] ? params['params']['vehicleId'] : null;
     console.log(this.vehicleId);
     this.getVehicleDetails();
    });
  }

  getVehicleDetails = () => {
    this.ngxService.startBackground();
    this.fireDB.get('purchase/inventory/'+this.commonService.getGroupUId()+'/'+this.commonService.getDealerUId()+"/"+this.vehicleId).then(res => {
      if(res.val()) {
        this.vehicleDetails = res.val();
      }
      this.ngxService.stopBackground();
    })
  }

  allotVehicle = () => {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        selectedVehicleId: this.vehicleId             
      },
  };
    this.router.navigate(['home/leads-enquiry'], navigationExtras);
  }

}
