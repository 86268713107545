export class MenuDealer {
  // [
  //   {'label' : 'Home',  'selected' : true, 'icon' : 'person_pin' , 'route' : 'home'},
  //   {'label' : 'Purchase',  'selected' : false, 'icon' : 'person_pin' , 'route' : 'purchase'},
  //   {'label' : 'Enquiry',  'selected' : false, 'icon' : 'person_pin' , 'route' : 'leads-enquiry'},
  //   {'label' : 'Follow-up',  'selected' : false, 'icon' : 'person_pin' , 'route' : 'leads-followup'},
  // ]

  SALE = [
    { 'label': 'All Leads', 'title': 'Home', 'selected': true, 'icon': 'supervised_user_circle', 'route': 'leads-list' },
    { 'label': 'Appointment', 'selected': false, 'icon': 'subject', 'route': 'leads-appointment' },
    { 'label': 'Enquiry', 'title': 'Enquiry', 'selected': false, 'icon': 'person_pin', 'route': 'leads-enquiry' },
    { 'label': 'Vehicle', 'selected': false, 'icon': 'person_pin', 'route': 'allotment-vehicle-list' },
    { 'label': 'Follow-up', 'title': 'Follow-up', 'selected': false, 'icon': 'person_pin', 'route': 'leads-followup' },
    { 'label': 'Test Drive', 'selected': false, 'icon': 'person_pin', 'route': 'leads-testdrive' },
    { 'label': 'Booking', 'title': 'Booking', 'selected': false, 'icon': 'person_pin', 'route': 'leads-booking' },
    { 'label': 'Sales Contract', 'title': 'Sales Contract', 'selected': false, 'icon': 'person_pin', 'route': 'create-sales-contract' },
    { 'label': 'Proforma Invoice', 'title': 'Proforma Invoice', 'selected': false, 'icon': 'description', 'route': 'proforma-invoice' },
    { 'label': 'Payment Receipt', 'title': 'Payment Receipt', 'selected': false, 'icon': 'receipt', 'route': 'payment-receipt' },
    { 'label': 'Invoice', 'selected': false, 'icon': 'subject', 'route': 'create-invoice' },
  ]
  PURCHASE = [
    { 'label': 'Purchase', 'selected': false, 'icon': 'shopping_cart', 'route': 'purchaseM' },
  ]
  INVENTORY = [
    { 'label': 'Inventory', 'selected': false, 'icon': 'view_list', 'route': 'inventory-m' },
  ]

  SETUP = [
    { 'label': 'Setting', 'selected': false, 'icon': 'settings', 'route': 'dealer-setting' },
  ]

  REPORT = [
    { 'label': 'Reports', 'selected': false, 'icon': 'report', 'route': 'report' },
  ]
}
