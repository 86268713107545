import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    /*'Authorization': 'my-auth-token'*/
  })
};
@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  usedCarUrl  = environment.USEDCAR_API_URL;
  cardealerUrl = environment.CARDEALER_API_URL;
  constructor(private httpClient: HttpClient) { }

  saveEnquiry = (leads: any) => {
    return this.httpClient.post(this.usedCarUrl + "/api/leads/saveEnquiry", leads);
  }

  saveFollowup = (leads: any) => {
    return this.httpClient.post(this.usedCarUrl + "/api/leads/saveFollowup", leads);
  }

  saveTestdrive = (leads: any) => {
    return this.httpClient.post(this.usedCarUrl + "/api/leads/saveTestdrive", leads);
  }

  saveBooking = (leads: any) => {
    return this.httpClient.post(this.usedCarUrl + "/api/leads/saveBooking", leads);
  }

  getLeads = (groupUId, dealerUId, leadsId) => {
    return this.httpClient.get(this.usedCarUrl + '/api/leads/getLeads?groupUId='+groupUId+'&dealerUId='+dealerUId+'&leadId='+leadsId);
  }

  getPackageStatus(customerId: string, vehicleId:string){
    return this.httpClient.get(this.usedCarUrl + 'onespan/getPackageStatus?customerId='+customerId+'&vehicleId='+vehicleId);
 }
  getOldMakeList = (makeName) => {
    return this.httpClient.get(this.cardealerUrl+ "getOldMakeVhicleRecord");
  }
  getOldModelList = (makeId) => {
    return this.httpClient.get(this.cardealerUrl+ "getOldModelVhicleRecord?makeId="+makeId);
  }

  getBankNames = (groupUId) => {
    return this.httpClient.get(this.cardealerUrl+ 'api/finance/bank-list-group?groupId='+ groupUId);
  }

  getLeadsProcess = (groupUId, dealerUId, leadsId) => {
    return this.httpClient.get(this.usedCarUrl + '/api/leads/getLeadsProcess?groupUId='+groupUId+'&dealerUId='+dealerUId+'&leadId='+leadsId);
  }
  searchLeads(body: any): Observable<JSON> {
    return this.httpClient.post<JSON>(this.usedCarUrl + '/api/leads/searchLeads', body);
  }

  
}
