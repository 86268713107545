export const environment = {
  production: true,
  REGION_ID_OF_INDIA: "-LTlpsP-n3t9ARmLv-WM",
  usedcar_dev: {
    apiKey: "AIzaSyCLegke8ZQ7NAeMeo8cb1FVPWubPqeO9Go",
    authDomain: "usedcarz-479af.firebaseapp.com",
    databaseURL: "https://usedcarz-479af.firebaseio.com",
    projectId: "usedcarz-479af",
    storageBucket: "usedcarz-479af.appspot.com",
    messagingSenderId: "188520935740",
    appId: "1:188520935740:web:a7a474ec5cfbcd0459ae39",
    measurementId: "G-LJ7TZ5XK0Z"
  },
  cardealer_db: {
    apiKey: "AIzaSyCXTfoyb18l2xDia9_YB6FRAxFx8kdKq-U",
		authDomain: "carsdealers-users.firebaseapp.com",
		databaseURL: "https://carsdealers-users.firebaseio.com",
		projectId: "carsdealers-users",
		storageBucket: "carsdealers-users.appspot.com",
		messagingSenderId: "68881924628"
	},
  USEDCAR_API_URL: " https://usedcarservice.el.r.appspot.com/",
  CARDEALER_API_URL: "https://carsdealersbackend.el.r.appspot.com/",

  REGION_ID_IND: '-LTlpsP-n3t9ARmLv-WM',


  CUSTOMER_INFO: "customer-info",
  DEFAULT_VEHICLE: "defaultVehicle",
};