<div class="contanier-fluid" style="margin-bottom: 100px;background-color: rgb(250,250,250)">
    <div class="row" style="margin: 0px;">
        <div class="col-sm-12 custom-header">
           PROFORMA INVOICE
        </div>
    </div>
    <div class="row" style="margin: 0px;" *ngIf="!isBookingSaved">
        <div class="col-sm-12 alert alert-danger">
            <span>Proforma Invoice displays data when customer has approved total price in </span><a href="javascript:void(0)" [routerLink]="['/home/leads-booking']">Booking form</a>
        </div>
    </div>
    <div class="row margin-lr-none" style="margin-top: 10px;">
        <div class="col-sm-4">
            <div>
                <label>{{user.businessName}}</label>
            </div>
            <div>
                <label>Showroom</label>
            </div>
            <div>
                <label>{{user.address}}</label>
            </div>
            <div>
                <label>{{user.state}} - {{user.zip}}</label>
            </div>
            <div>
                <label *ngIf="user.phone">{{user.phone}}</label>
            </div>
            <div>
                <label *ngIf="user.email">{{user.email}}</label>
            </div>
        </div>
        <div class="col-sm-5 text-center">
            <div>
                <label>{{currentLead.fullName}}</label>
            </div>
            <div *ngIf="currentLead.address">
                <label>{{currentLead.address.address}}</label>
            </div>
            <div *ngIf="currentLead.address">
                <label>{{currentLead.address.state}} - {{currentLead.address.zip}}</label>
            </div>
            <div>
                <label *ngIf="currentLead.phone">{{currentLead.mobile}}</label>
            </div>
            <div>
                <label *ngIf="currentLead.email">{{currentLead.email}}</label>
            </div>
        </div>
        <div class="col-sm-3">
            <div>
                <label>Deal #{{currentLead.dealNumber}}</label>
            </div>
            <div>
                <mat-form-field class="example-full-width">
                    <mat-label>Invoice #</mat-label>
                    <input matInput [(ngModel)]="mainObj.invoiceRecipt">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Date</mat-label>
                    <input matInput [(ngModel)]="mainObj.invoiceDate" name="invoiceDate" [matDatepicker]="invoiceDate">
                    <mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
                    <mat-datepicker #invoiceDate></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row margin-lr-none" style="margin-top: 10px;">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th colspan="6" style="text-align: center;">PARTICULAR</th>
                    <th style="text-align: right;">AMOUNT (Rs.)</th>
                </tr>
                <tr>
                    <td><label>Model:</label> {{customerVehicle.modelName}}</td>
                    <td><label>Variant:</label> {{customerVehicle.variantName}}</td>
                    <td><label>VIN:</label> {{customerVehicle.vin}}</td>
                    <td><label>Engine Number:</label>  {{customerVehicle.engineNumber}}</td>
                    <td colspan="2"><label>Color:</label> {{customerVehicle.color}}</td>
                    <td></td>
                </tr>
            </thead>
        </table>
    </div>
    <div class="row margin-lr-none">
        <div class="col-sm-7">
            <img *ngIf="customerVehicle && customerVehicle.vehicleImages && customerVehicle.vehicleImages[0]" src="{{customerVehicle.vehicleImages[0].downloadUrl}}" style="width: 100%;">
        </div>
        <div class="col-sm-5">
            <div class="row">
                <div class="col-sm-8" style="text-align: right;">
                    <label>Price</label>&nbsp;:
                </div>
                <div class="col-sm-4" style="text-align: right;"><span class="fa fa-inr"></span>&nbsp;{{customerVehicle.salePrice | number}}</div>
            </div>

            <div class="row">
                <div class="col-sm-8" style="text-align: right;">
                    <label>Total Amount Received</label>&nbsp;:
                </div>
                <div class="col-sm-4" style="text-align: right;"><span class="fa fa-inr"></span>&nbsp;{{bookingObj.amountReceived | number}}</div>
            </div>

            <div class="row">
                <div class="col-sm-8" style="text-align: right;">
                    <label>Balance</label>&nbsp;:
                </div>
                <div class="col-sm-4" style="text-align: right;"><span class="fa fa-inr"></span>&nbsp;{{bookingObj.balancePayment | number}}</div>
            </div>
        </div>
    </div>
    <div class="row" style="margin: 0px;">
        <div class="col-sm-12">
            <table class="table">
                <tr>
                    <th style="width: 10%;">
                       <label>RUPEES</label> 
                    </th>
                    <td>
                        
                    </td>
                </tr>
                <tr>
                    <th colspan="2">To make adjustment to the invoice, Please go to <a href="javascript:void(0)" [routerLink]="['/home/leads-booking']">Booking form</a></th>
                </tr>
            </table>
        </div>
    </div>
    <div class="row" style="margin: 0px;">
        <div class="col-sm-6 form-group" style="padding: 0px;">
            <div class="col-sm-12 invoiveMargin"><b>PAN#&nbsp;</b> {{user.pan}}</div>
            <div class="col-sm-12 invoiveMargin"><b>TAN#&nbsp;</b> {{user.tan}}</div>
            <div class="col-sm-12 invoiveMargin"><b>GSTIN&nbsp; {{user.gstin}}</b></div>
            <div class="col-sm-12 invoiveMargin"><b>SERVICE TAX Number&nbsp;</b> ST/MMM/4565/DSF444</div>
            <div class="col-sm-12 invoiveMargin"><b>TERMS & CONDITIONS</b></div>

            <div class="col-sm-12 invoiveMargin">
                <div style="width: 25px;float: left;"><b>1.</b></div>
                <div style="width: calc(100% - 25px);float: left;">
                    <div>Amount payable by P.O/D.D. in favour of </div>
                    <div>{{user.businessName}} And payable at {{user.city}}. </div>
                </div>
            </div>

            <div class="col-sm-12 invoiveMargin">
                <div style="width: 25px;float: left;"><b>2.</b></div>
                <div style="width: calc(100% - 25px);float: left;">
                    <div>{{user.businessName}} reserves the right to change the booking procedure, </div>
                    <div>equipments, specifications, prices and discontinue models without notice. </div>
                </div>
            </div>

            <div class="col-sm-12 invoiveMargin">
                <div style="width: 25px;float: left;"><b>3.</b></div>
                <div style="width: calc(100% - 25px);float: left;">
                    <div>Price and statutory levies, prevailing at the time of delivery are applicable,</div>
                    <div>irrespective of when initial payment is made.</div>
                </div>
            </div>

            <div class="col-sm-12 invoiveMargin">
                <div style="width: 25px;float: left;"><b>4.</b></div>
                <div style="width: calc(100% - 25px);float: left;">
                    Forcemeasure clause would be applicable to all deliveries.
                </div>
            </div>
            <div class="col-sm-12 invoiveMargin">
                <div style="width: 25px;float: left;"><b>5.</b></div>
                <div style="width: calc(100% - 25px);float: left;">
                    Booking Cancellation Charges Applicable.
                </div>
            </div>
        </div>
        <div class="col-sm-6 form-group text-center" style="padding-top: 40px;">
            <div class="col-sm-12">
                <div style="height: 100px; ">
                    <h4> For {{user.businessName}}</h4>
                </div>
                <div>
                    <h4>Authorized Signatory</h4>
                </div>
            </div>
            <div clas="col-sm-offset-4 col-sm-5">
                <label style="width: 100px;float: left;margin-top: 10px;text-align: right;padding-right: 20px;">SC</label>
                <mat-form-field style="width:calc(100% - 100px);float: left;">
                    <input matInput autocomplete="off">
                </mat-form-field>
            </div>
            <div clas="col-sm-offset-4 col-sm-5">
                <label style="width: 100px;float: left;margin-top: 10px;text-align: right;padding-right: 20px;">Mobile#</label>
                <mat-form-field style="width:calc(100% - 100px);float: left;">
                    <input matInput autocomplete="off">
                </mat-form-field>
            </div>

        </div>
        <div class="col-sm-12">
            <h4 style="padding-left: 30px;text-align: center;">
                <b>[Godown : {{user.businessName}}]</b>
            </h4>
            
        </div>
        <div class="row" style="margin: 0px;width: 100%;">
            <div class="col-sm-12">
                <mat-checkbox (change)="checkFullPayement()" [(ngModel)]="mainObj.isFullAmountPaid">
                    Full Amount Paid
                </mat-checkbox>
            </div>
        </div>
        <div class="row" style="margin: 0px;width: 100%;">
            <div class="col-sm-12" style="text-align: center;">
                <button mat-raised-button [disabled]="disableBtn" class="primary-btn" (click)="saveProforma()" color="primary" type="submit">Save</button>
            </div>
        </div>
    </div>
    
</div>