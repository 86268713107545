import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private afdb: AngularFireDatabase,
  ) { }

  get = (nodepath: string) => {
    return this.afdb.database.ref(nodepath).once('value');
  }
  firbaseUpdateData(node: string, value: any) {
    return this.afdb.object(node).update(value);
  }
  firebasePushData(node: string, value: any) {
    return this.afdb.list(node).push(value);
  }

  getFirebaseListTypeValues(node: string) {
    return this.afdb.list(node).valueChanges();
  }

  getFirebaseListLimit(node: string, limit: number) {
    return this.afdb.list(node, ref => ref.limitToFirst(limit)).valueChanges();
  }
  getFirebaseListLimitLast(node: string, limit: number) {
    return this.afdb.list(node, ref => ref.limitToLast(limit)).valueChanges();
  }

  getFirebaseObjectTypeValues(node: string): Observable<any> {
    return this.afdb.object(node).valueChanges();
  }

  firebaseUpdateNodeByPush(rootNode: string, obj: any) {
    this.afdb.list(rootNode).push(obj);
  }
  firebaseUpdateNodeByPushAndReturnKey(rootNode: string, obj: any, objRef: any, callbackResp: any) {
    this.afdb.list(rootNode).push(obj).then(res => {
      callbackResp({
        objRef: objRef,
        response: res
      })
    })
  }

  firebaseUpdateNodeByPushAndUpdatePushKey(rootNode: string, obj: any, objRef: any, callbackResp: any) {
    this.afdb.list(rootNode).push(obj).then(res => {
      this.firbaseUpdateData(rootNode + '/' + res.key, { id: res.key })
      callbackResp({
        objRef: objRef,
        response: res
      })
    })
  }

  addObjAnPushKey(rootNode: string, obj: any, objRef: any, callbackResp: any) {
    this.afdb.list(rootNode).push(obj).then(res => {
      this.firbaseUpdateData(rootNode + '/' + res.key, { uid: res.key })
      callbackResp({
        objRef: objRef,
        response: res
      })
    })
  }

  firebaseDeleteNode(rootNode: string) {
    return this.afdb.object(rootNode).set(null);
  }

  firbaseSetData(node: string, value: any) {
    return this.afdb.object(node).set(value);
  }

  getMapObject(dataList, key) {
    var lookup = {};
    for (var i in dataList) {

      var tempArr = [];
      if (lookup[dataList[i][key]] != undefined) {
        tempArr = lookup[dataList[i][key]];
        tempArr.push(dataList[i]);
        lookup[dataList[i][key]] = tempArr;
      } else {
        tempArr.push(dataList[i]);
        lookup[dataList[i][key]] = tempArr;
      }
    }
    return lookup;
  }

  sortDataByWord(_dataList, key) {

    var sortedDataList = _dataList.sort(function (val1, val2) {
      var dataA = val1[key] != undefined && val1[key] != null ? val1[key].trim().toLowerCase() : val1[key] != undefined ? val1[key] : val1.name;
      // ignore upper and lowercase
      var dataB = val2[key] != undefined && val2[key] != null ? val2[key].trim().toLowerCase() : val2[key] != undefined ? val2[key] : val2.name;
      // ignore upper and lowercase
      if (dataA < dataB) {
        return -1;
      }
      if (dataA > dataB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    return sortedDataList;
  }

  sortDataByNumber(_dataList, key) {

    _dataList.sort(function (val1, val2) {
      var dataA = val1[key];
      var dataB = val2[key];

      return dataA - dataB;
    });

    return _dataList;
  }

  sortNumber(_dataList, order) {

    _dataList.sort(function (val1, val2) {

      if (order != undefined && order == "DESC") {
        return val2 - val1;
      } else {
        return val1 - val2;
      }

    });

    return _dataList;
  }


  removeDuplicateValue(tempArray, key) {

    var new_arr = [];
    var lookup = {};

    for (var i in tempArray) {
      lookup[tempArray[i][key]] = tempArray[i];
    }

    for (i in lookup) {
      new_arr.push(lookup[i]);
    }

    return new_arr;

  }

  convertDateFormate(localeString: any) {

    var a = new Date(localeString);

    var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    var year = a.getFullYear();
    var month = months[a.getMonth()];

    var date = a.getDate();
    var dataFormate = '';
    var monthFormate = '';

    if (date < 10) {
      dataFormate = '0' + date;
    } else {
      dataFormate = '' + date;
    }

    if (month < 10) {
      monthFormate = '0' + month;
    } else {
      monthFormate = '' + month;
    }

    var finalMinDate = monthFormate + "/" + dataFormate + "/" + year;
    return finalMinDate;
  }

  returnDayMonthOnly(localeString: any) {
    var a = new Date(localeString);

    var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];

    var date = a.getDate();
    var dataFormate = '';
    var monthFormate = '';

    if (date < 10) {
      dataFormate = '0' + date;
    } else {
      dataFormate = '' + date;
    }

    if (month < 10) {
      monthFormate = '0' + month;
    } else {
      monthFormate = '' + month;
    }

    var finalMinDate = mS[Number(monthFormate) - 1] + " " + dataFormate;
    return finalMinDate;
  }
  sortByDateTime(_dataList, key) {

    _dataList.sort(function (val1, val2) {

      const dataA = val1[key] != null ? new Date(val1[key]) : val1[key];
      const dataB = val2[key] != null ? new Date(val2[key]) : val2[key];

      if (dataA > dataB) {
        return -1;
      }
      if (dataA < dataB) {
        return 1;
      }
      return 0;
    });

    return _dataList;
  }

  getOrderByChild(path, key, value): Observable<any> {
    return this.afdb.list(path, ref => ref.orderByChild(key).equalTo(value)).valueChanges();
  }

  searchByEquals = (nodepath: string, searchKey: string, searchValue: any) => {
    return this.afdb.database.ref(nodepath).orderByChild(searchKey).equalTo(searchValue).once('value');
  }
}
