import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { AlertService } from 'ngx-alerts';
import { UtilityService } from 'src/app/service/utility.service';
import { FirebaseService } from 'src/app/service/firebase.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InvoiceService } from '../invoice-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-recipet',
  templateUrl: './payment-recipet.component.html',
  styleUrls: ['./payment-recipet.component.css']
})
export class PaymentRecipetComponent implements OnInit {

  user : any = {};
  currentLead: any = {};
  mainList : any = [];
  bookingItems : any = {};
  customerVehicle: any = {};
  modeOfPaymentArr: any = [];
  cardType: any = ['Credit', 'Debit'];
  cardName: any = ['VISA', 'Master', 'Diners', 'Amex'];
  groupId: any = '';
  dealerId: any = '';
  customerId : any = '';
  nodePath: any = '';
  disableBtn: boolean = false;
  bookingObj : any = {};
  balancePayment = 0;
  constructor(
    private commonService: CommonService,
    private alertService : AlertService,
    private utilService : UtilityService,
    private firebaseService: FirebaseService,
    private loaderService: NgxUiLoaderService,
    private invoiceService: InvoiceService,
    private router : Router
    ) {
      this.modeOfPaymentArr = ['Cash', 'Cheque / DD', 'Online Transfer', 'Other', 'Card'];
    }

  ngOnInit(): void {
   this.init()
  }

  public init() {
    this.user = this.commonService.getUserInfo();
    this.currentLead = this.commonService.getCurrentLead();
    if(!this.currentLead) {
      this.currentLead = {};
      this.alertService.danger('Please select customer to continue');
      this.router.navigate(['/home/leads-list']);
      return;
    }
    this.loaderService.startBackground();
    this.customerId = this.currentLead.pushKey;
    this.groupId = this.utilService.getGroupId();
    this.dealerId = this.utilService.getDealerId();
    this.customerVehicle = this.currentLead ? this.currentLead['vehicle'] : {};
    this.bookingObj = this.currentLead ? this.currentLead['booking'] : {};
    this.balancePayment = this.bookingObj ? this.bookingObj['balancePayment'] : 0;
    this.getPaymentData();
  }

  getPaymentData() {
    this.nodePath = 'invoice/payment_reciept/'+this.groupId+'/'+this.dealerId+'/'+this.customerId;
    this.firebaseService.getFirebaseListTypeValues(this.nodePath).subscribe(res=> {
      if(res && res.length > 0) {
        this.mainList = res;
      } else {
        this.makeList(); //from booking
      }
      this.loaderService.stopBackground();
    })
  }

  makeList() {
    var bookingData = this.currentLead['booking'];
    var obj = {'invoiceDate' : bookingData.bookingDate, 'modeOfPayment' : bookingData.purchaseMode, 'amount' : bookingData.amountReceived ? Number(bookingData.amountReceived) : 0,
        'fromBooking' : true, 'chequeNo' : bookingData.chequeNo, 'chequeDate' : bookingData.chequeDate, 'purchaseModeOther' : bookingData.purchaseModeOther
        ,'cardType' : bookingData.cardType, 'cardName' : bookingData.cardName, 'onlineBankName' : bookingData.onlineBankName, 'checkBankName' : bookingData.checkBankName
        ,'onlineTransactionNo' : bookingData.onlineTransactionNo, 'cardTransactionNo' : bookingData.cardTransactionNo, 'receiptId' : bookingData.receiptNumber,
        'createdBy' : this.user.uid, 'updatedBy' :  this.user.uid
    };
    this.mainList.push(obj);
  }

  pushObj() {
    var obj = {};
    obj['invoiceDate'] = new Date();
    obj['createdBy'] =  this.user.uid;
    obj['updatedBy'] =  this.user.uid;
    this.mainList.push(obj);
  }

  removeItem(index) {
    this.mainList.splice(index, 1);
  }

  formatPaymentAmount() {
    if(!this.mainList) {
      return;
    }
    this.mainList.forEach(item=> {
      if(item && item.amount) {
        if(typeof item.amount == 'string') {
          item.amount = item.amount.replace(/\,/g,'');
          item.amount = Number(item.amount);
        }
      }
    })
  }

  validateFields() {
    var message = '';
    for(var i=0;i<this.mainList.length;i++) {
      var obj = this.mainList[i];
      if(obj && !obj.invoiceDate) {
        message = 'Please enter invoice date to continue';
        break;
      }
      if(obj && !obj.modeOfPayment) {
        message = 'Please select mode of payment to continue';
        break;
      }
      if(obj && !obj.amount) {
        message = 'Please enter amount to continue';
        break;
      }
    }
    return message;
  }

  saveInfo() {
    this.formatPaymentAmount();
    this.disableBtn = true;
    var message = this.validateFields();
    if(message) {
      this.alertService.danger(message);
      return;
    }
    this.loaderService.startBackground();
    this.invoiceService.savePaymentRecipt(this.mainList, this.groupId, this.dealerId, this.customerId).subscribe(res=> {
      if(res && res['status'] == true) {
        sessionStorage.currentLead = JSON.stringify(res['content']);
        this.alertService.info('Record saved successfully');
      }
      this.disableBtn = false;
      this.loaderService.stopBackground();
      this.getPaymentData();
      this.init();
    })
  }

  onlyDigits(val) {
    return this.utilService.onlyDigits(val);
  }

}
