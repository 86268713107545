import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-e-signature',
  templateUrl: './e-signature.component.html',
  styleUrls: ['./e-signature.component.css']
})
export class ESignatureComponent implements OnInit {

  signatureImage;

  constructor(public dialogRef: MatDialogRef<ESignatureComponent>) { }

  ngOnInit() {
  }

  showImage(data) {
    //this.signatureImage = data;
    this.dialogRef.close(data);
  }

}
