import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/service/common.service';
import { LeadsService } from '../leads.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FirebaseService } from 'src/app/service/firebase.service';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.css']
})
export class LeadsListComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  user: any;
  customerList : any;
  displayedColumns: string[] = ['dealNumber', 'name', 'vehicle', 'variant',  'lastupdatedDate', 'status', 'action'];
  dataSource = new MatTableDataSource();
  constructor(private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    public ngxService: NgxUiLoaderService, 
    private fireDB: FirebaseService) { }

  ngOnInit() {
    this.user = this.commonService.getUserInfo()
    this.getCustomers();
  }

  getCustomers = ()=> {
    this.customerList = [];
    this.dataSource = new MatTableDataSource();
    this.ngxService.startBackground();
    let node = "leads_info/"+ this.commonService.getGroupUId()+"/"+this.commonService.getDealerUId();
    this.fireDB.getFirebaseObjectTypeValues(node).subscribe(res => {
      this.ngxService.stopBackground();
      if(res) {
        console.log(res)
        this.customerList = Object.values(res);
        this.customerList = this.customerList.sort(function(a, b) {
          if(a>b) {
            return 1
          } else {
            return -1;
          }
        })
        this.dataSource = new MatTableDataSource(this.customerList);
        this.dataSource.sort = this.sort;
      }
    }, err => {
      this.ngxService.stopBackground();
      console.log(err)
    });
  }

  selectCustomer = (customer) => {
    // delete customer['selectedVehicle'];
    // this.uilServ.setSelectedCustomer(customer, vehicle);
    this.commonService.setCurrentLead(customer);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/home/leads-summary']));
  }

  getStatusDisplay = (status, item) => {
    if(status == "enquiry") {
      return "Enquiry";
    } else if(status == "followup") {
      return "Follow-up";
    } else if(status == "testdrive") {
      return "Test Drive";
    } else if(status == "booking") {
      return "Booking";
    } else if(status == "billing") {
      return "Billed, "+ item.billingStatus;
    } else if(status == "preApproved") {
      return "Pre-Approved";
    } else {
      return status;
    }
  }
}
