import { Injectable } from '@angular/core';
import { PurchaseBean } from '../models/purchase-bean';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  purchaseRef: any = {};
  constructor() {
    this.purchaseRef = new PurchaseBean();
  }

  getLoginUser() {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  getGroupId() {
    var user = this.getLoginUser();
    if (user['dealerAdmin'] == true) {
      if (user && user['group']) {
        var group = user['group'];
        return group['uid'];
      }
      return '';
    } else if (user['groupAdmin'] == true) {
      return user['uid'];
    }
    return '';
  }

  getDealerId() {
    var user = this.getLoginUser();
    if (user['dealerAdmin'] == true) {
      if (user && user['uid']) {
        return user['uid'];
      }
    }
    return '';
  }

  isDealerLogin() {
    var user = this.getLoginUser();
    if (user['dealerAdmin'] == true) {
      return true;
    }
    return false;
  }

  isGroupLogin() {
    var user = this.getLoginUser();
    if (user['groupAdmin'] == true) {
      return true;
    }
    return false;
  }

  setcustomerInformation = (data: any) => {
    this.purchaseRef.setcustomerInformation(data);
  }

  getcustomerInformation() {
    return this.purchaseRef.getcustomerInformation();
  }

  setvehicleInformation = (data: any) => {
    this.purchaseRef.setvehicleInformation(data);
  }

  getvehicleInformation() {
    var dataObj = this.purchaseRef.getvehicleInformation();
    if (dataObj && Object.keys(dataObj).length > 0) {
      return dataObj;
    }
    return { 'vehicleImages': [] };
  }

  setdocumentInformation = (vehicleImages: any) => {
    this.purchaseRef.setdocumentInformation(vehicleImages);
  }

  getdocumentInformation = () => {
    var dataObj = this.purchaseRef.getdocumentInformation()
    if (dataObj && Object.keys(dataObj).length > 0) {
      return dataObj;
    }
    return { 'customerDocuments': {}, 'vehicleDocuments': {} };
  }

  clearPurchaseInformation = () => {
    sessionStorage.removeItem(environment.CUSTOMER_INFO);
    sessionStorage.removeItem(environment.DEFAULT_VEHICLE);
  }

  onlyDigits(val) {
    if (isNaN(val)) {
			//toast('Only Digits');
			if (val.length > 1) {
			    return (val.substr(0, val.length - 1));
			}
			return null;
		}
		return val;
  }
}
