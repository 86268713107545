import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.isAuthenticated()) {
      this.getOut();
      return false;
    }
    return true;
  }
  public isAuthenticated(): boolean {
    return sessionStorage.getItem('user') != null;
  }
  /**
   * Temparary method for authenticated access only,
   *  merge with above method on final release of product.
   */
  // public isValidated(): boolean {
  //   return this.local.get('user').validated == true;
  // }
  getOut() {
    sessionStorage.removeItem('user');
    this.router.navigate(['login']);
  }
  setSession(user: any) {
    sessionStorage.setItem('user', user);
  }
}
