import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UtilityService } from './utility.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class FireAuthService {

  constructor(private fireAuth: AngularFireAuth, 
    private router : Router, 
    private utilservice : UtilityService,
    private commonService : CommonService
    ) { }

  login = (email, password) => {
    return this.fireAuth.signInWithEmailAndPassword(email, password);
  }

  logout = () => {
    this.fireAuth.signOut();
    sessionStorage.removeItem('user');
    this.utilservice.clearPurchaseInformation();
    this.router.navigate(['login']);
  }

  createUser = (admin: any, password: string) => {
    return this.fireAuth.createUserWithEmailAndPassword(admin.email, password);
  }

  resolveMenuOnRefresh() {
    const user = this.commonService.getUserInfo();
    if(user && Object.keys(user).length > 0) {
      if(user['dealerAdmin'] == true) {
        this.router.navigate(['/home/leads-list']);
      }
    }
  }
  
}
