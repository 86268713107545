<div class="row" style="margin:0px;padding:0px">
    <div class="col-sm-10" style="text-align: left;margin-left: -15px;">
        <h4 style="margin-top: 10px;">eSignature</h4>
    </div>
    <div class="col-sm-2" style="text-align: right;">
        <button mat-icon-button mat-dialog-close style="margin-right: -40px;">
            <mat-icon mat-list-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="row" style="margin:0px;padding:0px">
    <ng-signature-pad doneButtonText="Done" format="base64" width="500" height="200" (done)="showImage($event)">
    </ng-signature-pad>
</div>