<div style="padding-left: 0px;">
  <div fxFlex="100">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutGap.sm="0px">
          <mat-card [class.mat-elevation-z6]="true"  fxFlex="70" style="padding: 0px; background-color: #000; border-radius: 0px;">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" *ngIf="vehicleDetails.vehicleInformation">
              <!-- <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol> -->
              <div class="carousel-inner">
                <div class="carousel-item" *ngFor="let item of vehicleDetails.vehicleInformation.vehicleImages; let i = index;" [ngClass]="{'active': i == 0}">
                  <img id="card-img"  class="img-fluid" style="height: 80vh;" [src]="item.downloadUrl"/>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </mat-card>
          <mat-card [class.mat-elevation-z6]="true"  fxFlex="30" style="border-radius: 0px; padding: 0px;">
            <mat-card-header>
              <div fxFlex="100">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2px" fxLayoutGap.sm="0px">
                      <div fxLayoutAlign="end end" fxFlex>
                        <button mat-mini-fab color="warn" style="font-size: 16px;" [routerLink]="['/home/allotment-vehicle-list']">
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                  </div>
              </div>
          </mat-card-header>
            <mat-card-content style="padding: 0px 16px;">
                <ng-container *ngIf="vehicleDetails.vehicleInformation">
                  <div style="font-size: 20px; margin-bottom: 10px;">
                    <b>{{vehicleDetails.vehicleInformation.makeName}} &nbsp;{{vehicleDetails.vehicleInformation.modelName}}&nbsp;{{vehicleDetails.vehicleInformation.submodelName}}</b>
                  </div>
                  <h4 style="margin-bottom: 5px;">{{vehicleDetails.vehicleInformation.variantName}}</h4>
                  <h4 *ngIf="vehicleDetails.vehicleInformation.vin" style="margin-bottom: 5px;">VIN:&nbsp;{{vehicleDetails.vehicleInformation.vin}}</h4>
                  <h4 *ngIf="vehicleDetails.vehicleInformation.km">
                    <i class="fa fa-tachometer" aria-hidden="true"></i>&nbsp; {{vehicleDetails.vehicleInformation.km | number : '1.0-2'}}&nbsp;KM
                </h4>
                  <div style="font-size: 22px; margin-top: 20px;" *ngIf="vehicleDetails.vehicleInformation.salePrice"><i class="fa fa-inr"></i>&nbsp;{{vehicleDetails.vehicleInformation.salePrice  | number : '1.0-2' }}</div>
              </ng-container>
              <div style="height: 50px;"></div>
              <div>
                <button mat-raised-button color="primary" style="width: 100%; text-transform: uppercase;" [disabled]="!vehicleDetails.vehicleInformation" (click)="allotVehicle()">Allot to Customer</button>
              </div>
            </mat-card-content>
        </mat-card>
      </div>
</div>