<div style="padding: 0px 16px !important;"> 
    <div fxFlex="100">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
            <div fxFlex>
                <!-- <mat-form-field fxFlex="40">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="search($event.target.value)" #input>
                  </mat-form-field> -->
            </div>
            <div>
                <!-- <button mat-button [matMenuTriggerFor]="menu">
                    <i class="fas fa-sort-amount-down-alt"></i> &nbsp;SORT
                </button> -->
                <mat-menu #menu="matMenu">
                    <button mat-menu-item disabled>Ex-Showroom</button>
                    <button mat-menu-item >
                        <i class="fas fa-sort-numeric-down"></i>&nbsp;Low to high
                    </button>
                    <button mat-menu-item >
                        <i class="fas fa-sort-numeric-down-alt"></i>&nbsp;High
                                        to Low
                    </button>
                </mat-menu>
            </div>
            <div>
                <!-- <button mat-button [matMenuTriggerFor]="view">
                    <i class="fa fa-bars"></i> &nbsp;VIEW
                </button>
                <mat-menu #view="matMenu">
                <button [ngClass]="{'active-menu':  viewType == 'List'}" mat-menu-item (click)="viewType='List'">
                    <i class="fa fa-list"></i>&nbsp;&nbsp;List
                </button>
                <mat-divider></mat-divider>
                <button [ngClass]="{'active-menu':  viewType == 'Grid'}" mat-menu-item (click)="viewType='Grid'">
                    <i class="fa fa-th"></i>&nbsp;&nbsp;Grid</button>
                </mat-menu> -->
                <div fxLayout="row" fxLayout.xs="column">
                    <button [ngClass]="{'active-menu':  viewType == 'List'}" mat-menu-item (click)="viewType='List'">
                        <i class="fa fa-list"></i>
                    </button>
                    <mat-divider></mat-divider>
                    <button [ngClass]="{'active-menu':  viewType == 'Grid'}" mat-menu-item (click)="viewType='Grid'">
                        <i class="fa fa-th"></i></button>
                        <mat-divider></mat-divider>
                        <button mat-button (click)="filterSidenav.toggle()" style="outline: none; padding: 0; border: none;">
                            <span class="material-icons">
                                filter_alt
                                </span>
                        </button>
                </div>
            </div>
            
        </div>
        <mat-drawer-container autosize >
            <mat-drawer #filterSidenav class="filter-sidenav" mode="side" position="end">
                <!-- <button type="button" mat-button (click)="drawer.toggle()" style="float: right;">
                    <span class="material-icons">
                        highlight_off
                        </span>
                  </button> 
              -->
             <div style="height: 10px;"></div>
             <h3><b>Refine Your Result</b></h3>
             <div fxLayout="row" fxLayoutAlign="end center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin: 5px;">
                <div fxFlex="1">&nbsp;</div>
                <button fxFlex="49" mat-raised-button color="warn" (click)="clearFilter()">Clear</button>
                <button fxFlex="49" mat-raised-button color="primary" (click)="applyFilter()">Apply</button>
                <div fxFlex="1">&nbsp;</div>
            </div>
               <mat-accordion class="example-headers-align" multi>
                <!--      <mat-expansion-panel class="parametersPanel" >
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                        Range
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-chip-list class="example-chip">
                            <mat-chip class="mat-standard-chip" *ngFor="let label of rangeLabel">
                            {{label}}
                            </mat-chip>
                        </mat-chip-list>
                    </mat-expansion-panel> -->
                    <mat-expansion-panel class="parametersPanel" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                        Select Budget
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-chip-list class="example-chip">
                            <mat-chip class="mat-standard-chip" *ngFor="let item of budgetLabel" (click)="item.clicked = !item.clicked" [ngClass]="{'active-chips' : item.clicked, 'non-active-chips': !item.clicked}">
                            {{item.name}}
                            </mat-chip>
                        </mat-chip-list>
                        <div style="height: 5px;"></div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="parametersPanel" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                        Body Type
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-chip-list class="example-chip">
                            <mat-chip class="mat-standard-chip" *ngFor="let item of bodyTypeData" (click)="item.clicked = !item.clicked" [ngClass]="{'active-chips' : item.clicked, 'non-active-chips': !item.clicked}">
                            {{item.name}}&nbsp;<span *ngIf="item.count">({{item.count}})</span>
                            </mat-chip>
                        </mat-chip-list>
                        <div style="height: 5px;"></div>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="parametersPanel" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                            Brand
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="height: 150px; overflow: auto;">
                            <mat-selection-list #shoes>
                                <mat-list-option *ngFor="let item of makeData" checkboxPosition="before" color="primary" style="height: 40px; font-size: 14px;" (click)="item.clicked = !item.clicked">
                                  {{item.name}}&nbsp;({{item.count}})
                                </mat-list-option>
                              </mat-selection-list>
                        </div>
                    </mat-expansion-panel>
                    
                    <mat-expansion-panel class="parametersPanel">
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                            Owner
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-chip-list class="example-chip">
                            <mat-chip class="mat-standard-chip" *ngFor="let item of OwnerData" (click)="item.clicked = !item.clicked" [ngClass]="{'active-chips' : item.clicked, 'non-active-chips': !item.clicked}">
                            {{item.name}} Owner&nbsp;<span *ngIf="item.count">({{item.count}})</span>
                            </mat-chip>
                        </mat-chip-list>
                        <div style="height: 5px;"></div>
                    </mat-expansion-panel>
                    
                    <mat-expansion-panel class="parametersPanel">
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                                Kilometers Driven
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-chip-list class="example-chip">
                            <mat-chip class="mat-standard-chip" *ngFor="let item of kmDrivenData" (click)="item.clicked = !item.clicked" [ngClass]="{'active-chips' : item.clicked, 'non-active-chips': !item.clicked}">
                            {{item.name}}&nbsp;<span *ngIf="item.count">({{item.count}})</span>
                            </mat-chip>
                        </mat-chip-list>
                        <div style="height: 5px;"></div>
                    </mat-expansion-panel>
                    
                    <mat-expansion-panel class="parametersPanel">
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                        Fuel Type
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-chip-list class="example-chip">
                            <mat-chip class="mat-standard-chip" *ngFor="let item of fuelTypeData" (click)="item.clicked = !item.clicked" [ngClass]="{'active-chips' : item.clicked, 'non-active-chips': !item.clicked}">
                            {{item.name}}&nbsp;<span *ngIf="item.count">({{item.count}})</span>
                            </mat-chip>
                        </mat-chip-list>
                        <div style="height: 5px;"></div>
                    </mat-expansion-panel>
                       
                    <mat-expansion-panel class="parametersPanel">
                        <mat-expansion-panel-header [collapsedHeight]="'42px'" [expandedHeight]="'42px'">
                            <mat-panel-title>
                                Transmission
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-chip-list class="example-chip">
                            <mat-chip class="mat-standard-chip" *ngFor="let item of transmissionData" (click)="item.clicked = !item.clicked" [ngClass]="{'active-chips' : item.clicked, 'non-active-chips': !item.clicked}">
                            {{item.name}}&nbsp;<span *ngIf="item.count">({{item.count}})</span>
                            </mat-chip>
                        </mat-chip-list>
                        <div style="height: 5px;"></div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-drawer>
          </mat-drawer-container>
        <div [hidden]="true">
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div (click)="changeDataView(dataListCopy)" style="padding: 30px; cursor: pointer;">
                            <div class="num-vehicle">{{inventoryCount['total']}}</div>
                            <div class="sub-title">Total Vehicles</div>
                        </div>
                    </ng-template>
                </mat-tab>
                <!-- <mat-tab>
                    <ng-template mat-tab-label>
                        <div (click)="changeDataView(inventoryCount['bookedList'])" style="padding: 30px; cursor: pointer;">
                            <div class="num-vehicle">{{inventoryCount['booked']}}</div>
                            <div  class="sub-title">Booked</div>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div (click)="changeDataView(inventoryCount['freeList'])" style="padding: 30px; cursor: pointer;">
                            <div class="num-vehicle">{{inventoryCount['free']}}</div>
                            <div  class="sub-title">Free</div>
                        </div>
                    </ng-template>
                </mat-tab> -->
                <mat-tab *ngFor="let tab of headerCountList" >
                    <ng-template mat-tab-label >
                        <div (click)="changeDataView(tab.list)" style="padding: 30px; cursor: pointer;">
                            <b class="num-vehicle">{{tab['count']}}</b>
                            <div class="color-strip" [ngStyle]="{'background-color': tab.code}"></div>
                            <small class="sub-title">{{tab['color']}}</small>
                        </div>
                    </ng-template>
                </mat-tab>
              </mat-tab-group>
              
        </div>
        <div *ngIf="viewType == 'List'" fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px !important;">
            <!-- <table class="table table-bordered mb-0">
                <thead>
                    <tr>
                        <th class="vertical-align-top"><i class="fa fa-car"></i>&nbsp;Vehicle</th>
                        <th class="vertical-align-top" style="width: 20%">
                            <i class="fa fa-map-marker"></i>&nbsp;Stock Location
                        </th>
                        <th class="text-center" style="width: 15%">Ex-Showroom Price</th>
                        <th class="vertical-align-top text-center" style="width: 14%">Status</th>
                        <th class="vertical-align-top text-center" style="width: 7%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-pointer"
                        *ngFor="let item of dataList "
                        [ngClass]="{'selected-bg': selectedModel == item.model}">
                        <td>
                            <ng-container *ngIf="item.vehicleInformation">
                                <div>{{item.vehicleInformation.makeName}} &nbsp;{{item.vehicleInformation.modelName}}&nbsp;{{item.vehicleInformation.submodelName}}</div>
                                <div>{{item.vehicleInformation.variantName}}</div>
                                <div *ngIf="item.vehicleInformation.vin"><small> VIN:&nbsp;{{item.vehicleInformation.vin}}</small></div>
                            </ng-container>
                        </td>
                        <td style="width: 20%">
                            {{item.warehouse || 'Showroom'}}
                        </td>
                        <td class="text-right" style="width: 10%">
                            <ng-container *ngIf="item.vehicleInformation.salePrice">
                                <i class="fa fa-inr"></i>&nbsp;{{item.vehicleInformation.salePrice  | number : '1.0-2' }}
                            </ng-container>
                        </td>
                        <td style="width: 7%">
                            <ng-container *ngIf="item.vehicleInformation">
                                <div>{{item.vehicleInformation.status}}</div>
                            </ng-container>
                        </td>
                        <td class="text-center" style="width: 7%">
                                <button mat-button [matMenuTriggerFor]="action">
                                    <i class="fa fa-ellipsis-v"></i>
                                </button>
                                <mat-menu #action="matMenu">
                                    <button mat-menu-item (click)="edit(item.primaryDescriptionId)">
                                        <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                                    </button>
                                    <button mat-menu-item (click)="routeToVehicleDetails(item)">
                                        <i class="fa fa-check-circle"></i>&nbsp;Allot to Customer
                                    </button>
                                </mat-menu>
                        </td>
                    </tr>
                    <tr *ngIf="!dataList.length">
                        <td colspan="9" class="text-center">No record found</td>
                    </tr>
                </tbody>
            </table> -->
            <div fxFlex="100">
                <div *ngFor="let item of dataList " [hidden]="!item.vehicleInformation"  fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px !important;">
                    <mat-card style="padding: 0px;" fxFlex="100">
                        <div fxLayout="row">
                            <div fxFlex="30" >
                                <ng-container *ngIf="item.vehicleInformation && item.vehicleInformation.vehicleImages[0];else other_content">
                                    <img id="card-img" [src]="item.vehicleInformation.vehicleImages[0].downloadUrl"
                                    class="md-card-image cursor-pointer" alt="images">
                                </ng-container>
                                <ng-template #other_content>
                                    <img id="card-img" src="../../../../assets/images/no-image1.png"
                                    class="md-card-image cursor-pointer" alt="images">
                                </ng-template>
                            </div>
                            <div fxFlex="70" style="padding: 16px;">
                                <div  class="header-padding">
                                    <ng-container *ngIf="item.vehicleInformation">
                                        <div style="margin-bottom: 10px; font-size: 20px;">
                                            <strong>{{item.vehicleInformation.makeName}} &nbsp;{{item.vehicleInformation.modelName}}&nbsp;{{item.vehicleInformation.submodelName}}</strong>
                                        </div>
                                        <div>

                                             <div>{{item.vehicleInformation.year}}&nbsp;|&nbsp;{{item.vehicleInformation.fuelType}}&nbsp;|&nbsp;{{item.vehicleInformation.km ? (item.vehicleInformation.km | number : '1.0-2') : ""}}&nbsp;KM</div>

                                        </div>
                                        <h4 style="margin-bottom: 10px;">{{item.vehicleInformation.status ? item.vehicleInformation.status : "In Stock"}}</h4>

                                        <!-- <h4 *ngIf="item.vehicleInformation.km" style="margin-bottom: 10px;">
                                            <i class="fa fa-tachometer" aria-hidden="true"></i>&nbsp; {{item.vehicleInformation.km}}
                                        </h4> -->

                                        <!-- <h4 *ngIf="item.vehicleInformation.km" style="margin-bottom: 10px;">
                                            <i class="fa fa-tachometer" aria-hidden="true"></i>&nbsp; {{item.vehicleInformation.km | number : '1.0-2'}}
                                        </h4> -->

                                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                            <div fxFlex="50">
                                                <div>
                                                    <h4 *ngIf="item.vehicleInformation.salePrice" style="font-size: 20px;">
                                                        <i class="fa fa-inr"></i>&nbsp;{{item.vehicleInformation.salePrice  | number : '1.0-2' }}
                                                    </h4>
                                                </div>
                                            </div>
                                            <div fxFlex="50">
                                                <div *ngIf="!item.sales || item.sales && !item.sales.vehicleStatus" fxLayout="row" fxLayoutAlign="end center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                                                    <div fxFlex="1">&nbsp;</div>
                                                    <button fxFlex="49" mat-raised-button color="warn" (click)="routeToTestDrive()">Book a Test Drive</button>
                                                    <button fxFlex="49" mat-raised-button color="primary" (click)="routeToVehicleDetails(item)">Assign to Customer</button>
                                                    <div fxFlex="1">&nbsp;</div>
                                                </div>
                                                <div *ngIf="item.sales && item.sales.vehicleStatus" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                                                    <div fxFlex="1">&nbsp;</div>
                                                    <div fxFlex="98"  fxLayoutAlign="center center" style="padding: 8px;color: white;
                                                    background: linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255));">Booked</div>
                                                    <div fxFlex="1">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                 </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <!-- GRID VIEW -->
        <div *ngIf="viewType == 'Grid'" class="Roboto-font-family">
                <div class="row no-margin">
                  <div class="col-sm-4 col-lg-4 col-md-4 col-xl-4 noPadding"  *ngFor="let item of dataList" [hidden]="!item.vehicleInformation">
                    <ng-container *ngIf="item.vehicleInformation">
                        <mat-card class="card noPadding" [ngClass]="{'mat-elevation-z3' : !item.hovering, 'mat-elevation-z8' : item.hovering}"
                        (mouseover)="item.hovering=true" (mouseout)="item.hovering=false" >
                           <!-- <div  class="header-padding">
                               <div class="md-title title-font-size" *ngIf="item.vehicleInformation">
                                      {{item.vehicleInformation.makeName}} &nbsp;{{item.vehicleInformation.modelName}}
                                    <span class="pull-right" *ngIf="item.vehicleInformation.salePrice">
                                           <i class="fa fa-inr"></i>&nbsp;{{item.vehicleInformation.salePrice  | number : '1.0-2' }}
                                   </span>
                               </div>
                               <div>
                                   <small class="md-subhead subhead-font-size">
                                       <ng-container *ngIf="item.vehicleInformation">
                                           <div>{{item.vehicleInformation.submodelName}}&nbsp;{{item.vehicleInformation.fuelType}}&nbsp;{{item.vehicleInformation.bodyType}}</div>
                                       </ng-container>
                                   </small>
                               </div>
                           </div> -->
                           <mat-card-content style="margin-bottom: 0px; border-bottom: 1px solid#ccc;">
                               <ng-container *ngIf="item.vehicleInformation && item.vehicleInformation.vehicleImages[0];else other_content">
                                   <img id="card-img" [src]="item.vehicleInformation.vehicleImages[0].downloadUrl"
                                   class="md-card-image cursor-pointer" alt="images">
                               </ng-container>
                               <ng-template #other_content>
                                   <img id="card-img" src="../../../../assets/images/no-image1.png"
                                   class="md-card-image cursor-pointer" alt="images">
                               </ng-template>
                              
                           </mat-card-content>
                           <div style="height: 130px; padding: 10px;">
                               <ng-container *ngIf="item.vehicleInformation">
                                   <div style="margin-bottom: 10px; font-size: 20px;">
                                       <strong>{{item.vehicleInformation.makeName}} &nbsp;{{item.vehicleInformation.modelName}}&nbsp;{{item.vehicleInformation.submodelName}}</strong>
                                </div>
                                <div>

                                    <div>{{item.vehicleInformation.year}}&nbsp;|&nbsp;{{item.vehicleInformation.fuelType}}&nbsp;|&nbsp;{{item.vehicleInformation.km ? (item.vehicleInformation.km | number : '1.0-2') : ""}}&nbsp;KM</div>

                                    

                                </div>
                                <h5 style="margin-bottom: 8px">
                                    {{item.vehicleInformation.status ? item.vehicleInformation.status : "In Stock"}}

                                    <!-- <span class="pull-right" *ngIf="item.vehicleInformation.km">
                                        <i class="fa fa-tachometer" aria-hidden="true"></i>&nbsp; {{item.vehicleInformation.km}}
                                    </span> -->

                                    <!-- <span class="pull-right" *ngIf="item.vehicleInformation.km">
                                        <i class="fa fa-tachometer" aria-hidden="true"></i>&nbsp; {{item.vehicleInformation.km | number : '1.0-2'}}
                                    </span> -->

                                </h5>
                                <div>
                                    <h4 *ngIf="item.vehicleInformation.salePrice" style="font-size: 20px;">
                                        <i class="fa fa-inr"></i>&nbsp;{{item.vehicleInformation.salePrice  | number : '1.0-2' }}
                                    </h4>
                                </div>
                               </ng-container>
                            </div>
                               <div *ngIf="!item.sales ||  item.sales && !item.sales.vehicleStatus" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                                   <div fxFlex="1">&nbsp;</div>
                                   <button fxFlex="49" mat-raised-button color="warn"  (click)="routeToTestDrive()">Book a Test Drive</button>
                                   <button fxFlex="49" mat-raised-button color="primary" style="padding: 0px;" (click)="routeToVehicleDetails(item)">Assign to Customer</button>
                                   <div fxFlex="1">&nbsp;</div>
                               </div>
                               <div *ngIf="item.sales && item.sales.vehicleStatus" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                                    <div fxFlex="1">&nbsp;</div>
                                    <div fxFlex="98"  fxLayoutAlign="center center" style="padding: 8px;color: white;
                                    background: linear-gradient(60deg,rgb(17, 98, 179), rgb(30,144,255));">Booked</div>
                                    <div fxFlex="1">&nbsp;</div>
                                </div>
                               <!-- <div class="row no-margin">
                                   <div class="col-sm-1  noPadding">
                                       <div *ngIf="item.colorExterior" class="color-div" [ngStyle]="{'background-color': item.colorExterior.code}"></div>
                                   </div>
                                   <div class="col-sm-7 noPadding">&nbsp;
                                       <i class="fa fa-map-marker" *ngIf="item.dealerLocation"></i>&nbsp;
                                       {{item.dealerLocation}}
                                   </div>
                                   <div class="col-sm-4 text-right noPadding">
                                       <ng-container *ngIf="item.vehicleInformation">
                                           <div>{{item.vehicleInformation.status}}</div>
                                       </ng-container>
                                   </div>
                               </div> -->
                         </mat-card>
                    </ng-container>
                </div>
              </div>
        </div>
    </div>
    </div>