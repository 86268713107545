<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" class="hidden-print">
    <mat-toolbar class="custom-primary">
        <mat-toolbar-row>
            <div class="row" style="width: 100%;">
                <div class="col-sm-6" style="height: 26px">
                    <h4 style="font-size: 18px;" *ngIf="currentLead">
                        <div style="height: 23px;">
                            <span>{{currentLead['fullName']}}</span>
                            <span style="margin-left: 10px; cursor: pointer;"
                                (click)="sendSMS(currentLead)">{{currentLead['mobile']}}
                                <mat-icon
                                    style="vertical-align: middle;font-size: 22px;margin-left: 5px;margin-top: 3px;">
                                    textsms
                                </mat-icon>
                            </span>
                            <mat-icon title="Reset Lead"
                                style="vertical-align: middle;font-size: 22px;margin-left: 5px;margin-top: 3px;"
                                (click)="resetLead()"> remove_circle</mat-icon>
                        </div>
                        <div>
                            <span>{{vehicleInfo['year']}} {{vehicleInfo['makeName']}}
                                {{vehicleInfo['modelName']}}</span>
                        </div>
                    </h4>
                </div>
                <div class="col-sm-6" style="text-align: right;">
                    <button mat-raised-button class="search-btn" style="vertical-align: -7px;margin-right: 17px">
                        <span class="material-icons search-icon" style=" padding-right: 5px;" (click)="openDialog()">
                            person_search
                        </span>
                        <mat-form-field class="example-full-width"
                            style="margin-bottom: -1.25em; margin-top: -20px; width: 150px;">
                            <input matInput [(ngModel)]="searchValue" (keydown.enter)="openDialog()">
                        </mat-form-field>
                        <span class="material-icons search-icon-drop" (click)="openDialog()">
                            search
                        </span>
                    </button>
                    <div style="cursor: pointer;float: right;" mat-card-avatar class="example-header-image"
                        (click)="rightDrawer.toggle()">
                        &nbsp;
                    </div>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>