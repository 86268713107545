import { Component, OnInit, ViewChild, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { LeadsService } from '../leads.service';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { CardealerDbService } from 'src/app/firebase-service/cardealer-db.service';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-leads-testdrive',
  templateUrl: './leads-testdrive.component.html',
  styleUrls: ['./leads-testdrive.component.css']
})
export class LeadsTestdriveComponent implements OnInit {

  leads: any = { testdrive: {} };
  checkBoxData = [
    { name: 'Exterior Styling', excellent: false, good: false, poor: false },
    { name: 'Interior Appearance', excellent: false, good: false, poor: false },
    { name: 'Pick-up/ Acceleration', excellent: false, good: false, poor: false },
    { name: 'Ride comfort', excellent: false, good: false, poor: false },
    { name: 'Drive comfort', excellent: false, good: false, poor: false },
    { name: 'Leg room', excellent: false, good: false, poor: false },
    { name: 'Head room', excellent: false, good: false, poor: false },
    { name: 'Braking', excellent: false, good: false, poor: false },
    { name: 'Instrument visibility', excellent: false, good: false, poor: false },
    { name: 'Dashboard style', excellent: false, good: false, poor: false },
    { name: 'Fit and Finish', excellent: false, good: false, poor: false },
    { name: 'Air Conditioning', excellent: false, good: false, poor: false },
    { name: 'Overall quality feel', excellent: false, good: false, poor: false },
  ];
  drivenByArr = ['SALES CONSULTANT', 'CUSTOMER', 'DRIVER'];
  feedbackArr = ['excellent', 'good', 'poor'];
  uid: string;
  profileForm: FormGroup;
  currentUser: any = {};
  disableBtn: boolean = false;
  vehicle: any = {};
  employeeList: any = [];
  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('searchTo') searchToElementRef: ElementRef;
  mapDataObject: any = {};

  constructor(private commonService: CommonService, private alertService: AlertService
    , private leadsService: LeadsService, private ngxService: NgxUiLoaderService
    , private router: Router, private caredealerFireDB: CardealerDbService,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private route: ActivatedRoute,
    private cRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.profileForm = new FormGroup({
      pushKey: new FormControl(this.leads.pushKey, [
        Validators.required,
      ]),
      testdriveDate: new FormControl(this.leads.testdrive.testdriveDate, [
        Validators.required,
      ]),
    });
    this.leads.testdrive.feedback = this.leads.testdrive && this.leads.testdrive && this.leads.testdrive.feedback ? this.leads.testdrive.feedback : this.checkBoxData;
    this.leads.testdrive.feedbackCount = this.leads.testdrive.feedbackCount ? this.leads.testdrive.feedbackCount : {};
    this.currentUser = this.commonService.getUserInfo();
    let currentLead = this.commonService.getCurrentLead();
    if (!currentLead) {
      this.alertService.danger("Please select lead to continue");
    } else {
      this.ngxService.startBackground();
      this.leadsService.getLeads(currentLead.groupUId, currentLead.dealerUId, currentLead.pushKey).subscribe((res: any) => {
        this.ngxService.stopBackground();
        if (res.status) {
          this.leads = Object.assign(this.leads, res.content);
          this.commonService.setCurrentLead(this.leads);
          this.leads.testdrive = this.leads.testdrive ? this.leads.testdrive : {};
          this.leads.testdrive.feedback = this.leads.testdrive && this.leads.testdrive && this.leads.testdrive.feedback ? this.leads.testdrive.feedback : this.checkBoxData;
          this.leads.testdrive.feedbackCount = this.leads.testdrive.feedbackCount ? this.leads.testdrive.feedbackCount : { "excellent": "" };
          this.vehicle = this.leads.vehicle ? this.leads.vehicle : {};
          this.profileForm.get('pushKey').setValue(this.leads.pushKey);
          this.leads.testdrive.startingKM = this.leads.testdrive.startingKM ? this.leads.testdrive.startingKM : this.vehicle.km;
        }
      });
    }
    this.getEmployess();

    this.route.params.subscribe(params => {
      console.log(params)
      if (params.from && params.to) {
        this.leads.testdrive.from = params.from;
        this.leads.testdrive.to = params.to;
        this.getDistanceAndTime(params.from, params.to);
      }
    });
  }

  ngAfterViewInit() {
    this.findAdress();
  }

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.leads.testdrive.from = place.formatted_address;
          let fromObject = {};
          fromObject['name'] = place.formatted_address;
          fromObject['lat'] = place.geometry.location.lat();
          fromObject['lng'] = place.geometry.location.lng();
          this.mapDataObject.from = JSON.stringify(fromObject);
        });
      });
      let autocompletes = new google.maps.places.Autocomplete(this.searchToElementRef.nativeElement);
      autocompletes.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.ngxService.startBackground();
          let place: google.maps.places.PlaceResult = autocompletes.getPlace();
          this.leads.testdrive.to = place.formatted_address;
          this.getDistanceAndTime(this.leads.testdrive.from, this.leads.testdrive.to);
          let fromObject = {};
          fromObject['name'] = place.formatted_address;
          fromObject['lat'] = place.geometry.location.lat();
          fromObject['lng'] = place.geometry.location.lng();
          this.mapDataObject.to = JSON.stringify(fromObject);
        });
      });
    });
  }

  getDistanceAndTime(from, to): void {
    if (from && to) {
      let directionsService = new google.maps.DirectionsService();
      let directionObservable = Observable.create((observer) => {
        let request: any = {
          origin: from,
          destination: to,
          travelMode: 'DRIVING',
          drivingOptions: {
            departureTime: new Date(),
            trafficModel: "bestguess"
          }
        }
        directionsService.route(request, function (response, status) {
          if (status === google.maps.DirectionsStatus.OK) {
            let kms = response.routes[0].legs[0].distance.text;
            if (kms) {
              let kmsArr = kms.split(' ');
              if (kmsArr[0]) {
                kms = (Number(kmsArr[0].replace(/,/g, ''))) * 2 + ' ' + kmsArr[1];
              }
            }
            let time = response.routes[0].legs[0].duration.text;
            if (time) {
              var timeArr = time.split(' ');
              if (timeArr[0]) {
                time = (Number(timeArr[0])) * 2 + ' ' + timeArr[1];
              }
            }
            observer.next({ 'kms': kms, 'time': time });
          } else {
            alert('Google route unsuccesfull!');
          }
        });
      });
      directionObservable.subscribe((text: any) => {
        this.changeTimeAndHours(text);
      });
    }
  }

  changeTimeAndHours(text) {
    this.ngxService.stopBackground();
    this.leads.testdrive.kms = text.kms;
    this.leads.testdrive.time = text.time;
    this.cRef.detectChanges();
  }

  onSubmit = () => {
    if (!this.leads.testdrive) {
      this.alertService.warning('Date required');
    } else if (this.leads.testdrive && this.leads.testdrive.drivenBy && this.leads.testdrive.drivenBy.toUpperCase() == 'CUSTOMER' && !this.leads.testdrive.driverLicense) {
      this.alertService.warning('Customer driving license number required');
    } else if (this.leads.testdrive && this.leads.testdrive.endingKM && !this.leads.testdrive.startingKM) {
      this.alertService.warning('Starting KM required');
    } else if (this.leads.testdrive && !this.leads.testdrive.salesPersonUId) {
      this.alertService.warning('Please select sales person');
    } else {
      let timestamp = new Date().getTime();
      this.leads.lastupdatedBy = this.currentUser.empUId ? this.currentUser.empUId : this.currentUser.uid;
      this.leads.lastupdationDate = timestamp;
      if (this.leads.testdrive.testdriveDate) {
        this.leads.testdrive.testdrive_timestamp = new Date(this.leads.testdrive.testdriveDate).getTime();
      }
      if (this.leads.testdrive.driverName) {
        this.leads.testdrive.driverName = this.leads.testdrive.driverName ? this.leads.testdrive.driverName.toUpperCase() : '';
      }
      if (this.leads.testdrive.drivenBy) {
        this.leads.testdrive.drivenBy = this.leads.testdrive.drivenBy ? this.leads.testdrive.drivenBy.toUpperCase() : '';
      }
      if (this.leads.testdrive.driverLicense) {
        this.leads.testdrive.driverLicense = this.leads.testdrive.driverLicense ? this.leads.testdrive.driverLicense.toUpperCase() : '';
      }
      if (this.leads.testdrive.feedback) {
        this.leads.testdrive.feedback.forEach(element => {
          delete element['$$hashKey'];
        });
      }
      let commentsId = timestamp;
      if (this.leads.testdrive.remarks) {
        this.leads.comments[commentsId] = { 'comments': this.leads.testdrive.remarks, 'givenBy': this.leads.lastupdatedBy, 'timestamp': commentsId, 'status': 'testdrive', 'givenByName': (this.currentUser.firstName ? this.currentUser.firstName + ' ' : '') + '' + (this.currentUser.middleName ? this.currentUser.middleName + ' ' : '') + "" + (this.currentUser.lastName ? this.currentUser.lastName : '') };
        this.leads.testdrive.commentsId = commentsId;
      }
      //for recent activity
      this.leads.recentActivity = this.leads.recentActivity ? this.leads.recentActivity : {};
      this.leads.recentActivity[timestamp] = { 'comments': "Test drive process done", 'givenBy': this.leads.lastupdatedBy, 'timestamp': timestamp, 'status': 'enquiry', 'givenByName': (this.currentUser.firstName ? this.currentUser.firstName + ' ' : '') + '' + (this.currentUser.middleName ? this.currentUser.middleName + ' ' : '') + "" + (this.currentUser.lastName ? this.currentUser.lastName : '') };

      if (this.leads.status != 'billing' || this.leads.billingStatus != 'Delivered') {
        this.leads.status = this.leads.status && "enquiry" != this.leads.status && "followup" != this.leads.status ? this.leads.status : "testdrive";
      }
      this.leads.updatedBy = this.leads.updatedBy ? this.leads.updatedBy : {};
      this.leads.updatedBy[this.leads.lastupdatedBy] = { 'name': (this.currentUser.firstName ? this.currentUser.firstName + ' ' : '') + '' + (this.currentUser.middleName ? this.currentUser.middleName + ' ' : '') + "" + (this.currentUser.lastName ? this.currentUser.lastName : ''), 'timestamp': timestamp, 'uid': this.leads.lastupdatedBy };
      this.leads.comments = this.leads.comments ? this.leads.comments : {};
      this.disableBtn = true;
      this.ngxService.startBackground();
      this.leadsService.saveTestdrive(this.leads).subscribe((response: any) => {
        this.disableBtn = false;
        this.ngxService.stopBackground();
        if (response && response.status && response.content) {
          this.alertService.success('Record saved successfully');
          this.leads = response.content;
          this.commonService.setCurrentLead(this.leads);
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/home/leads-summary']));
        } else {
          this.alertService.warning('Unable to save. Please check and try again.');
        }
      }, (error) => {
        console.log(error);
        this.alertService.danger('Network connection not available. Please check and try again.');
        this.disableBtn = false;
        this.ngxService.stopBackground();
      });
    }

  }

  getEmployess = () => {
    let nodepath = 'employee' + "/" + this.commonService.getDealerUId();
    this.caredealerFireDB.getFirebaseListTypeValues(nodepath).subscribe(res => {
      this.employeeList = res;
    });
  }

  changeCheckbox = function (item, key, val) {
    this.feedbackArr.forEach(data => {
      if (data == key) {
        item[data] = val;
      } else {
        item[data] = false;
      }
    });
    this.countFeedback();
  };

  countFeedback = function () {
    this.leads.testdrive.feedbackCount = {};
    this.leads.testdrive.feedback.forEach(item => {
      this.leads.testdrive.feedbackCount = this.leads.testdrive.feedbackCount ? this.leads.testdrive.feedbackCount : {};
      this.feedbackArr.forEach(arr => {
        if (item[arr]) {
          this.leads.testdrive.feedbackCount[arr] = this.leads.testdrive.feedbackCount[arr] ? this.leads.testdrive.feedbackCount[arr] : 0;
          this.leads.testdrive.feedbackCount[arr] += 1;
        }
      });
    });
  };

  routeToAppointMent = () => {
    this.router.navigate(['home/leads-appointment']);
  }

  tdLocationSet = () => {
    if (this.leads.testdrive.tdRouteLocation == "Dealer Location") {
      this.leads.testdrive.from = this.currentUser.address + ", " + this.currentUser.city + ", " + this.currentUser.state
    } else {
      this.leads.testdrive.from = "";
    }
  }
  calculateDriven = () => {
    if (this.isNumber(this.leads.testdrive.endingKM) > this.isNumber(this.leads.testdrive.startingKM)) {
      this.leads.testdrive.driven = this.leads.testdrive.endingKM - this.leads.testdrive.startingKM;
    } else {
      this.leads.testdrive.driven = undefined;
    }
  }

  isNumber = (val) => {
    if (isNaN(val))
      return 0;
    else
      return Number(val);
  }
}
