import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';
import { AlertService } from 'ngx-alerts';
import { LeadsService } from '../leads.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FirebaseService } from 'src/app/service/firebase.service';
import { CardealerDbService } from 'src/app/firebase-service/cardealer-db.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leads-followup',
  templateUrl: './leads-followup.component.html',
  styleUrls: ['./leads-followup.component.css']
})
export class LeadsFollowupComponent implements OnInit {

  leads: any = {followup : {}};
  uid: string;
  profileForm: FormGroup;
  minEnquiryDate = new Date();
  currentUser : any = {};
  followup: any = {};
  followupMode: any ;
  followupTemplates: any  = [];
  disableBtn : boolean = false;
  isViewAll: boolean = false;
  allFollowup = [];

  constructor(private commonService: CommonService, private alertService: AlertService
    , private leadsService: LeadsService, private ngxService: NgxUiLoaderService
    , private fireService: FirebaseService
    , private cardealerFireDB: CardealerDbService, private router: Router) {}
  ngOnInit(): void {
    this.profileForm = new FormGroup({
      followupDate: new FormControl(this.followup.followupDate, [
        Validators.required,
      ]),
      followupMode: new FormControl(this.followup.followupMode, [
        Validators.required,
      ]),
      remark: new FormControl(this.followup.remark, [
        Validators.required,
      ]),
      pushKey: new FormControl(this.leads.pushKey, [
        Validators.required,
      ]),
    });
    this.currentUser = this.commonService.getUserInfo();
    let currentLead = this.commonService.getCurrentLead();
    if(!currentLead) {
      this.alertService.danger("Please select lead to continue");
    } else {
      this.ngxService.startBackground();
      this.leadsService.getLeads(currentLead.groupUId, currentLead.dealerUId, currentLead.pushKey).subscribe((res: any) =>{
        this.ngxService.stopBackground();
        if(res.status) {
          this.leads = Object.assign(this.leads, res.content);
          this.commonService.setCurrentLead(this.leads);
          this.leads.followup = this.leads.followup ? this.leads.followup : {};
          this.followup.followupDate = this.leads.followup ? (this.leads.followup.lastFollowup ? this.leads.followup.lastFollowup.nextFollowupDate : null) : this.leads.enquiry.nextFollowupDate;
          this.followup.followupDate = this.followup.followupDate ? this.followup.followupDate : new Date();
          this.allFollowup = this.leads.followup.allFollowup ? Object.values(this.leads.followup.allFollowup) : [];
          this.profileForm.get('pushKey').setValue(this.leads.pushKey);
        }
      });
      this.followupTemplates = [];
      this.cardealerFireDB.getFirebaseListTypeValues("group-setup/followup-templates/"+this.commonService.getGroupUId()).subscribe((res) => {
          if (res) {
              this.followupTemplates = res;
          }
      }, (err) => {
          console.log(err);
      });
      this.followupMode = ['Inbound Call', 'Outbound Call', 'Inbound Visit', 'Outbound Visit', 'Test Drive'];
    }
  }

  onSubmit = () => {
    if (!this.followup.followupDate) {
      this.alertService.warning('Follow-up date is required');
  } else if(!this.followup.reason) {
      this.alertService.warning('Follow-up Reason is required');
  } else  if(!this.followup.followupMode) {
      this.alertService.warning('Follow-up Mode is required');
  }else if (!this.followup.remark) {
      this.alertService.warning('Remark is required');
  } else {
      let timestamp = new Date().getTime();
      this.leads.lastupdatedBy = this.currentUser.empUId ? this.currentUser.empUId : this.currentUser.uid;
      this.leads.followup = this.leads.followup ? this.leads.followup : {};
      this.leads.comments = this.leads.comments ? this.leads.comments : {};
      if (this.followup.remark) {
          this.leads.comments[timestamp] = { 'comments': this.followup.remark, 'givenBy': this.leads.lastupdatedBy, 'timestamp': timestamp, 'status': 'followup', 'givenByName': (this.currentUser.firstName ? this.currentUser.firstName + ' ' : '') + '' + (this.currentUser.middleName ? this.currentUser.middleName + ' ' : '') + "" + (this.currentUser.lastName ? this.currentUser.lastName : '') };
          this.followup.commentsId = timestamp;
      }
       //for recent activity
       this.leads.recentActivity = this.leads.recentActivity ? this.leads.recentActivity : {};
       this.leads.recentActivity[timestamp] = {'comments': "Follow-up process done", 'givenBy': this.leads.lastupdatedBy, 'timestamp': timestamp, 'status':'enquiry', 'givenByName': (this.currentUser.firstName ? this.currentUser.firstName+' ' : '')+''+(this.currentUser.middleName? this.currentUser.middleName+' ': '')+""+(this.currentUser.lastName ? this.currentUser.lastName : '')};

      if(!this.leads.followup) {
          this.followup.followup_timestamp = new Date(this.followup.followupDate).getTime();
      }
      if (this.followup.followupDate && !this.followup.followup_timestamp) {
          this.followup.followup_timestamp = this.followup.nextFollowupDate ? new Date(this.followup.nextFollowupDate).getTime() :  new Date(this.followup.followupDate).getTime();
      }
      this.leads.followup.lastFollowup = this.followup;
      this.leads.followup.allFollowup = this.leads.followup.allFollowup ? this.leads.followup.allFollowup : {};
      this.leads.followup.allFollowup[timestamp] = this.followup;
      this.leads.followup.followupCount = this.leads.followup.followupCount ? Number(this.leads.followup.followupCount) + 1 : 1;
      this.leads.lastupdationDate = timestamp;
      //status change
      if (this.leads.status != 'billing' || this.leads.billingStatus != 'Delivered') {
          this.leads.status = this.leads.status && "enquiry" != this.leads.status ? this.leads.status: "followup";
      }
      this.leads.updatedBy = this.leads.updatedBy ? this.leads.updatedBy : {};
      this.leads.updatedBy[this.leads.lastupdatedBy] = { 'name': (this.currentUser.firstName ? this.currentUser.firstName + ' ' : '') + '' + (this.currentUser.middleName ? this.currentUser.middleName + ' ' : '') + "" + (this.currentUser.lastName ? this.currentUser.lastName : ''), 'timestamp': timestamp, 'uid': this.leads.lastupdatedBy };
      this.disableBtn = true;
      this.ngxService.startBackground();
      this.leadsService.saveFollowup(this.leads).subscribe((response: any) => {
          this.disableBtn = false;
           this.ngxService.stopBackground();
          if (response && response.status && response.content) {
              this.alertService.success('Record saved successfully');
              this.leads = response.content;
             this.commonService.setCurrentLead(this.leads);
             this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
             this.router.navigate(['/home/leads-summary']));
          } else {
              this.alertService.warning('Unable to save. Please check and try again.');
          }
      }, function (error) {
          console.log(error);
          this.alertService.danger('Network connection not available. Please check and try again.');
          this.disableBtn = false;
           this.ngxService.stopBackground();
      });
  }
  }
}
