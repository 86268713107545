<ngx-alerts></ngx-alerts>
<div fxFlex="100" style="padding: 20px 5px 20px 5px; background-color: #fff;">
    <div *ngIf="assignToAnotherFlag">
        <div class="alert alert-danger  alert-dismissible">
            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
            <button class="btn btn-primary btn-sm pull-right" style="margin-top:-6px" routerLink="../allotment-vehicle-list">Change Vehicle</button>
            <strong>This vehicle already assigned to another customer</strong> 
        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.sm="0px" style="margin-top: 10px;">
        <div fxFlex="70">
            <div class="row no-margin">
                <div class="col-sm-8 header-text">
                    {{vehicleInfo.makeName}} &nbsp;{{vehicleInfo.modelName}}&nbsp;{{vehicleInfo.submodelName}}<br>
                    <small style="font-size: 12px;"><i>{{vehicleInfo.status}}</i></small>
                  </div>
                <div class="col-sm-4 text-right header-text" *ngIf="vehicleInfo.salePrice">
                    <i class="fa fa-inr" style="font-size: 24px"></i>&nbsp;{{vehicleInfo.salePrice  | number : '1.0-2' }}</div>
            </div>
            <div class="row no-margin">
                <div class="col-sm-12">
                    <mat-card [class.mat-elevation-z6]="true" fxFlex>
                        <div class="row no-margin">
                            <div class="col-sm-12 no-padding">
                                <ng-container *ngIf="vehicleInfo.vehicleImages && vehicleInfo.vehicleImages[0];else other_content">
                                    <img id="card-img"  class="img-fluid" style="height: 100vh;" [src]="vehicleInfo.vehicleImages[0].downloadUrl"/>
                                </ng-container>
                                <ng-template #other_content>
                                    <img id="card-img" src="../../../../assets/images/notfound.png"
                                    class="md-card-image cursor-pointer"  style="height: 80vh;" alt="images">
                                </ng-template>
                              
                            </div>
                        </div>
                        <div class="row no-margin primary-color" style="margin-top: 20px;">
                            <div class="col-sm-3 no-padding">
                                <div class="row no-margin">
                                    <div class="col-sm-5 no-padding">
                                        <img id="card-img"  class="img-fluid" src="../../../../assets/images/vehicle-year-icon.png"/>
                                    </div>
                                    <div class="col-sm-7 no-padding">
                                        <h4 style="margin-top: 15px;">{{vehicleInfo.year}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 no-padding">
                                <div class="row no-margin">
                                    <div class="col-sm-5 no-padding">
                                        <img id="icon-img"  class="img-fluid" src="../../../../assets/images/odometer-icon.png"/>
                                    </div>
                                    <div class="col-sm-7 no-padding">
                                        <h4 style="margin-top: 15px;">{{vehicleInfo.km | number : '1.0-2' }}&nbsp;KM</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 no-padding">
                                <div class="row no-margin">
                                    <div class="col-sm-5 no-padding">
                                        <img id="icon-img"  class="img-fluid" src="../../../../assets/images/fueltype-icon.png"/>
                                    </div>
                                    <div class="col-sm-7 no-padding">
                                        <h4 style="margin-top: 15px;">{{vehicleInfo.fuelType}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 no-padding">
                                <div class="row no-margin">
                                    <div class="col-sm-5 no-padding">
                                        <img id="icon-img"  class="img-fluid" src="../../../../assets/images/owner-icon.png"/>
                                    </div>
                                    <div class="col-sm-7 no-padding">
                                        <h4 style="margin-top: 15px;">{{vehicleInfo.owners}}&nbsp;OWNER</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div fxFlex>
            <div class="row no-margin">
                <div class="col-sm-12" style="padding-left: 0px;">
                    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                            <mat-form-field  fxFlex >
                                <mat-label>Booking Date</mat-label>
                                <input matInput disabled [(ngModel)]="booking.bookingDate" formControlName="bookingDate" required [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker disabled="false"></mat-datepicker>
                              </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                           <b>Mode of Booking:</b>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 5px;">
                            <mat-radio-group fxFlex [(ngModel)]="booking.purchaseMode" [ngModelOptions]="{standalone: true}" color="primary">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-radio-button fxFlex="50"  name="purchaseMode" value="Cash">Cash</mat-radio-button>
                                    <mat-radio-button fxFlex="50" name="purchaseMode" value="Cheque">Cheque</mat-radio-button>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-radio-button fxFlex="50"  name="purchaseMode" value="Online Transfer"> Online Transfer</mat-radio-button>
                                    <mat-radio-button fxFlex="50" name="purchaseMode" value="Other">Other</mat-radio-button>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-radio-button fxFlex="50" name="purchaseMode" value="Card">Card</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div class="row" *ngIf="booking.purchaseMode == 'Cheque'" style="margin: 0px;">
                            <div class="col-sm-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Bank:</mat-label>
                                    <input matInput [(ngModel)]="booking.chequeBankName" [ngModelOptions]="{standalone: true}" >
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Cheque#:</mat-label>
                                    <input matInput [(ngModel)]="booking.chequeNumber" [ngModelOptions]="{standalone: true}" >
                                </mat-form-field>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Date</mat-label>
                                    <input matInput [(ngModel)]="booking.chequeDate" [ngModelOptions]="{standalone: true}"  name="chequeDate" [matDatepicker]="chequeDate">
                                    <mat-datepicker-toggle matSuffix [for]="chequeDate"></mat-datepicker-toggle>
                                    <mat-datepicker #chequeDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="booking.purchaseMode == 'Online Transfer'" style="margin: 0px;">
                            <div class="col-sm-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Bank:</mat-label>
                                    <input matInput [(ngModel)]="booking.onlineBankName" [ngModelOptions]="{standalone: true}" >
                                </mat-form-field>
                            </div>
                            <div class="col-sm-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Transaction#:</mat-label>
                                    <input matInput [(ngModel)]="booking.transactionNumber" [ngModelOptions]="{standalone: true}" >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="booking.purchaseMode == 'Other'" style="margin: 0px;">
                            <div class="col-sm-12">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Other:</mat-label>
                                    <input matInput [(ngModel)]="booking.other" [ngModelOptions]="{standalone: true}" >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="booking.purchaseMode == 'Card'" style="margin: 0px;padding-right: 5px;">
                            <div class="col-sm-6 padding-lr-none">
                                <mat-form-field appearance="outline" style="width: 100%; ">
                                    <mat-label>Type</mat-label>
                                    <mat-select [(ngModel)]="booking.cardType" [ngModelOptions]="{standalone: true}"  name="cardType">
                                        <mat-option value="{{item}}" *ngFor="let item of cardType">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-6 padding-lr-none">
                                <mat-form-field appearance="outline" style="width: 100%; ">
                                    <mat-label>Name</mat-label>
                                    <mat-select [(ngModel)]="booking.cardName" [ngModelOptions]="{standalone: true}"  name="cardName">
                                        <mat-option value="{{item}}" *ngFor="let item of cardName">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-sm-6 padding-lr-none">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Transaction#:</mat-label>
                                    <input matInput [(ngModel)]="booking.cardTransactionNumber" [ngModelOptions]="{standalone: true}" >
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                            <b>Mode of Finance:</b>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 5px;">
                            <mat-radio-group fxFlex [(ngModel)]="booking.financeMode" [ngModelOptions]="{standalone: true}" color="primary">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-radio-button fxFlex="50"  name="financeMode" value="Self">Self Finance
                                        
                                        </mat-radio-button>
                                    <mat-radio-button fxFlex="50" name="financeMode" value="Inhouse">Inhouse</mat-radio-button>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-radio-button fxFlex="50"  name="financeMode" value="Outright">Outright</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                            <mat-form-field fxFlex appearance="outline" class="input-field-space" >
                                <mat-label>Amount Received</mat-label>
                                <input matInput [(ngModel)]="booking.amountReceived" (keypress)="numberOnly($event)" (change)="calculateBalance()" formControlName="amountReceived" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                            <mat-form-field fxFlex appearance="outline" class="input-field-space">
                                <mat-label>Balance Payment</mat-label>
                                <input matInput [(ngModel)]="booking.balancePayment" [disabled]="true" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                            <mat-form-field  fxFlex appearance="outline" class="input-field-space">
                                <mat-label>Assigned Date</mat-label>
                                <input matInput disabled [(ngModel)]="booking.assignedDate" [ngModelOptions]="{standalone: true}" [matDatepicker]="assignedDate">
                                <mat-datepicker-toggle matSuffix [for]="assignedDate"></mat-datepicker-toggle>
                                <mat-datepicker #assignedDate disabled="false"></mat-datepicker>
                              </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                            <mat-form-field  fxFlex appearance="outline" class="input-field-space">
                                <mat-label>Committed Delivery</mat-label>
                                <input matInput disabled [(ngModel)]="booking.committedDeliveryDate" [ngModelOptions]="{standalone: true}" [matDatepicker]="committedDeliveryDate">
                                <mat-datepicker-toggle matSuffix [for]="committedDeliveryDate"></mat-datepicker-toggle>
                                <mat-datepicker #committedDeliveryDate disabled="false"></mat-datepicker>
                              </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-bottom: 10px;">
                           <b>Booking Status:</b>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px"  style="margin-bottom: 5px;">
                            <mat-radio-group fxFlex [(ngModel)]="booking.subStatus" (change)="booking.billed = (booking.subStatus != 'Assigned'? false : booking.billed )" [ngModelOptions]="{standalone: true}" color="primary">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-radio-button fxFlex="50"  name="subStatus" value="Not Assigned">Not Assigned</mat-radio-button>
                                    <mat-radio-button fxFlex="50" name="subStatus" value="Assigned">Assigned</mat-radio-button>
                                </div>
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                    <mat-radio-button fxFlex="50"  name="subStatus" value="On-Hold">On-Hold</mat-radio-button>
                                    <mat-radio-button fxFlex="50"  name="subStatus" value="Cancelled">Cancelled</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px"  style="margin-bottom: 5px;">
                            <div fxFlex="50"><b>Billing Status:</b></div>
                            <section class="example-section" fxFlex="50">
                                <mat-checkbox [(ngModel)]="booking.billed" color="primary" [disabled]="booking.subStatus != 'Assigned'" [ngModelOptions]="{standalone: true}" >Billed</mat-checkbox>
                              </section>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px"  style="margin-bottom: 5px;">
                            <div fxFlex="50"><b>Delivery Status:</b></div>
                            <section class="example-section" fxFlex="50">
                                <mat-checkbox [(ngModel)]="booking.delivered" [disabled]="leads.status != 'billing'" color="primary"  [ngModelOptions]="{standalone: true}">Delivered</mat-checkbox>
                              </section>
                          </div>
                          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                            <mat-form-field fxFlex appearance="outline">
                                <mat-label>Consultant Remark</mat-label>
                                <textarea matInput placeholder="Consultant Remark" [(ngModel)]="booking.remark" [ngModelOptions]="{standalone: true}"></textarea>
                              </mat-form-field>
                          </div>
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                            <button fxFlex="50" mat-raised-button color="warn" type="reset" [disabled]="assignToAnotherFlag">Clear</button>
                            <button fxFlex="50" mat-raised-button color="primary" type="submit" [disabled]="!profileForm.valid || disableBtn || assignToAnotherFlag">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>