import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { SearchLeadsDialogComponent } from './header/search-leads-dialog/search-leads-dialog.component';
import { SmsDialogComponent } from './header/sms-dialog/sms-dialog.component';

@NgModule({
  declarations: [LoginComponent, HeaderComponent, SearchLeadsDialogComponent, SmsDialogComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatIconModule,
    CoreRoutingModule
  ],
  exports: [
    HeaderComponent,
  ],
  entryComponents: [SearchLeadsDialogComponent, SmsDialogComponent]
})
export class CoreModule { }
