import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { PurchaseService } from '../service/purchase.service';
import { CommonService } from 'src/app/service/common.service';

export class SMS {
  public phone: string;
  public message: string;
}

@Component({
  selector: 'app-p-sms',
  templateUrl: './p-sms.component.html',
  styleUrls: ['./p-sms.component.css']
})

export class PSmsComponent implements OnInit {

  customer: any;
  smsObject: SMS;
  constructor(public dialogRef: MatDialogRef<PSmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public ngxService: NgxUiLoaderService,
    private alertService: AlertService,
    private apiService: PurchaseService,
    private commonService : CommonService
    ) {
    this.smsObject = new SMS();

    var customerInformation = this.commonService.getSelectedCustomer();
    if (customerInformation) {
      this.customer = customerInformation;
      this.smsObject.phone = this.customer.phone;
    }
  }

  ngOnInit() {

  }

  sendSms() {
    if (this.smsObject.message) {
      this.ngxService.startBackground();
      this.smsObject.message = this.smsObject.message + " ";
      this.apiService.sendSMS(this.smsObject).subscribe(res => {
        let flag = res.success;
        if (flag) {
          this.alertService.success('Message sent');
          this.ngxService.stopBackground();
          this.close();
        } else {
          this.alertService.danger('Message not sent');
        }
      });
    } else {
      this.alertService.danger('Message not sent');
    }
  }

  close() {
    this.ngxService.stopBackground();
    this.dialogRef.close();
  }
}
