import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import { UtilityService } from 'src/app/service/utility.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  user : any = {};
  constructor( private http: HttpClient,  private utilService: UtilityService) {
    this.user = this.utilService.getLoginUser();

   }
  searchCustomers(body: any): Observable<JSON> {
    return this.http.post<JSON>(environment.USEDCAR_API_URL + '/api/purchase/searchCustomers', body);
  }

  genrateDealNumber(dealerId: string, customerId: string): Observable<JSON> {
    let param = new HttpParams().set("dealerId", dealerId)
                                .set("customerId", customerId);
    return this.http.get<JSON>(environment.USEDCAR_API_URL + '/api/purchase/genrate-deal-number', { params: param });
  }

  saveDealNumber(groupId, dealerId: string, customerId: string): Observable<JSON> {
    let param = new HttpParams().set("groupId", groupId).set("dealerId", dealerId)
                                .set("customerId", customerId);
    return this.http.get<JSON>(environment.USEDCAR_API_URL + '/api/purchase/save-deal-number', { params: param });
  }

  savePurchaseCustomer(groupId, dealerId: string, customerId: string): Observable<JSON> {
    let param = new HttpParams().set("groupId", groupId).set("dealerId", dealerId)
                                .set("customerId", customerId)
                                .set("createdBy",  this.user['uid']);
    return this.http.get<JSON>(environment.USEDCAR_API_URL + 'api/mysql/purchase/save-customer', { params: param });
  }
  savePurchaseVehicle(groupId, dealerId: string, customerId: string): Observable<JSON> {
    let param = new HttpParams().set("groupId", groupId).set("dealerId", dealerId)
                                .set("customerId", customerId)
                                .set("createdBy",  this.user['uid']);
    return this.http.get<JSON>(environment.USEDCAR_API_URL + 'api/mysql/purchase/save-vehicle', { params: param });
  }
  getAllStockVehicle(groupId, dealerId: string, toDate: any): Observable<JSON> {
    let param = new HttpParams().set("groupId", groupId).set("dealerId", dealerId)
                                .set("toDate", toDate);
    return this.http.get<JSON>(environment.USEDCAR_API_URL + 'api/mysql/purchase/get-all-stock-vehicle', { params: param });
  }
  sendSMS(sms: any): Observable<any> {
    return this.http.post(environment.USEDCAR_API_URL + "api/purchase/sendsms?userPhone=" + sms.phone + "&message=" + sms.message, {});
  }

  sendMail(emailObject): Observable<any> {
    return this.http.post(environment.USEDCAR_API_URL + "api/mail/sendMail" ,emailObject);
  }

}
