import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { LeadsService } from '../leads.service';
import { AlertService } from 'ngx-alerts';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CardealerDbService } from 'src/app/firebase-service/cardealer-db.service';

@Component({
  selector: 'app-leads-summary',
  templateUrl: './leads-summary.component.html',
  styleUrls: ['./leads-summary.component.css']
})
export class LeadsSummaryComponent implements OnInit {

  workFlowGivenByName: any = '';
  currentLead: any = {};
  vehicleInfo: any = {};
  leadProcess: any = {};
  recentActivity = [];
  stagesCompleted: number = 0;
  constructor(private commonService: CommonService, private leadService: LeadsService
    , private alerService: AlertService, private ngxService: NgxUiLoaderService,
    private carDealerFirebaseService: CardealerDbService,
  ) { }

  ngOnInit(): void {
    this.currentLead = this.commonService.getCurrentLead();
    if (this.currentLead) {
      this.vehicleInfo = this.currentLead && this.currentLead.vehicle ? this.currentLead.vehicle : {};
      this.recentActivity = this.currentLead.recentActivity ? Object.values(this.currentLead.recentActivity) : [];
      this.getLeadProcess();
    } else {

    }

  }

  getLeadProcess = () => {
    this.ngxService.startBackground();
    this.leadService.getLeadsProcess(this.commonService.getGroupUId(), this.commonService.getDealerUId(), this.currentLead.pushKey).subscribe((res: any) => {
      if (res.status) {
        this.leadProcess = res.content;
        this.stagesCompletedValCal();

        var workflowGivenByUID = this.leadProcess['lastupdatedBy'];
        this.getGivenByNameINFO(workflowGivenByUID);
      }
      this.ngxService.stopBackground();
    }, err => {
      console.log(err)
      this.ngxService.stopBackground();
    })
  }

  getGivenByNameINFO(lastupdatedBYUID) {
    if (lastupdatedBYUID != undefined && lastupdatedBYUID != null && lastupdatedBYUID != '') {
      var nodepath = 'users/' + lastupdatedBYUID;
      this.carDealerFirebaseService.getFirebaseObjectTypeValues(nodepath).subscribe(res => {
        if (res) {
          var response = res;
          this.workFlowGivenByName = response['firstName'] + ' ' + response['lastName'];
        }
      })
    }
  }


  datToTimesAgo = (date) => {
    return this.timeToAgo(new Date(date).getTime());
  }

  timeToAgo = (timestamp) => {
    var seconds = Math.floor((new Date().getTime() - timestamp) / 1000);
    var intervalType;

    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'year';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'month';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'day';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }

    if(interval < 0){
      interval = 0;
    }
    if (interval > 1 || interval === 0) {
      intervalType += 's';
    }

    return interval + " " + intervalType + " ago";
  }

  stagesCompletedValCal = () => {
    if (this.leadProcess.enquiryDone == "Y") {
      this.stagesCompleted += 1;
    }
    if (this.leadProcess.followupDone == "Y") {
      this.stagesCompleted += 1;
    }
    if (this.leadProcess.testdriveDone == "Y") {
      this.stagesCompleted += 1;
    }
    if (this.leadProcess.bookingDone == "Y") {
      this.stagesCompleted += 1;
    }
    if (this.leadProcess.billingDone == "Y") {
      this.stagesCompleted += 1;
    }
    if (this.leadProcess.deliveredDone == "Y") {
      this.stagesCompleted += 1;
    }
  }


}
