import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/service/api.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'ngx-alerts';
export class FormAttributes {
  category: string = "testdrive";
  reason: string = "Video Conference";
  message: string;
  firebaseUId: string;
  dealerId: string;
  timestamp: string;
  toEmail: string;
  fullName: string;
  dealerName: string;
  categoryName: string;
}
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css']
})
export class BookAppointmentComponent implements OnInit {

  public formAttr: FormAttributes;
  public customer: any;
  public date: any;
  public category: any = [{ 'title': 'Follow up', 'value': 'followup' }];
  public reason: any = [{ 'title': 'Video Conference' }];
  public loginDealer: any;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private bottomSheetRef: MatDialogRef<BookAppointmentComponent>, private apiService: ApiService,
    private ngxService: NgxUiLoaderService, private alertService: AlertService) {
    this.formAttr = new FormAttributes();
    if (data) {
      this.customer = data.customer;
      this.date = data.date;
    }
    this.loginDealer = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {};
  }

  ngOnInit() {
  }

  saveCustomer() {
    var validateUser = true;
    this.ngxService.startBackground();
    this.formAttr.firebaseUId = this.customer.pushKey;
    this.formAttr.dealerId = this.loginDealer.firebaseUId;
    this.formAttr.timestamp = this.date;
    this.formAttr.toEmail = this.customer.email;
    this.formAttr.fullName = this.customer.fullName;
    this.formAttr.dealerName = this.loginDealer.businessName;
    console.log(this.formAttr);
    this.apiService.updateFollowupDetails('send_notification', this.formAttr)
      .subscribe((resMap: any) => {
        this.ngxService.stopBackground();
        if (resMap.status) {
          // this.snackbar.openSnackBar('Record saved successfully', 'OK', {
          //   duration: 3000
          // });
          this.alertService.danger("Record saved successfully");
          this.bottomSheetRef.close();
        } else {
          // this.snackbar.openSnackBar('Record not saved', 'OK', {
          //   duration: 3000
          // });
          this.alertService.danger("Record not saved");
        }
      }, error => {
        console.log(error);
        // this.snackbar.openSnackBar('error', 'OK', {
        //   duration: 3000
        // });
        this.alertService.danger("Record not saved");
        this.ngxService.stopBackground();
      });
  }

  close() {
    this.bottomSheetRef.close();
  }
}
