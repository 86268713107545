<div fxLayout="row" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutGap="7px" fxLayoutGap.sm="0px"
    style="margin-top: 20px !important; color: #0269ce; ">
    <h2>Welcome, <span style="text-transform: uppercase;">{{this.user.firstName}} {{this.user.middleName}}
            {{this.user.lastName}}</span></h2>
</div>

<div fxLayout="row" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutGap="7px" fxLayoutGap.sm="0px"
    style="margin-top: 10px !important;">
    <h3 style="font-size:24px;">Manage and control your account, all from one place</h3>
</div>

<div style="padding: 16px;" *ngIf="isDealer">
    <div fxLayout="row" fxLayout.xs="row" fxLayoutGap="10px" fxLayoutGap.sm="0px">
        <div fxFlex="30">
            <div fxLayout="row" fxLayout.xs="row" fxLayoutGap="10px" fxLayoutGap.sm="0px">
                <mat-card fxFlex [class.mat-elevation-z2]="true" fxFlexAlign="stretch" [style.minHeight]="'70vh'">
                    <mat-card-header>
                        <mat-card-title style="font-size:19px;">
                            Document Library Setup
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content style="padding-left: 20px;">
                        <div fxLayout="row" fxLayout.xs="row" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="height:30px;">
                            <h4>
                                <a [routerLink]="['/home/kyc-setup']" matTooltip="Create KYC Document" matTooltipClass="custom-tooltip"
                                    style="cursor: pointer; margin-top: 10px; text-decoration: none;">
                                    KYC Document
                                </a>
                            </h4>
                        </div>
                        <div fxLayout="row" fxLayout.xs="row" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="height:30px;">
                            <h4>
                                <a [routerLink]="['/home/vehicle-doc-setup']" matTooltip="Create Vehicle Document"
                                    matTooltipClass="custom-tooltip"
                                    style="cursor: pointer; margin-top: 10px; text-decoration: none;">
                                    Vehicle Document
                                </a>
                            </h4>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>