import { Component, OnInit, DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AlertService } from 'ngx-alerts';
import { environment } from 'src/environments/environment';
import { FirebaseService } from 'src/app/service/firebase.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-sales-contract',
  templateUrl: './create-sales-contract.component.html',
  styleUrls: ['./create-sales-contract.component.css']
})
export class CreateSalesContractComponent implements OnInit {

  totalAmount: number = 0;
  paymentModes: any = [
    {
      id: 'ORI', name: 'Outright', selected: false,
    },
    {
      id: 'FNI', name: 'Finance', selected: false,
    },
    {
      id: 'EXC', name: 'Exchange', selected: false,
    }
  ];

  contractModel: any = {
    date: '',
    time: '',
    location: '',
    name: '',
    so: '',
    address: '',
    phoneNoR: '',
    phoneNoO: '',
    phoneNoMOB: '',
    make: '',
    model: '',
    variant: '',
    colour: '',
    registrationNumber: '',
    ownerSerial: '',
    fuelType: '',
    chassisNo: '',
    vehiclePriceRemarks: '',
    vehiclePriceAmount: '',
    warrantyCostRemarks: '',
    warrantyCostAmount: '',
    oneYearServiceRemarks: '',
    oneYearServiceAmount: '',
    insuranceRemarks: '',
    insuranceAmount: '',
    zeroDepCoverRemarks: '',
    zeroDepCoverAmount: '',
    rsaRemarks: '',
    rsaAmount: '',
    accessoriesRemarks: '',
    accessoriesAmount: '',
    rtoTransRemarks: '',
    rtoTransAmount: '',
    othersRemarks: '',
    othersAmount: '',
    gstRemarks: '',
    gstAmount: '',
    additionaRFRemarks: '',
    additionaRFAmount: '',
    finance: '',
    loan: '',
    tenure: '',
    exchange: '',
    registrationNO: '',
    amount: '',
    jobAgreedUpon: '',
    isEdit: false,
  };

  customerInfo: any;
  vehicleInfo: any;
  user: any = {};
  groupUID: any;
  sessionCustomer: any = {};
  vehiclePriceArr: any = [];
  refurbishTotal: any = 0;
  differ: KeyValueDiffer<string, any>;

  constructor(
    private alertService: AlertService,
    private differs: KeyValueDiffers,
    private firebaseService: FirebaseService,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    private router: Router,
  ) {
    this.differ = this.differs.find({}).create();
  }

  ngOnInit(): void {
    this.sessionCustomer = this.commonService.getCurrentLead();
    if (!this.sessionCustomer || Object.keys(this.sessionCustomer).length == 0) {
      this.alertService.danger('Please select lead to continue');
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/home/leads-list'])
      )
      return;
    }

    // this.getVehicleRefurbishmentTotalPrice();
    this.getTimeAndDate();
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.groupUID = this.user.group ? this.user.group.uid : '';
    this.getCustomerInfo();
  }

  getSavedSalesContractDATA() {
    this.ngxService.startBackground();
    const nodePathUrl = "salesContract/" + this.groupUID + "/" + this.user.uid + "/" + this.customerInfo.pushKey;
    this.firebaseService.getFirebaseObjectTypeValues(nodePathUrl).subscribe(res => {
      if (res) {
        debugger
        this.resetForm();
        this.contractModel = res;
        this.contractModel.isEdit = true;

        //Setting checkbox values
        if (res.paymentModes != undefined && res.paymentModes != null && res.paymentModes != "") {
          if (res.paymentModes.length > 0) {
            this.paymentModes.forEach((val, key) => {
              res.paymentModes.forEach((saved, savedkey) => {
                if (val.id == saved.id) {
                  val.selected = true;
                }
              });
            });
          }
        }
        if(this.customerInfo['vehicle']['salePrice'] != this.contractModel.vehiclePriceAmount){
          this.alertService.danger('Price calculation has been changed save the data again.');
        }
      } else {
        this.setFORMDefault();
      }

      this.ngxService.stopBackground();
    })
  }


  ngDoCheck() {
    this.totalAmount = Number(this.contractModel.vehiclePriceAmount) + Number(this.contractModel.warrantyCostAmount) + Number(this.contractModel.oneYearServiceAmount) + Number(this.contractModel.insuranceAmount) + Number(this.contractModel.zeroDepCoverAmount) + Number(this.contractModel.rsaAmount) + Number(this.contractModel.accessoriesAmount) + Number(this.contractModel.rtoTransAmount) + Number(this.contractModel.othersAmount) + Number(this.contractModel.gstAmount) + Number(this.contractModel.additionaRFAmount);
  }

  // getVehicleRefurbishmentTotalPrice() {
  //   this.firebaseService.getFirebaseObjectTypeValues("used-car-evaluation/" + this.commonService.getDealerUId() + "/" + this.sessionCustomer.uid + "/" + this.commonService.getSelectedVehicle().vin)
  //     .subscribe((res: any) => {
  //       console.log(res)
  //       if (res == null) {
  //         this.firebaseService.getFirebaseObjectTypeValues("purchase/inventory/" + this.commonService.getGroupUId() + "/" + this.commonService.getDealerUId() + '/' + this.sessionCustomer.uid)
  //           .subscribe((res: any) => {
  //             if (res == null) {
  //               return;
  //             }

  //             if (res.vehiclePrice) {
  //               this.refurbishTotal = res.vehicleInformation.refurbishTotal;
  //               this.contractModel.vehiclePriceAmount = this.refurbishTotal;
  //             }
  //           });
  //         return;
  //       } else {
  //         if (res.vehiclePrice) {
  //           this.vehiclePriceArr = res.vehiclePrice;

  //           if (this.vehiclePriceArr.length > 0) {
  //             this.vehiclePriceArr.forEach((val, key) => {
  //               if (val.amount) {
  //                 this.refurbishTotal = this.refurbishTotal + val.amount;
  //               }
  //             });

  //             this.contractModel.vehiclePriceAmount = this.refurbishTotal;
  //           }
  //         }
  //       }
  //     });
  // }

  getTimeAndDate() {
    const moment = require('moment');
    this.contractModel.date = moment().format('MM/DD/YYYY');
    this.contractModel.time = moment().format("hh:mm");
  }

  getCustomerInfo() {
    // var defaultVehicle = sessionStorage.getItem(environment.DEFAULT_VEHICLE) != null ? JSON.parse(sessionStorage.getItem(environment.DEFAULT_VEHICLE)) : {};
    // var customerInformation = sessionStorage.getItem(environment.CUSTOMER_INFO) != null ? JSON.parse(sessionStorage.getItem(environment.CUSTOMER_INFO)) : "";
    var customerInformation = this.commonService.getCurrentLead();

    console.log("-------CUST--------");
    console.log(customerInformation);

    if (customerInformation) {
      this.customerInfo = customerInformation;
      this.getSavedSalesContractDATA();
    } else {
      this.customerInfo = null;
    }

    // if (defaultVehicle) {
    //   this.vehicleInfo = defaultVehicle;
    // } else {
    //   this.vehicleInfo = null;
    // }
  }

  resetForm() {
    this.contractModel = {
      date: '',
      time: '',
      location: '',
      name: '',
      so: '',
      address: '',
      phoneNoR: '',
      phoneNoO: '',
      phoneNoMOB: '',
      make: '',
      model: '',
      variant: '',
      colour: '',
      registrationNumber: '',
      ownerSerial: '',
      fuelType: '',
      chassisNo: '',
      vehiclePriceRemarks: '',
      //vehiclePriceAmount: '',
      warrantyCostRemarks: '',
      warrantyCostAmount: '',
      oneYearServiceRemarks: '',
      oneYearServiceAmount: '',
      insuranceRemarks: '',
      insuranceAmount: '',
      zeroDepCoverRemarks: '',
      zeroDepCoverAmount: '',
      rsaRemarks: '',
      rsaAmount: '',
      accessoriesRemarks: '',
      accessoriesAmount: '',
      rtoTransRemarks: '',
      rtoTransAmount: '',
      othersRemarks: '',
      othersAmount: '',
      gstRemarks: '',
      gstAmount: '',
      additionaRFRemarks: '',
      additionaRFAmount: '',
      finance: '',
      loan: '',
      tenure: '',
      exchange: '',
      registrationNO: '',
      amount: '',
      jobAgreedUpon: '',
      isEdit: false,
    };

    this.totalAmount = 0;
    this.paymentModes = [
      {
        id: 'ORI', name: 'Outright', selected: false,
      },
      {
        id: 'FNI', name: 'Finance', selected: false,
      },
      {
        id: 'EXC', name: 'Exchange', selected: false,
      }
    ];

    // this.setFORMDefault();
    // this.getTimeAndDate();
  }

  setFORMDefault() {
    // Set customerInfo detail in form default
    if (this.customerInfo) {
      // this.contractModel.location = this.customerInfo.city;
      this.contractModel.name = this.customerInfo.firstName + " " + this.customerInfo.lastName;
      // this.contractModel.address = this.customerInfo.address;
      this.contractModel.phoneNoMOB = this.customerInfo.mobile;
    }

    // Set vehicleInfo detail in form default
    if (this.customerInfo) {
      if (this.customerInfo.vehicle) {
        this.contractModel.make = this.customerInfo.vehicle.makeName;
        this.contractModel.model = this.customerInfo.vehicle.modelName;
        this.contractModel.variant = this.customerInfo.vehicle.variantName;
        this.contractModel.colour = this.customerInfo.vehicle.color;
        this.contractModel.registrationNumber = this.customerInfo.vehicle.regNo;
        this.contractModel.fuelType = this.customerInfo.vehicle.fuelType;
        this.contractModel.chassisNo = this.customerInfo.vehicle.vin;
        this.contractModel.vehiclePriceAmount = this.customerInfo['vehicle']['salePrice'];
      }
    }
  }

  isChange(item: any, event: MatCheckboxChange) {
    console.log(item);
    console.log(event.checked);
  }

  sendFormData() {
    this.ngxService.startBackground();
    if (this.validateForm()) {
      var paymentMode = [];
      this.paymentModes.forEach((val, key) => {
        if (val.selected) {
          paymentMode.push(val);
        }
      });

      var timestamp = new Date().getTime();
      this.contractModel.paymentModes = paymentMode;
      this.contractModel.totalAmount = this.totalAmount;
      this.contractModel.invalid_flag = 'N';
      this.contractModel.delete_flag = 'N';
      this.contractModel.created_by = this.contractModel.isEdit ? this.contractModel.created_by : this.user.uid;
      this.contractModel.lastUpdated_by = this.user.uid;
      this.contractModel.creation_date = this.contractModel.isEdit ? this.contractModel.creation_date : timestamp;
      this.contractModel.lastUpdated_date = timestamp;

      const nodePathUrl = "salesContract/" + this.groupUID + "/" + this.user.uid + "/" + this.customerInfo.pushKey;

      if (this.contractModel.isEdit) { // in edit case
        // const finalNodePath = nodePathUrl + "/" + this.contractModel.id;
        this.firebaseService.firbaseUpdateData(nodePathUrl, this.contractModel);

        this.contractModel.isEdit = false;

        this.ngxService.stopBackground();
        this.alertService.success('Sales contract updated successfully');
        // this.resetForm();

      } else { // in case of new creation
        const finalNodePath = nodePathUrl;
        this.firebaseService.firbaseSetData(finalNodePath, this.contractModel);

        this.ngxService.stopBackground();
        this.alertService.success('Sales contract created successfully');
        // this.resetForm();
      }
    } else {
      this.ngxService.stopBackground();
    }
  }

  validateForm() {
    if (this.contractModel.name == undefined || this.contractModel.name == null || this.contractModel.name == '') {
      this.alertService.danger('Please enter name');
      return false;
    } else if (this.customerInfo == undefined || this.customerInfo == null || this.customerInfo == '') {
      this.alertService.danger('Please select customer');
      return false;
    } else if (this.customerInfo != undefined && (this.customerInfo.pushKey == undefined || this.customerInfo.pushKey == null || this.customerInfo.pushKey == '')) {
      this.alertService.danger('Customer uid is missing.');
      return false;
    }
    else {
      return true;
    }
  }

  public print(): void {
    let printContents, popupWin;
    var doc = document.getElementById('print-section');
    printContents = doc.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
          <style>
          #printContent {
            display: block;
            width: auto;
            height: auto;
            overflow: visible;
            }
            @media print {
              @page { size: A4 landscape; }
              .hidden-print {
                display: none !important;
              }

              .details-card {
                border: 1px solid #ddd !important;
                min-height: 100px !important;
            }
          }
             table > tbody > tr > td, table > tbody > tr > th,  table > thead > tr > th {
              border: 1px solid #ddd !important;
          }
         table > tbody > tr > td, table > tbody > tr > th, table > thead > tr > th{
              padding: 5px !important;
          }
          table {
            width: 100% !important;
          }
          </style>
        </head>
        <body onload="window.print();window.close()">
        <h4 style='text-align: center;'>Sales Contract Form</h4>
          <div id="printContent">${printContents}</div>
        </body>
      </html>`
    );
    popupWin.document.close();
  }

}


