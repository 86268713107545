import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FirebaseService } from 'src/app/service/firebase.service';
import { CommonService } from 'src/app/service/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatAccordion } from '@angular/material/expansion';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSelectionList } from '@angular/material/list';
import { AlertService } from 'ngx-alerts';
import { FireEventService } from 'src/app/service/fire-event.service';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css']
})
export class VehicleListComponent implements OnInit {

  dataList : any = [];
  dataListCopy : any = [];
  user: any = {};
  viewType = 'Grid';
  inventoryCount = {};
  headerCountList = [];
  selectedModel: any;
  budgetLabel: any[] = [];
  bodyTypeData: any[] = [];
  OwnerData: any[] = [];
  kmDrivenData: any[] = [];
  fuelTypeData: any[] = [];
  transmissionData: any[] = [];
  makeData: any[] = [];
  @ViewChild('filterSidenav') public sidenav: MatSidenav;
  @ViewChild('shoes') public matList: MatSelectionList;
  currentLead: any;
  constructor(private fireDB: FirebaseService, private commonService : CommonService
    ,  public ngxService: NgxUiLoaderService,
    private router: Router, private alertService: AlertService, private fireEvent: FireEventService) {
      this.user = commonService.getUserInfo();
      this.currentLead = commonService.getCurrentLead();
     }

  ngOnInit() {
    this.inventoryCount = {'free': 0, 'booked': 0, 'total': this.dataList.length};
    this.ngxService.startBackground();
    // this.fireDB.searchByEquals('inventory-test/OErnOccHdzLy6biOJxZ1g4If1ig1/primary_description', 'dealerLocationId', 'JEOyUOA99LSOIyc5xkg2ZWkvtvy2').then(res => {
      this.fireDB.searchByEquals('purchase/inventory/'+this.commonService.getGroupUId()+'/'+this.user.uid, 'vehicleInformation/status', "In Stock").then(res => {
      if(res.val()) {
        console.log(res.val())
        this.dataList = Object.values(res.val());
        this.dataListCopy = Object.values(res.val());
        this.filterBy();
      }
      this.ngxService.stopBackground();
    })
  }


  changeDataView = (list) => {
    this.dataList = list;
  }

    filterBy = () => {
      this.budgetLabel = [{name: "Less than 2 L"}, {name: "2 L - 4 L"}, {name: "4 L - 6 L"}, {name: "More than 6 L"}];
      this.kmDrivenData = [{name: "<10K km"}, {name: "<25K km"}, {name: "<50K km"}, {name: "<75K km"}, {name: ">75K+ km"}];
      this.bodyTypeData = [];
      this.OwnerData = [];
      this.fuelTypeData = [];
      this.transmissionData = [];
      this.makeData = [];
      let bodyJson = {};
      let ownerJson = {};
      let fuelTypeJson = {};
      let transmissionJson = {};
      let makeJson = {};
      this.dataList.forEach((element: any) => {
        if(element.vehicleInformation) {
          let vehicle = element.vehicleInformation;
          if(vehicle.bodyType) {
            bodyJson = this.makeFilterJson(vehicle.bodyType, bodyJson, vehicle.bodyType);
          }
          if(vehicle.owners) {
            ownerJson = this.makeFilterJson(vehicle.owners, ownerJson, vehicle.owners);
          }
          if(vehicle.fuelType) {
            fuelTypeJson = this.makeFilterJson(vehicle.fuelType, fuelTypeJson, vehicle.fuelType);
          }
          if(vehicle.transmissionControlType) {
            transmissionJson = this.makeFilterJson(vehicle.transmissionControlType, transmissionJson, vehicle.transmissionControlType);
          }
          if(vehicle.makeId) {
            makeJson = this.makeFilterJson(vehicle.makeId+vehicle.modelId, makeJson, vehicle.makeName+" "+vehicle.modelName);
          }
        }
      });
      this.bodyTypeData = Object.values(bodyJson);
      this.OwnerData = Object.values(ownerJson);
      this.fuelTypeData = Object.values(fuelTypeJson);
      this.transmissionData = Object.values(transmissionJson);
      this.makeData = Object.values(makeJson);
    }

    makeFilterJson = (key, json, name) => {
      json[key] = json[key] ? json[key] : {};
      json[key]['name'] = name;
      json[key]['count'] = json[key]['count'] ? json[key]['count'] + 1 : 1;
      return json;
    }

  applyFilter = () => {
    this.dataList = [];
    this.dataListCopy.forEach(element => {
      let flag = false;
      let vehicle = element.vehicleInformation;
      flag = this.filterByBudget(vehicle);
      if(flag) {
        flag = this.filterByBodyType(vehicle);
      }
      if(flag) {
        flag = this.filterByFuelType(vehicle);
      }
      if(flag) {
        flag = this.filterByOwner(vehicle);
      }
      if(flag) {
        flag = this.filterByTransmission(vehicle);
      }
      if(flag) {
        flag = this.filterByBrand(vehicle);
      }
      if(flag) {
        flag = this.filterByDrivenKm(vehicle);
      }
      if(flag) {
        this.dataList.push(element);
      }
    });
    this.sidenav.close();
  }

  clearFilter = () => {
    this.dataList = this.dataListCopy;
    this.filterBy();
    this.matList.deselectAll();
    this.sidenav.close();
  }
  filterByBudget = (vehicle) => {
    let flag = false;
    let arr = this.budgetLabel.filter(item => {
      return item.clicked;
    })
    if(arr.length == 0) {
      flag = true;
    } else {
      let exShowroomPrice = this.stringToNumber(vehicle.exShowroomPrice)
      this.budgetLabel.forEach(item => {
        if(item.clicked && !flag) {
          if(item.name == "Less than 2 L" && exShowroomPrice <= 200000) {
            flag = true;
          } else if(item.name == "2 L - 4 L" && exShowroomPrice > 200000 && exShowroomPrice <= 400000) {
             flag = true;
          } else if(item.name == "4 L - 6 L" && exShowroomPrice > 400000 && exShowroomPrice <= 600000) {
            flag = true;
          } else if(item.name == "More than 6 L" && exShowroomPrice > 600000 ) {
            flag = true;
          }
        }
      });
    }
    return flag;
  }

  filterByDrivenKm = (vehicle) => {
    let flag = false;
    let arr = this.kmDrivenData.filter(item => {
      return item.clicked;
    })
    if(arr.length == 0) {
      flag = true;
    } else {
      let km = this.stringToNumber(vehicle.km)
      this.kmDrivenData.forEach(item => {
        if(item.clicked && !flag) {
          if(item.name == "<10K km" && km <= 10000) {
            flag = true;
          } else if(item.name == "<25K km" && km > 10000 && km <= 25000) {
             flag = true;
          } else if(item.name == "<50K km" && km > 25000 && km <= 50000) {
            flag = true;
          } else if(item.name == "<75K km" && km > 50000 && km <= 75000 ) {
            flag = true;
          }
        }
      });
    }
    return flag;
  }

  filterByBodyType = (vehicle) => {
   return this.commonFilterBy(vehicle, this.bodyTypeData, 'bodyType');
  }
  filterByFuelType = (vehicle) => {
    return this.commonFilterBy(vehicle, this.fuelTypeData, 'fuelType');
   }
   filterByOwner = (vehicle) => {
    return this.commonFilterBy(vehicle, this.OwnerData, 'owners');
   }
   filterByTransmission = (vehicle) => {
    return this.commonFilterBy(vehicle, this.transmissionData, 'transmissionControlType');
   }
   filterByBrand = (vehicle) => {
    let flag = false;
    let arr = this.makeData.filter(item => {
      return item.clicked;
    })
    if(arr.length == 0) {
      flag = true;
    } else {
      this.makeData.forEach(item => {
        if(item.clicked && !flag) {
          if(item.name == vehicle.makeName+" "+vehicle.modelName) {
            flag = true;
          } 
        }
      });
    }
    return flag;
   }

  commonFilterBy = (vehicle, filterArr, filterKey) => {
    let flag = false;
    let arr = filterArr.filter(item => {
      return item.clicked;
    })
    if(arr.length == 0) {
      flag = true;
    } else {
      filterArr.forEach(item => {
        if(item.clicked && !flag) {
          if(item.name == vehicle[filterKey]) {
            flag = true;
          } 
        }
      });
    }
    return flag;
  }

  stringToNumber = (obj) => {
    return obj ? Number(obj): 0;
  }

  edit = (primaryDescriptionId) => {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          primaryDescriptionId: primaryDescriptionId               
        },
    };
    this.router.navigate(['home/add-vehicle/inventory-vehicle-specification'], navigationExtras);
  }

  routeToVehicleDetails = (item: any) => {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        vehicleId: (item.uid ? item.uid : item.vehicleInformation.vin)               
      },
  };
    this.router.navigate(['home/allotment-vehicle-details'], navigationExtras);
  }

  routeToTestDrive = () => {
    if(this.currentLead) {
      this.fireEvent.setOptionLink({'label': 'Test Drive', 'route': 'leads-testdrive'});
      // this.router.navigate(['home/leads-testdrive']);
    } else {
      this.alertService.danger("Please select lead to continue.")
      this.router.navigate(['home/leads-list']);
    }
  }
}
