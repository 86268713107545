<mat-toolbar [ngStyle]="{'height':'50px'}" class="header-css">
    <div fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
            <div fxFlex="50">Test Drive</div>
            <div fxFlex="50" fxLayoutAlign="end start">
                <!-- <button mat-raised-button color="primary" (click) ="changeTo()">View</button> -->
            </div>
        </div>
    </div>
</mat-toolbar>

<div style="padding-left: 16px;">
    <div fxFlex="100">
        <!-- <div fxFlex="1"></div> -->
        <div fxFlex="99">
            <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px"
                    style="margin-top: 10px !important;">
                    <div fxFlex="65">
                        <mat-card [class.mat-elevation-z6]="true">
                            <!-- <mat-card-header>
                                    <mat-card-subtitle>Follow-up</mat-card-subtitle>
                                </mat-card-header> -->
                            <mat-card-content>
                                <div fxFlex="100">
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Date & Time</mat-label>
                                            <!-- <input matInput disabled [(ngModel)]="leads.testdrive.testdriveDate"  formControlName="testdriveDate" required [matDatepicker]="picker">
                                                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                      <mat-datepicker #picker disabled="false"></mat-datepicker> -->
                                            <input matInput disabled [ngxMatDatetimePicker]="picker"
                                                placeholder="Choose a date" [(ngModel)]="leads.testdrive.testdriveDate"
                                                formControlName="testdriveDate" required>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker #picker disabled="false"></ngx-mat-datetime-picker>
                                        </mat-form-field>
                                        <div fxFlex="50" class="margin-top-10">
                                            <button mat-raised-button color="primary" type="button"
                                                (click)="routeToAppointMent()"><span class="material-icons">
                                                    add
                                                </span>&nbsp;Add Appointment</button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>TD Route Location</mat-label>
                                            <mat-select [(ngModel)]="leads.testdrive.tdRouteLocation"
                                                [ngModelOptions]="{standalone: true}"
                                                (selectionChange)="tdLocationSet()">
                                                <mat-option value="Customer Location">Customer Location</mat-option>
                                                <mat-option value="Dealer Location">Dealer Location</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Starting Place</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.from"
                                                [ngModelOptions]="{standalone: true}" #search autocorrect="off"
                                                autocapitalize="off" spellcheck="off">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="25" appearance="outline">
                                            <mat-label>Via/To</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.to"
                                                [ngModelOptions]="{standalone: true}" #searchTo autocorrect="off"
                                                autocapitalize="off" spellcheck="off">
                                        </mat-form-field>
                                        <div fxFlex="5" class="margin-top-10">
                                            <button mat-icon-button type="button" routerLink=""
                                                [routerLink]="[ '/home/test-drive-directions']"
                                                [queryParams]="mapDataObject">
                                                <mat-icon>explore</mat-icon>
                                            </button>
                                        </div>
                                        <div fxFlex="5" class="margin-top-10">
                                            <button mat-icon-button title="Reset Locations" type="button"
                                                (click)="leads.testdrive.from = null; leads.testdrive.to = null;">
                                                <mat-icon>refresh</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Kms</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.kms"
                                                [ngModelOptions]="{standalone: true}">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Approx Time</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.time"
                                                [ngModelOptions]="{standalone: true}">
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Sales Person</mat-label>
                                            <mat-select [(ngModel)]="leads.testdrive.salesPersonUId"
                                                [ngModelOptions]="{standalone: true}" required>
                                                <mat-option *ngFor="let employee of employeeList"
                                                    (onSelectionChange)="leads.testdrive.salesPersonName = (employee.firstName+' '+employee.lastName).toUpperCase()"
                                                    [value]="employee.uid">{{employee.firstName}} {{employee.lastName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field fxFlex appearance="outline">
                                            <mat-label>Driver Name</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.driverName"
                                                [ngModelOptions]="{standalone: true}">
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Starting KM</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.startingKM"
                                                [ngModelOptions]="{standalone: true}" (change)="calculateDriven()">
                                        </mat-form-field>
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Ending KM</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.endingKM"
                                                [disabled]="!leads.testdrive.startingKM"
                                                [ngModelOptions]="{standalone: true}" (change)="calculateDriven()">
                                        </mat-form-field>
                                        <mat-form-field fxFlex appearance="outline">
                                            <mat-label>Driven KM</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.driven" disabled="true"
                                                [ngModelOptions]="{standalone: true}">
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <mat-form-field fxFlex="30" appearance="outline">
                                            <mat-label>Driven By</mat-label>
                                            <mat-select [(ngModel)]="leads.testdrive.drivenBy"
                                                [ngModelOptions]="{standalone: true}">
                                                <mat-option *ngFor="let item of drivenByArr" [value]="item">{{item}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field fxFlex appearance="outline">
                                            <mat-label>Customer Driving License Number</mat-label>
                                            <input matInput [(ngModel)]="leads.testdrive.driverLicense"
                                                style=" text-transform: uppercase;" maxlength="15" size="15"
                                                [ngModelOptions]="{standalone: true}">
                                        </mat-form-field>
                                    </div>
                                    <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                    <h4>Feedback</h4>
                                                </div> -->
                                    <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                    <div class="table-responsive">
                                                        <table class="table" fxFlex>
                                                            <thead>
                                                                <tr>
                                                                    <th>Attributes</th>
                                                                    <th class="text-center">Excellent</th>
                                                                    <th class="text-center">Good</th>
                                                                    <th class="text-center">Poor</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of checkBoxData">
                                                                    <td>{{item.name}}</td>
                                                                    <td class="text-center">
                                                                        <mat-checkbox [(ngModel)]="item.excellent" [ngModelOptions]="{standalone: true}" (change)="changeCheckbox(item, 'excellent', item.excellent)" color="primary"></mat-checkbox>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <mat-checkbox [(ngModel)]="item.good" [ngModelOptions]="{standalone: true}" (change)="changeCheckbox(item, 'good', item.good)" color="primary"></mat-checkbox>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <mat-checkbox [(ngModel)]="item.poor" [ngModelOptions]="{standalone: true}" (change)="changeCheckbox(item, 'poor', item.poor)" color="primary"></mat-checkbox>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> -->
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <mat-form-field fxFlex appearance="outline">
                                            <mat-label>Prospect Remarks</mat-label>
                                            <textarea matInput placeholder="Consultant Remark"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div style="height: 30px;"></div>
                                    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column"
                                        fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                        <button fxFlex="20" mat-raised-button color="warn" type="reset">Clear</button>
                                        <button fxFlex="20" mat-raised-button color="primary" type="submit"
                                            [disabled]="!profileForm.valid || disableBtn">{{uid ? 'Update' : 'Save'}}</button>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div fxFlex="35">
                        <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                <mat-card [class.mat-elevation-z6]="true" fxFlex >
                                    <mat-card-content>
                                            <div fxFlex="100">
                                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                    <div fxFlex>
                                                        <div>Rating</div>
                                                    </div>
                                                    <div fxFlex class="text-center">
                                                        <div>Excellent</div>
                                                        <div>  <h5>{{leads.testdrive.feedbackCount.excellent}}</h5></div>
                                                    </div>
                                                    <div fxFlex class="text-center">
                                                        <div>Good</div>
                                                        <div>  <h5>{{leads.testdrive.feedbackCount.good}}</h5></div>
                                                    </div>
                                                    <div fxFlex class="text-center">
                                                        <div>Poor</div>
                                                        <div>  <h5>{{leads.testdrive.feedbackCount.poor}}</h5></div>
                                                    </div>
                                                </div>
                                            </div>
                                    </mat-card-content>
                                </mat-card>
                            </div> -->
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px"
                            style="margin-top: 10px;">
                            <mat-card [class.mat-elevation-z6]="true" fxFlex style="padding: 0px;">
                                <!-- <mat-card-header>
                                        <mat-card-subtitle>Vehicle Information</mat-card-subtitle>
                                    </mat-card-header> -->
                                <mat-card-content>
                                    <div fxFlex="100">
                                        <ng-container
                                            *ngIf="vehicle && vehicle.vehicleImages && vehicle.vehicleImages[0];else other_content">
                                            <img id="card-img" style="height:235px"
                                                [src]="vehicle.vehicleImages[0].downloadUrl" class="img-fluid"
                                                alt="images">
                                        </ng-container>
                                        <ng-template #other_content>
                                            <img id="card-img" src="../../../../assets/images/no-image1.png"
                                                class="img-fluid" style="height:235px" alt="images">
                                        </ng-template>
                                        <div *ngIf="vehicle" style="padding: 16px;">
                                            <div class="md-title title-font-size"
                                                style="margin-bottom: 10px; margin-top: 10px; font-size: 22px;">
                                                <strong>{{vehicle.makeName}}
                                                    &nbsp;{{vehicle.modelName}}&nbsp;{{vehicle.submodelName}}</strong>
                                            </div>
                                            <div *ngIf="vehicle.year">
                                                <div>{{vehicle.year}}&nbsp;|&nbsp;{{vehicle.fuelType}}&nbsp;|&nbsp;
                                                    <span *ngIf="vehicle.km">
                                                        {{vehicle.km | number : '1.0-2'}}&nbsp;KM
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 *ngIf="vehicle.salePrice"
                                                    style="font-size: 20px; margin-top: 10px;">
                                                    <i
                                                        class="fa fa-inr"></i>&nbsp;{{vehicle.salePrice  | number : '1.0-2' }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>

                </div>
                <div style="height: 30px;"></div>
            </form>
        </div>
    </div>
</div>