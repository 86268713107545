<mat-toolbar [ngStyle]="{'height':'50px'}" class="header-css">
    <div fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
            <div fxFlex="50">Follow-up</div>
            <div fxFlex="50" fxLayoutAlign="end start">
                <!-- <button mat-raised-button color="primary" (click) ="changeTo()">View</button> -->
            </div>
        </div>
    </div>
</mat-toolbar>

<div style="padding-left: 16px;">
    <div fxFlex="100">
        <div fxFlex="100" style="padding-right: 18px;">
            <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px" style="margin-top: 10px !important;">
                        <div fxFlex="50">
                            <mat-card [class.mat-elevation-z6]="true" >
                                <div  class="row" style="margin-bottom: 10px;">
                                    <div class="col-sm-6">Last Follow-up Summary</div>
                                    <div class="col-sm-6">
                                        <button class="pull-right" type="button" mat-raised-button (click)="isViewAll = !isViewAll">{{isViewAll ? "Last": "All"}}</button>
                                    </div>
                                </div>
                                <mat-card-content>
                                    <div fxFlex="100">
                                        <mat-card-content *ngIf="!isViewAll" style="height: 300px; overflow: auto; padding: 16px;">
                                            <div class="row">
                                                <p class="col-sm-6"><strong>Follow-up Date:</strong></p>
                                                <p class="col-sm-6">{{leads.followup && leads.followup.lastFollowup? (leads.followup.lastFollowup.followupDate | date: 'MMM, dd yyyy') : ''}}</p>
                                            </div>
                                            <div class="row">
                                                <p class="col-sm-6"><strong>Follow-up Count:</strong></p>
                                                <p class="col-sm-6">{{leads.followup ? leads.followup.followupCount : ''}}</p>
                                            </div>
                                            <div class="row">
                                                <p class="col-sm-6"><strong>Reason:</strong></p>
                                                <p class="col-sm-6">{{leads.followup && leads.followup.lastFollowup && leads.followup.lastFollowup.reason ? leads.followup.lastFollowup.reason  : ''}}</p>
                                            </div>
                                            <div class="row">
                                                <p class="col-sm-6"><strong>Mode of Follow-up:</strong></p>
                                                <p class="col-sm-6">{{leads.followup && leads.followup.lastFollowup ? leads.followup.lastFollowup.followupMode : ""}}</p>
                                            </div>
                                             <div class="row">
                                                <p class="col-sm-6"><strong>Next Contact Date:</strong></p>
                                                <p class="col-sm-6">{{leads.followup && leads.followup.lastFollowup ? (leads.followup.lastFollowup.nextFollowupDate | date: 'MMM, dd yyyy') : ''}}</p>
                                            </div>
                                            <div class="row">
                                                <p class="col-sm-6"><strong>Remark:</strong></p>
                                                <p class="col-sm-6">{{leads.followup && leads.followup.lastFollowup ? leads.followup.lastFollowup.remark : ''}}</p>
                                            </div>
                                        </mat-card-content>
                                        <mat-card-content *ngIf="isViewAll" style="padding: 0px; height: 300px; overflow: auto;">
                                            <mat-card [class.mat-elevation-z3]="true"  *ngFor="let item of allFollowup" style="margin-bottom: 2px;">
                                                <div class="row">
                                                    <p class="col-sm-6"><strong>Follow-up Date:</strong></p>
                                                    <p class="col-sm-6">{{ item.followupDate ? (item.followupDate | date : "MMM dd, yyyy") : ""}}</p>
                                                </div>
                                                <div class="row">
                                                    <p class="col-sm-6"><strong>Reason:</strong></p>
                                                    <p class="col-sm-6">{{item.reason ? item.reason  : ''}}</p>
                                                </div>
                                                <div class="row">
                                                    <p class="col-sm-6"><strong>Mode of Follow-up:</strong></p>
                                                    <p class="col-sm-6">{{item.followupMode}}</p>
                                                </div>
                                                <div class="row">
                                                    <p class="col-sm-6"><strong>Next Contact Date:</strong></p>
                                                    <p class="col-sm-6">{{ item.nextFollowupDate ? (item.nextFollowupDate | date : "MMM dd, yyyy") : ""}}</p>
                                                </div>
                                                <div class="row">
                                                    <p class="col-sm-6"><strong>Remark:</strong></p>
                                                    <p class="col-sm-6">{{item.remark}}</p>
                                                </div>
                                            </mat-card>
                                        </mat-card-content>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                      <div fxFlex="50">
                          <mat-card [class.mat-elevation-z6]="true" >
                              <div class="row" style="margin-bottom: 25px;">
                                <div class="col-sm-12">Follow-up</div>
                            </div>
                              <mat-card-content>
                                      <div fxFlex="100">
                                            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field  fxFlex="50" appearance="outline">
                                                    <mat-label>Follow-up Date</mat-label>
                                                    <input matInput disabled [(ngModel)]="followup.followupDate" formControlName="followupDate" required [matDatepicker]="picker">
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                                                    </mat-form-field>
                                                <mat-form-field fxFlex="50" appearance="outline">
                                                    <mat-label>Reason</mat-label>
                                                    <mat-select [(ngModel)]="followup.reason" [ngModelOptions]="{standalone: true}">
                                                        <mat-option *ngFor="let item of followupTemplates" (onSelectionChange)="followup.reasonId = item.pushKey" [value]="item.description">{{item.description}}</mat-option>
                                                      </mat-select>
                                                </mat-form-field>
                                            </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field  fxFlex="50" appearance="outline">
                                                    <mat-label>Next Follow-up Date</mat-label>
                                                    <input matInput disabled [(ngModel)]="followup.nextFollowupDate" [ngModelOptions]="{standalone: true}" [matDatepicker]="nextfollowup">
                                                    <mat-datepicker-toggle matSuffix [for]="nextfollowup"></mat-datepicker-toggle>
                                                    <mat-datepicker #nextfollowup disabled="false"></mat-datepicker>
                                                  </mat-form-field>
                                                  <mat-form-field fxFlex="50" appearance="outline">
                                                    <mat-label>Mode of Follow-up</mat-label>
                                                    <mat-select [(ngModel)]="followup.followupMode" formControlName="followupMode" required>
                                                        <mat-option *ngFor="let item of followupMode" [value]="item">{{item}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                              </div>
                                              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                <mat-form-field fxFlex appearance="outline">
                                                    <mat-label>Consultant Remark</mat-label>
                                                    <textarea [(ngModel)]="followup.remark" formControlName="remark" required matInput placeholder="Consultant Remark"></textarea>
                                                  </mat-form-field>
                                              </div>
                                              <div style="height: 30px;"></div>
                                              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                                                  <button fxFlex="20" mat-raised-button color="warn" type="reset">Clear</button>
                                                  <button fxFlex="20" mat-raised-button color="primary" type="submit" [disabled]="!profileForm.valid || disableBtn">{{uid ? 'Update' : 'Save'}}</button>
                                              </div>
                                      </div>
                              </mat-card-content>
                          </mat-card>
                      </div>
                    </div>
                    <div style="height: 30px;"></div>
            </form>
        </div>
    </div>
</div>
            