<mat-toolbar [ngStyle]="{'height':'50px'}" class="header-css">
    <div fxFlex="100">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
            <div fxFlex="80">
                Test Drive Route
            </div>
        </div>
    </div>
</mat-toolbar>
<div class="container-fluid" style="padding-top: 20px;">
    <div class="row">
        <div class="col-sm-2"></div>
        <div id="formDiv" class="col-sm-9">
            <form class="form-horizontal" role="form" style="width: 100%;">
                <div class="form-group display-print">
                    <label for="origin" class="col-sm-2 control-label">Starting Location</label>
                    <div class="col-sm-12">
                        <input type="text" #search class="form-control" [(ngModel)]="directions.origin" name="origin" />
                    </div>
                </div>
                <div class="form-group display-print">
                    <label for="destination" class="col-sm-2 control-label">Destination</label>
                    <div class="col-sm-12">
                        <input type="text" #searchTo class="form-control" name="destination"
                            [(ngModel)]="directions.destination" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-8">
                        <button class="btn btn-primary" (click)="findAdress()"><i class="fa fa-arrow-circle-o-up"
                                aria-hidden="true"></i>&nbsp;Get Directions</button>
                        &nbsp;
                        <button class="btn btn-primary" [routerLink]="[ '/home/leads-testdrive', fromToObject]"
                            ng-disabled="btnDisable"><i class="fa fa-bookmark-o"
                                aria-hidden="true"></i>&nbsp;Bookmark</button>
                        &nbsp;
                        <button class="btn btn-primary" (click)="reset()" ng-disabled="btnDisable"><i
                                class="fa fa-refresh" aria-hidden="true"></i>&nbsp;Reset</button>
                        &nbsp;
                        <button class="btn btn-primary" onclick="window.print();"><i class="fa fa-print"
                                aria-hidden="true"></i>&nbsp;Print</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-sm-12">
            <agm-map [scrollwheel]="false" [zoom]="zoom" [latitude]="lat" [longitude]="lng">
                <agm-direction *ngIf="origin && destination" [origin]="origin" [destination]="destination">
                </agm-direction>
            </agm-map>
        </div>
        <div class="col-sm-12" id="directionsList" ng-show="directions.showList"></div>
    </div>
</div>